<template>
    <!-- PACKAGE DIALOG -->
    <keep-alive>
        <is-dialog :header="dialog.header" :visible="dialog.delete && (dialog.edit || dialog.add)" @is-confirm="onManagePackage(packageModel)" @is-cancel="this.dialog.delete = false;this.dialog.edit = false;this.dialog.add = false" :confirmDisabled="isInValidPackage" :breakpoints="{'960px': '75vw', '640px': '100vw'}" :style="{width: '75vw'}">
            <div class="confirmation-content p-fluid p-grid">
                <div class="p-col-12">
                    <div class="p-grid">
                        <div class="p-col-12 p-md-4">
                            <h6>PACKAGE DETAILS</h6>
                            <form-input id="code" label="Carton No" :required="true" :v$="v$" vBase="packageModel" :value="packageModel.code" vID="code" @is-sync="packageModel.code = $event.value;" placeholder="Auto number if not provided." :max-length="20" />
                            <form-input id="name" label="Content" :required="!isDomestic" :v$="v$" vBase="packageModel" vID="name" :value="packageModel.name" @is-sync="packageModel.name = $event.value" placeholder="Short Description for Custom purposes." v-focus :max-length="255" />
                            <h5>WEIGHT & DIMENSION</h5>
                            <form-numeric id="weight" :v$="v$" vBase="packageModel" vID="weight" :required="true" :value="packageModel.weight" label="Gross Weight" decimalDigit="3" @is-sync="packageModel.weight = $event.value" type="decimal" :suffix="`${$vm.$enums['weightunit'].filter((x) => x.id == weightUnit)[0]?.text?.toLowerCase()}`"></form-numeric>
                            <div class="p-d-flex p-nogutter" style="padding: 0 0.5rem !important;">
                                <div class="flex p-nogutter">
                                    <form-numeric id="length" :v$="v$" vBase="packageModel" :value="packageModel.length" label="Length" decimalDigit="2" @is-sync="packageModel.length = $event.value" type="decimal" :suffix="`${$vm.$enums['lengthunit'].filter((x) => x.id == dimensionUnit)[0]?.text?.toLowerCase()}`"></form-numeric>
                                </div>
                                <div class="flex p-nogutter">
                                    <form-numeric id="width" :v$="v$" vBase="packageModel" :value="packageModel.width" label="Width" decimalDigit="2" @is-sync="packageModel.width = $event.value" type="decimal" :suffix="`${$vm.$enums['lengthunit'].filter((x) => x.id == dimensionUnit)[0]?.text?.toLowerCase()}`"></form-numeric>
                                </div>
                                <div class="flex p-nogutter">
                                    <form-numeric id="height" :v$="v$" vBase="packageModel" :value="packageModel.height" label="Height" decimalDigit="2" @is-sync="packageModel.height = $event.value" type="decimal" :suffix="`${$vm.$enums['lengthunit'].filter((x) => x.id == dimensionUnit)[0]?.text?.toLowerCase()}`"></form-numeric>
                                </div>
                            </div>
                            <form-boolean id="volumeAutoCompute" :value="packageModel.volumeAutoCompute" type="toggle" label="Auto Compute Volume?" textPosition="left" @is-sync="packageModel.volumeAutoCompute = $event.value"></form-boolean>
                            <form-numeric id="volume" :v$="v$" :disabled="(packageModel.volumeAutoCompute ?? true)" vBase="packageModel" :required="false" :value="packageModel.volume" label="Volume" decimalDigit="4" @is-sync="packageModel.volume = $event.value; packageModel.volumeUnit = 200;" type="decimal" suffix="m3" :showCopy="false"></form-numeric>
                        </div>
                        <div class="p-col-12 p-md-4">
                            <h6>DECLARATIONS</h6>
                            <form-numeric id="consignmentValue" :v$="v$" vBase="packageModel" vID="consignmentValue" :required="!isDomestic" :value="packageModel.consignmentValue" label="Declared Value" decimalDigit="2" @is-sync="packageModel.consignmentValue = $event.value" type="currency" :currency="`${$vm.$currencies.filter(x => x.id === currencyID)[0]?.code}`"></form-numeric>
                            <form-numeric id="customsValue" :v$="v$" vBase="packageModel" vID="customsValue" :value="packageModel.customsValue" label="Customs Value" decimalDigit="2" @is-sync="packageModel.customsValue = $event.value" type="currency" :currency="`${$vm.$currencies.filter(x => x.id === currencyID)[0]?.code}`"></form-numeric>
                            <form-numeric id="discountValue" :v$="v$" vBase="packageModel" vID="discountValue" :value="packageModel.discountValue" label="Discount Value" decimalDigit="2" :max="packageModel.consignmentValue" @is-sync="packageModel.discountValue = $event.value" type="currency" :currency="`${$vm.$currencies.filter(x => x.id === currencyID)[0]?.code}`"></form-numeric>
                            <form-numeric id="insuranceValue" :v$="v$" vBase="packageModel" vID="insuranceValue" :value="packageModel.insuranceValue" label="Insurance Value" decimalDigit="2" @is-sync="packageModel.insuranceValue = $event.value" type="currency" :currency="`${$vm.$currencies.filter(x => x.id === currencyID)[0]?.code}`"></form-numeric>
                            <form-numeric id="dutyValue" v-show="formConsignment.incoterm == 64" :required="(!(isDomestic) && formConsignment.incoterm == 64)" :v$="v$" vBase="packageModel" vID="dutyValue" :value="packageModel.dutyValue" label="Duty Paid Value" decimalDigit="2" @is-sync="packageModel.dutyValue = $event.value" type="currency" :currency="`${$vm.$currencies.filter(x => x.id === currencyID)[0]?.code}`" :min="0.01"></form-numeric>
                            <form-numeric id="taxValue" v-show="formConsignment.incoterm == 64" :required="(!(isDomestic) && formConsignment.incoterm == 64)" :v$="v$" vBase="packageModel" vID="taxValue" :value="packageModel.taxValue" label="Duty Tax Paid Value" decimalDigit="2" @is-sync="packageModel.taxValue = $event.value" type="currency" :currency="`${$vm.$currencies.filter(x => x.id === currencyID)[0]?.code}`" :min="0.01"></form-numeric>
                            <form-numeric id="taxRate" v-show="formConsignment.incoterm == 64" :required="(!(isDomestic) && formConsignment.incoterm == 64)" :v$="v$" vBase="packageModel" :value="packageModel.taxRate" label="Tax Rate" decimalDigit="2" @is-sync="packageModel.taxRate = $event.value" suffix="%" :max="100"></form-numeric>

                        </div>
                        <div class="p-col-12 p-md-4">
                            <h6>OTHERS</h6>
                            <form-lookup id="additionalHandling" :required="false" type="enum" source="packageadditionalhandlingtype" :value="packageModel.additionalHandling" label="Additional Handling" @is-sync="packageModel.additionalHandling = $event.value" :v$="v$"></form-lookup>
                            <form-input v-if="!isDomestic" id="cpcNumber" label="CPC Number" :v$="v$" vBase="packageModel" vID="cpcNumber" :value="packageModel.cpcNumber" @is-sync="packageModel.cpcNumber = $event.value;" :max-length="15" />
                            <form-input id="ssccNumber" label="SSCC Number" :v$="v$" vBase="packageModel" vID="ssccNumber" :value="packageModel.ssccNumber" @is-sync="packageModel.ssccNumber = $event.value;" :max-length="30" />
                            <form-boolean id="isPallet" :value="packageModel.isPallet" type="toggle" label="Is Pallet?" textPosition="left" @is-sync="packageModel.isPallet = $event.value"></form-boolean>
                            <form-boolean id="isFragile" :value="packageModel.isFragile" type="toggle" label="Is Fragile?" textPosition="left" @is-sync="packageModel.isFragile = $event.value"></form-boolean>
                            <form-boolean id="hasLiquid" :value="packageModel.hasLiquid" type="toggle" label="Has Liquid?" textPosition="left" @is-sync="packageModel.hasLiquid = $event.value"></form-boolean>
                            <form-boolean id="useTailLift" :value="packageModel.useTailLift" type="toggle" label="Use TailLift?" textPosition="left" @is-sync="packageModel.useTailLift = $event.value"></form-boolean>
                        </div>

                    </div>
                    <div class="p-grid">
                        <div class="p-col-12">
                            <cosmos-grid id="PackageItemList" :persistent="false" sourceType="array" :source="sourceItem?.data ?? []" :sourcePaged="true" :searchFilters="searchFields" :autoGenerate="false" :columns="columns" :deleteColumns="searchFields" :showExport="false" :showDelete="false" :showEdit="false" :showHeader="true" :showOperation="false" :showCheckbox="false">
                                <template #toolbar>
                                    <div class="p-d-flex p-jc-between p-ai-center">
                                        <span>
                                            <pv-button icon="pi pi-fw pi-plus" label="Add New Item" style="margin-right: 5px;" @click="onItemCreate"></pv-button>
                                        </span>
                                        <!--<span class="p-input-icon-right" style="min-width: 250px; max-width: 300px; width: 100%">
                                            <i class="pi pi-search" />
                                            <pv-input v-model="activeFilters['global'].value" placeholder="Keyword Search" style="width: 100%;" @keyup="onForceFilter" />
                                        </span>-->
                                    </div>
                                </template>
                                <pv-grid-column field="code" :header="`ITEM DETAILS`">
                                    <template #body="slotProps">
                                        <div class="p-grid p-col-12">
                                            <div class="p-col-12 p-md-6">
                                                <div class="card" :style="`background:var(--surface-a); margin: -1.25rem -1.25rem; min-height: 20rem; ` ">
                                                    <table class="p-code-text" style="width: calc(100%);" cellspacing="0">
                                                        <tbody>
                                                            <tr>
                                                                <th colspan="6" class="p-code-text" style="text-align: left; padding: 0.50rem 0.5rem; border-bottom: 0.01rem solid var(--surface-b); background: var(--surface-b)">
                                                                    PRODUCT
                                                                </th>
                                                            </tr>
                                                            <tr style="border-bottom:thin; border:0.01rem;">
                                                                <th class="p-code-text" style="text-align: left; padding: 0.25rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); border-right: 0.1rem solid var(--surface-d);">Code</th>
                                                                <td colspan="3" class="p-code-text" style="width:calc(80%);text-align: left; padding: 0.25rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); "><div v-html="slotProps.data.code"></div></td>
                                                                <td colspan="2" rowspan="5" class="p-code-text" style="width:calc(80%);text-align: center; padding: 0.25rem 0.5rem; border: 0.01rem solid var(--surface-d); ">
                                                                    <img v-if="slotProps.data?.id > 0 && ((slotProps?.data?.image?.contentType?.length ?? 0) === 0)" :src="`${cdnUrl}/${tenantCode}/product/images/${slotProps.data.mask}.${(slotProps.data?.contentType ?? 'png').replace('image/','') ?? 'png'}?${Math.random()}`" onerror="this.onerror=null; this.src = '/_assets/img/no-image-box.png'" class="image-center" style="width: 10rem !important; height: 8rem !important; margin: auto; object-fit:contain;" />
                                                                    <img v-else-if="((slotProps.data?.image?.contentType?.length ?? 0) !== 0)" :src="`data:${slotProps.data.image?.contentType};base64, ${slotProps.data.image?.file}`" onerror="this.onerror=null; this.src = '/_assets/img/no-image-box.png'" class="image-center" style="width: 10rem !important; height: 8rem !important; margin: auto; object-fit:contain;" />
                                                                    <img v-else-if="(Array.isArray(slotProps.data?.image))" :src="`data:${slotProps.data.image[0]?.contentType};base64, ${slotProps.data.image[0]?.file}`" onerror="this.onerror=null; this.src = '/_assets/img/no-image-box.png'" class="image-center" style="width: 10rem !important; height: 8rem !important; margin: auto; object-fit:contain;" />
                                                                    <img v-else src="/_assets/img/no-image-box.png" onerror="this.onerror=null; this.src = '/_assets/img/no-image-box.png'" class="image-center" style="width: 10rem !important; height: 8rem !important; margin: auto; object-fit:contain;" />
                                                                </td>
                                                            </tr>
                                                            <tr style="border-bottom:thin; border:0.01rem;">
                                                                <th class="p-code-text" style="text-align: left; padding: 0.25rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); border-right: 0.1rem solid var(--surface-d);">Description</th>
                                                                <td colspan="4" class="p-code-text" style="width:calc(80%);text-align: left; padding: 0.25rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); "><div v-html="slotProps.data.name"></div></td>
                                                            </tr>
                                                            <tr style="border-bottom:thin; border:0.01rem;">
                                                                <th class="p-code-text" style="text-align: left; padding: 0.25rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); border-right: 0.1rem solid var(--surface-d);">Attribute</th>
                                                                <td colspan="4" class="p-code-text" style="width:calc(80%);text-align: left; padding: 0.25rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); "><div v-html="slotProps.data.attribute"></div></td>
                                                            </tr>
                                                            <tr style="border-bottom:thin; border:0.01rem;">
                                                                <th class="p-code-text" style="text-align: left; padding: 0.25rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); border-right: 0.1rem solid var(--surface-d);">SKU</th>
                                                                <td colspan="4" class="p-code-text" style="width:calc(80%);text-align: left; padding: 0.25rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); "><div v-html="slotProps.data.sku"></div></td>
                                                            </tr>
                                                            <tr style="border-bottom:thin; border:0.01rem;">
                                                                <th class="p-code-text" style="text-align: left; padding: 0.25rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); border-right: 0.1rem solid var(--surface-d);">GTIN</th>
                                                                <td colspan="4" class="p-code-text" style="width:calc(80%);text-align: left; padding: 0.25rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); "><div v-html="slotProps.data.gtin"></div></td>
                                                            </tr>
                                                            <tr>
                                                                <th colspan="6" class="p-code-text" style="text-align: left; padding: 0.50rem 0.5rem; border-bottom: 0.01rem solid var(--surface-b); background: var(--surface-b)">
                                                                    COMMODITY
                                                                </th>
                                                            </tr>
                                                            <tr style="border-bottom:thin; border:0.01rem;">
                                                                <th colspan="2" class="p-code-text" style="text-align: left; padding: 0.25rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); border-right: 0.1rem solid var(--surface-d);">HS Code</th>
                                                                <td colspan="4" class="p-code-text" style="width:calc(80%);text-align: left; padding: 0.25rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); "><div v-html="slotProps.data.htsCode"></div></td>
                                                            </tr>
                                                            <tr style="border-bottom:thin; border:0.01rem;">
                                                                <th colspan="2" class="p-code-text" style="text-align: left; padding: 0.25rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); border-right: 0.1rem solid var(--surface-d);">Manufacturer Country</th>
                                                                <td colspan="4" class="p-code-text" style="width:calc(80%);text-align: left; padding: 0.25rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); "><div v-if="slotProps.data.manufacturerCountryID" v-html="`${$vm.$countries.filter(x => x.id === slotProps.data.manufacturerCountryID)[0]?.text}`"></div></td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                            <div class="p-col-12 p-md-3">
                                                <div class="card" :style="`background:var(--surface-a); margin: -1.25rem 1rem; min-height: 20rem;` ">
                                                    <table class="p-code-text" style="width: calc(100%);" cellspacing="0">
                                                        <tbody>
                                                            <tr>
                                                                <th colspan="3" class="p-code-text" style="text-align: left; padding: 0.50rem 0.5rem; border-bottom: 0.01rem solid var(--surface-b); background: var(--surface-b)">
                                                                    PIECES, WEIGHT AND DIMENSION
                                                                </th>
                                                            </tr>
                                                            <tr style="border-bottom:thin; border:0.01rem;">
                                                                <th class="p-code-text" style="text-align: left; padding: 0.25rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); border-right: 0.1rem solid var(--surface-d);">Quantity</th>
                                                                <td colspan="2" class="p-code-text" style="width: calc(80%); text-align: right; padding: 0.25rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); "><div v-html="`${$filters.formatNumber(slotProps.data.quantity ?? 0.00,0,country?.code)}`"></div></td>
                                                            </tr>
                                                            <tr style="border-bottom:thin; border:0.01rem;">
                                                                <th class="p-code-text" style="text-align: left; padding: 0.25rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); border-right: 0.1rem solid var(--surface-d);">Net Weight</th>
                                                                <td colspan="2" class="p-code-text" style="width:calc(80%);text-align: left; padding: 0.25rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); "><div v-html="`${$filters.formatNumber(slotProps.data.weight,3,country?.code)} ${weightUnitRecord?.text}`"></div></td>
                                                            </tr>
                                                            <tr style="border-bottom:thin; border:0.01rem;">
                                                                <th class="p-code-text" style="text-align: left; padding: 0.25rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); border-right: 0.1rem solid var(--surface-d);">Dimension</th>
                                                                <td colspan="2" class="p-code-text" style="width:calc(80%);text-align: left; padding: 0.25rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); "><div v-html="`${slotProps.data.length} ${dimension?.text?.toLowerCase()} x ${slotProps.data.width} ${dimension?.text?.toLowerCase()} x ${slotProps.data.height} ${dimension?.text?.toLowerCase()}`"></div></td>
                                                            </tr>
                                                            <tr style="border-bottom:thin; border:0.01rem;">
                                                                <th class="p-code-text" style="text-align: left; padding: 0.25rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); border-right: 0.1rem solid var(--surface-d);">Volume</th>
                                                                <td colspan="2" class="p-code-text" style="width:calc(80%);text-align: left; padding: 0.25rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); "><div v-html="`${$filters.formatNumber(slotProps.data?.volume ?? 0,4,country?.code)} ${$vm.$enums['volumeunit'].filter((x) => x.id == slotProps.data?.volumeUnit ?? 200)[0]?.code?.replace('3', '<sup>3</sup>')?.toLowerCase() ?? ''}`"></div></td>
                                                            </tr>
                                                            <tr>
                                                                <th colspan="3" class="p-code-text" style="text-align: left; padding: 0.50rem 0.5rem; border-bottom: 0.01rem solid var(--surface-b); background: var(--surface-b)">
                                                                    HAZARDOUS MATERIAL
                                                                </th>
                                                            </tr>
                                                            <tr style="border-bottom:thin; border:0.01rem;">
                                                                <th class="p-code-text" style="text-align: left; padding: 0.25rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); border-right: 0.1rem solid var(--surface-d);">UN Code</th>
                                                                <td colspan="2" class="p-code-text" style="width:calc(80%);text-align: left; padding: 0.25rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); "><div v-html="`${(slotProps.data?.dG_HazmatID > 0 ? 'UN' : '')}${hazMatDS?.data.filter(x => x.id === slotProps.data.dG_HazmatID)[0]?.code ?? ''}`"></div></td>
                                                            </tr>
                                                            <tr style="border-bottom:thin; border:0.01rem;">
                                                                <th class="p-code-text" style="text-align: left; padding: 0.25rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); border-right: 0.1rem solid var(--surface-d);">Net Weight</th>
                                                                <td colspan="2" class="p-code-text" style="width:calc(80%);text-align: left; padding: 0.25rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); "><div v-html="`${(slotProps.data?.dG_HazmatID === 1202 ?  $filters.formatNumber(slotProps.data.dG_DryIceNetWeight ?? 0,3,country?.code) : '')}`"></div></td>
                                                            </tr>
                                                            <tr style="border-bottom:thin; border:0.01rem;">
                                                                <th class="p-code-text" style="text-align: left; padding: 0.25rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); border-right: 0.1rem solid var(--surface-d);">Weight Unit</th>
                                                                <td colspan="2" class="p-code-text" style="width:calc(80%);text-align: left; padding: 0.25rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); "><div v-html="`${(slotProps.data?.dG_HazmatID === 1202 ? $vm.$enums['dg_weightunit'].filter((x) => x.id == slotProps.data?.dG_DryIceUnitWeight)[0]?.text ?? '' : '')}`"></div></td>
                                                            </tr>
                                                            <tr style="border-bottom:thin; border:0.01rem;">
                                                                <th class="p-code-text" style="text-align: left; padding: 0.25rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); border-right: 0.1rem solid var(--surface-d);">Regulated Code</th>
                                                                <td colspan="2" class="p-code-text" style="width:calc(80%);text-align: left; padding: 0.25rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); "><div v-html="`${$vm.$enums['dg_regulationtype'].filter((x) => x.id == slotProps.data?.dG_RegulatedCode)[0]?.text ?? ''}`"></div></td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                            <div class="p-col-12 p-md-3">
                                                <div class="card" :style="`background:var(--surface-a); margin: -1.25rem -1.50rem; min-height: 20rem; ` ">
                                                    <table class="p-code-text" style="width: calc(100%);" cellspacing="0">
                                                        <tbody>
                                                            <tr>
                                                                <th colspan="3" class="p-code-text" style="text-align: left; padding: 0.50rem 0.5rem; border-bottom: 0.01rem solid var(--surface-b); background: var(--surface-b)">
                                                                    DECLARATION
                                                                </th>
                                                            </tr>
                                                            <tr style="border-bottom:thin; border:0.01rem;">
                                                                <th class="p-code-text" style="text-align: left; padding: 0.25rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); border-right: 0.1rem solid var(--surface-d);">Declared Value</th>
                                                                <td colspan="2" class="p-code-text" style="width:calc(80%);text-align: right; padding: 0.25rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); "><div v-html="`${$filters.formatCurrency(slotProps.data.consignmentValue ?? 0.00,currency?.code?.toUpperCase(),country?.code,2)}`"></div></td>
                                                            </tr>
                                                            <tr style="border-bottom:thin; border:0.01rem;">
                                                                <th class="p-code-text" style="text-align: left; padding: 0.25rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); border-right: 0.1rem solid var(--surface-d);">Customs Value</th>
                                                                <td colspan="2" class="p-code-text" style="width: calc(80%); text-align: right; padding: 0.25rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); "><div v-html="`${$filters.formatCurrency(slotProps.data.customsValue ?? 0.00,currency?.code?.toUpperCase(),country?.code,2)}`"></div></td>
                                                            </tr>
                                                            <tr style="border-bottom:thin; border:0.01rem;">
                                                                <th class="p-code-text" style="text-align: left; padding: 0.25rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); border-right: 0.1rem solid var(--surface-d);">Discount Value</th>
                                                                <td colspan="2" class="p-code-text" style="width: calc(80%); text-align: right; padding: 0.25rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); "><div v-html="`${$filters.formatCurrency(slotProps.data.discountValue ?? 0.00,currency?.code?.toUpperCase(),country?.code,2)}`"></div></td>
                                                            </tr>
                                                            <tr style="border-bottom:thin; border:0.01rem;">
                                                                <th class="p-code-text" style="text-align: left; padding: 0.25rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); border-right: 0.1rem solid var(--surface-d);">Insurance Value</th>
                                                                <td colspan="2" class="p-code-text" style="width: calc(80%); text-align: right; padding: 0.25rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); "><div v-html="`${$filters.formatCurrency(slotProps.data.insuranceValue ?? 0.00,currency?.code?.toUpperCase(),country?.code,2)}`"></div></td>
                                                            </tr>
                                                            <tr style="border-bottom:thin; border:0.01rem;">
                                                                <th class="p-code-text" style="text-align: left; padding: 0.25rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); border-right: 0.1rem solid var(--surface-d);">Duty Paid Value</th>
                                                                <td colspan="2" class="p-code-text" style="width: calc(80%); text-align: right; padding: 0.25rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); "><div v-html="`${$filters.formatCurrency(slotProps.data.dutyValue ?? 0.00,currency?.code?.toUpperCase(),country?.code,2)}`"></div></td>
                                                            </tr>
                                                            <tr style="border-bottom:thin; border:0.01rem;">
                                                                <th class="p-code-text" style="text-align: left; padding: 0.25rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); border-right: 0.1rem solid var(--surface-d);">Tax Paid Value</th>
                                                                <td colspan="2" class="p-code-text" style="width: calc(80%); text-align: right; padding: 0.25rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); "><div v-html="`${$filters.formatCurrency(slotProps.data.taxValue ?? 0.00,currency?.code?.toUpperCase(),country?.code,2)}`"></div></td>
                                                            </tr>
                                                            <tr style="border-bottom:thin; border:0.01rem;">
                                                                <th class="p-code-text" style="text-align: left; padding: 0.25rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); border-right: 0.1rem solid var(--surface-d);">Tax Rate Value</th>
                                                                <td colspan="2" class="p-code-text" style="width: calc(80%); text-align: right; padding: 0.25rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); "><div v-html="`${$filters.formatNumber(slotProps.data.taxRate ?? 0.00,2,country?.code)}%`"></div></td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </template>
                                </pv-grid-column>
                                <pv-grid-column field="mask" headerStyle="min-width: 7rem; max-width: 7rem; width: 7rem" style="`min-width: 7rem; max-width: 7rem; border-left: 1px solid var(--surface-d); text-align: center; object-position: center; padding: 0 5px;" :frozen="true" :alignFrozen="'right'">
                                    <template #body="slotProps">
                                        <div class="p-d-flex" style="width: 2rem;">
                                            <div class="p-col">
                                                <pv-button icon="pi pi-pencil" iconPos="right" @click="onItemEdit(slotProps.data)" class="p-button-info" />
                                            </div>
                                            <div class="p-col">
                                                <pv-button icon="pi pi-trash" iconPos="right" @click="onItemDelete(slotProps.data)" class="p-button-danger" />
                                            </div>
                                        </div>
                                    </template>
                                </pv-grid-column>
                            </cosmos-grid>
                        </div>
                    </div>
                </div>
            </div>
        </is-dialog>
    </keep-alive>
    <is-dialog header="Delete Record?" :visible="dialog.delete && !dialog.edit && !dialog.add" @is-confirm="onManagePackage(packageModel)" @is-cancel="this.dialog.delete = false; this.dialog.add = false; this.dialog.edit = false;">
        <div class="confirmation-content p-d-flex">
            <div>
                <i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 2rem" />
            </div>
            <div>
                You are about to delete this record:

                <table style="margin: 10px 0; padding: 10px; background-color: var(--surface-d); width: 100%;">
                    <tr v-for="data in deletePackageDisplay" :key="data.id">
                        <th style="text-align:left;">{{ $filters.titleize(data) }}</th>
                        <td>:</td>
                        <td> {{ forDeletePackage[data] }}</td>
                    </tr>
                </table>

                Please be aware of the following:
                <ul>
                    <li>For records that uses unique code field, you cannot reuse the same code again.</li>
                    <li>For records that has activity, the record will only be disabled and archived.</li>
                    <li>Also deleting a record may be irreversible action.</li>
                </ul>
                Are you sure you still want to proceed?
            </div>
        </div>
    </is-dialog>
    <!-- PACKAGE ITEM DIALOG -->
    <keep-alive>
        <is-dialog :header="dialogItemHeader" :visible="itemDialog.delete && (itemDialog.edit || itemDialog.add)" @is-confirm="onManageItem(itemModel)" @is-cancel="this.itemDialog.delete = false; this.itemDialog.add = false; this.itemDialog.edit = false;" :confirmDisabled="isInValidItem" :breakpoints="{'960px': '75vw', '640px': '100vw'}" :style="{width: '75vw'}">
            <div class="confirmation-content p-fluid p-grid">
                <div class="p-col-12">
                    <div class="p-grid">
                        <div :class="`p-col-12 p-md-${(isHazardous ?? false) ? 4 : 4}`">
                            <h6>PRODUCT DETAIL</h6>
                            <form-input id="itemCode" label="Product SKU/Code" :v$="v$" vBase="itemModel" vID="code" :required="true" :value="itemModel.code"  @is-sync="itemModel.code = $event.value;" placeholder="unique product code or SKU." v-focus  :max-length="255"/>
                            <form-input id="itemName" label="Product Name" :v$="v$"  vBase="itemModel" vID="name" :required="true" :value="itemModel.name" @is-sync="itemModel.name = $event.value" placeholder="product descrition." :max-length="255"/>
                            <form-input id="attribute" label="Attribute" :v$="v$"  vBase="itemModel" vID="attribute" :value="itemModel.attribute" @is-sync="itemModel.attribute = $event.value" :max-length="255"/>
                            <form-input id="sku" label="SKU" :v$="v$" :value="itemModel.sku" @is-sync="itemModel.sku = $event.value" :max-length="100"/>
                            <form-input id="gtin" label="GTIN" :v$="v$" :value="itemModel.gtin" @is-sync="itemModel.gtin = $event.value" :max-length="100" />
                            <form-input id="htsCode" label="HS Code" :v$="v$" vBase="itemModel" vID="htsCode" :required="!isDomestic" :validator="v$" :value="itemModel.htsCode" @is-sync="itemModel.htsCode = $event.value" placeholder="HTS Code 8 or 10 digit code" :max-length="10" />
                            <form-lookup id="manufacturerCountryID" :required="!isDomestic" type="autocomplete" :disabled="disabled" source="country" label="Manufacturer Country" :v$="v$"   vBase="itemModel" vID="manufacturerCountryID" :showDirectory="false" :showLookup="showLookup" :value="itemModel.manufacturerCountryID" @is-sync="itemModel.manufacturerCountryID = $event.value" />
                            <form-file v-if="(isHazardous ?? false)" id="fileOne" label="Image" :required="true" theme="document" accept="image/*" :value="itemModel.image" @is-sync="onImageUpload($event)"></form-file>
                        </div>
                        <div :class="`p-col-12 p-md-${(isHazardous ?? false) ? 4 : 4}`">
                            <h6>QUANTITY, WEIGHT & DIMENSION</h6>
                            <form-numeric id="itemquantity" :v$="v$" vBase="itemModel" vID="quantity" :required="true" :value="itemModel.quantity" label="Quantity" @is-sync="itemModel.quantity = $event.value" :min="1"></form-numeric>
                            <form-numeric id="itemWeight" :v$="v$" vBase="itemModel" vID="weight" :required="true" :value="itemModel.weight" label="Net Weight" decimalDigit="3" @is-sync="itemModel.weight = $event.value" type="decimal" :suffix="`${$vm.$enums['weightunit'].filter((x) => x.id == weightUnit)[0]?.text?.toUpperCase()}`"></form-numeric>
                            <div class="p-d-flex p-nogutter" style="padding: 0 0.5rem !important;">
                                <div class="flex p-nogutter">
                                    <form-numeric id="itemlength" :v$="v$" :value="itemModel.length" label="Length" decimalDigit="2" @is-sync="itemModel.length = $event.value" type="decimal" :suffix="`${$vm.$enums['lengthunit'].filter((x) => x.id == dimensionUnit)[0]?.text?.toUpperCase()}`"></form-numeric>
                                </div>
                                <div class="flex p-nogutter">
                                    <form-numeric id="itemwidth" :v$="v$" :value="itemModel.width" label="Width" decimalDigit="2" @is-sync="itemModel.width = $event.value" type="decimal" :suffix="`${$vm.$enums['lengthunit'].filter((x) => x.id == dimensionUnit)[0]?.text?.toUpperCase()}`"></form-numeric>
                                </div>
                                <div class="flex p-nogutter">
                                    <form-numeric id="itemheight" :v$="v$" :value="itemModel.height" label="Height" decimalDigit="2" @is-sync="itemModel.height = $event.value" type="decimal" :suffix="`${$vm.$enums['lengthunit'].filter((x) => x.id == dimensionUnit)[0]?.text?.toUpperCase()}`"></form-numeric>
                                </div>
                            </div>
                            <form-boolean id="volumeAutoCompute" :value="itemModel.volumeAutoCompute" type="toggle" label="Auto Compute Volume?" textPosition="left" @is-sync="itemModel.volumeAutoCompute = $event.value"></form-boolean>
                            <form-numeric id="volume" :v$="v$" :required="false" :disabled="itemModel.volumeAutoCompute ?? false" :value="itemModel.volume" label="Volume" decimalDigit="4" @is-sync="itemModel.volume = $event.value; itemModel.volumeUnit = 200;" type="decimal" suffix="m3" :showCopy="false"></form-numeric>
                            <div v-if="(isHazardous ?? false)">
                                <h6>HAZMAT</h6>
                                <form-lookup id="dG_HazmatID" label="UN Code" :required="false" :source="`services/admin/dangerousgood`" sourceType="url" :value="itemModel.dG_HazmatID" :v$="v$" :textField="'text2'" :valueField="'id'" @is-sync="{itemModel.dG_HazmatID = $event.value; itemModel.dG_UnCode = 'UN' + $event?.record?.code ?? '';}" :filterFields="['code', 'name', 'description', 'text2']" :sorted="false" />
                                <form-numeric v-if="itemModel.dG_HazmatID === 1202" id="dG_DryIceNetWeight" :required="itemModel.dG_HazmatID === 1202" :v$="v$" :value="itemModel.dG_DryIceNetWeight" label="Net Weight (Dry Ice)" decimalDigit="2" @is-sync="itemModel.dG_DryIceNetWeight = $event.value" type="decimal" :suffix="`${$vm.$enums['dg_weightunit'].filter((x) => x.id == itemModel.dG_DryIceUnitWeight)[0]?.text?.toUpperCase()}`"></form-numeric>
                                <form-lookup v-if="itemModel.dG_HazmatID === 1202" id="dG_DryIceUnitWeight" :required="false" type="enum" source="dg_weightunit" :value="itemModel.dG_DryIceUnitWeight" label="Unit Weight(Dry Ice)" @is-sync="itemModel.dG_DryIceUnitWeight = $event.value" :v$="v$"></form-lookup>
                                <form-lookup id="dG_RegulatedCode" :required="false" type="enum" source="dg_regulationtype" :value="itemModel?.dG_RegulatedCode" label="Regulated Code" @is-sync="itemModel.dG_RegulatedCode = $event.value" :v$="v$"></form-lookup>
                            </div>
                            <form-file v-if="!(isHazardous ?? false)" id="fileOne" label="Image" :required="true" theme="document" accept="image/*" :value="itemModel.image" @is-sync="onImageUpload($event)"></form-file>
                        </div>
                        <div :class="`p-col-12 p-md-4`">
                            <h6>DECLARATIONS</h6>
                            <form-numeric id="itemConsignmentValue"  :required="true" :v$="v$" vBase="itemModel" vID="consignmentValue"  :value="itemModel.consignmentValue" label="Declared Value" decimalDigit="2" @is-sync="itemModel.consignmentValue = $event.value" type="currency" :currency="`${$vm.$currencies.filter(x => x.id === currencyID)[0]?.code}`"></form-numeric>
                            <form-numeric id="itemCustomsValue" :v$="v$"  vBase="itemModel" vID="customsValue" :value="itemModel.customsValue" label="Customs Value" decimalDigit="2" @is-sync="itemModel.customsValue = $event.value" type="currency" :currency="`${$vm.$currencies.filter(x => x.id === currencyID)[0]?.code}`"></form-numeric>
                            <form-numeric id="itemDiscountValue" :v$="v$"  vBase="itemModel" vID="discountValue" :value="itemModel.discountValue" label="Discount Value" decimalDigit="2" :max="itemModel.consignmentValue" @is-sync="itemModel.discountValue = $event.value" type="currency"  :currency="`${$vm.$currencies.filter(x => x.id === currencyID)[0]?.code}`"></form-numeric>
                            <form-numeric id="itemInsuranceValue" :v$="v$"  vBase="itemModel" vID="insuranceValue" :value="itemModel.insuranceValue" label="Insurance Value" decimalDigit="2" @is-sync="itemModel.insuranceValue = $event.value" type="currency" :currency="`${$vm.$currencies.filter(x => x.id === currencyID)[0]?.code}`"></form-numeric>
                            <form-numeric id="itemDutyValue"  v-show="formConsignment.incoterm == 64" :required="!Domestic && formConsignment.incoterm == 64"  :v$="v$"  vBase="itemModel" vID="dutyValue" :value="itemModel.dutyValue" label="Duty Paid Value" decimalDigit="2" :min="0.01" @is-sync="itemModel.dutyValue = $event.value" type="currency" :currency="`${$vm.$currencies.filter(x => x.id === currencyID)[0]?.code}`"></form-numeric>
                            <form-numeric id="itemTaxValue"  v-show="formConsignment.incoterm == 64" :required="!Domestic && formConsignment.incoterm == 64"  :v$="v$"  vBase="itemModel" vID="taxValue" :value="itemModel.taxValue" label="Duty Tax Paid Value" decimalDigit="2" :min="0.01" @is-sync="itemModel.taxValue = $event.value" type="currency" :currency="`${$vm.$currencies.filter(x => x.id === currencyID)[0]?.code}`"></form-numeric>
                            <form-numeric id="itemTaxRate"  v-show="formConsignment.incoterm == 64" :required="!Domestic && formConsignment.incoterm == 64"  :v$="v$"  vBase="itemModel" vID="taxRate" :value="itemModel.taxRate" label="Tax Rate" decimalDigit="2" @is-sync="itemModel.taxRate = $event.value" suffix="%"></form-numeric>
                        </div>
                    </div>

                </div>
            </div>
        </is-dialog>
    </keep-alive>
    <is-dialog header="Delete Record?" :visible="itemDialog.delete && !itemDialog.edit && !itemDialog.add" @is-confirm="onManageItem(itemModel)" @is-cancel="this.itemDialog.delete = false; this.itemDialog.add = false; this.itemDialog.edit = false;">
        <div class="confirmation-content p-d-flex">
            <div>
                <i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 2rem" />
            </div>
            <div>
                You are about to delete this record:

                <table style="margin: 10px 0; padding: 10px; background-color: var(--surface-d); width: 100%;">
                    <tr v-for="data in deleteItemDisplay" :key="data.id">
                        <th style="text-align:left;">{{ $filters.titleize(data) }}</th>
                        <td>:</td>
                        <td> {{ forDeleteItem[data] }}</td>
                    </tr>
                </table>

                Please be aware of the following:
                <ul>
                    <li>For records that uses unique code field, you cannot reuse the same code again.</li>
                    <li>For records that has activity, the record will only be disabled and archived.</li>
                    <li>Also deleting a record may be irreversible action.</li>
                </ul>
                Are you sure you still want to proceed?
            </div>
        </div>
    </is-dialog>
    <!-- CLONE PACKGE DIALOG -->
    <is-dialog header="Clone Record?" :visible="dialog.clone" @is-confirm="manageClone" @is-cancel="{this.dialog.clone = false; this.cloneModel = { source: null,code: null,iteration: 1};}" :confirmDisabled="v$.$invalid">
        <div class="confirmation-content p-d-flex">
            <div class="p-col-12">
                <form-input @keyup="onClone_KeyupEvent" id="cloneCode" label="Carton Number" :max-length="20" :required="true" :v$="v$"  vBase="cloneModel" vID="code" :value="cloneModel.code" @is-sync="cloneModel.code = $event.value" placeholder="Carton Number" v-focus />
                <form-numeric @keyup="onClone_KeyupEvent" id="cloneQty" :v$="v$" :required="true" :min="1" :max="99" :value="cloneModel.iteration" label="Iteration" @is-sync="cloneModel.iteration = $event.value;"></form-numeric>
            </div>
        </div>
    </is-dialog>
        <cosmos-grid id="PackageList" :persistent="false" :sourceType="packageGridSource.type" :source="packageGridSource.source" :multiLevel="true" :showCheckbox="false" :sourcePaged="true" :searchFilters="searchFields" :autoGenerate="false" :columns="columns" :deleteColumns="searchFields" :showExport="false" :showDelete="false" :showEdit="false" :showHeader="!isDisabled" :showOperation="false" @is-source="onPackageSource($event)" @is-refresh="getSource()">
            <template v-if="!isDisabled" #toolbar>
                <div class="p-d-flex p-jc-between p-ai-center">
                    <span>
                        <pv-button v-if="!isDisabled" icon="pi pi-fw pi-plus" label="Add New Package" style="margin-right: 5px;" @click="onPackageCreate"></pv-button>
                    </span>
                </div>
            </template>
            <pv-grid-column field="code" header="PACKAGE DETAIL">
                <template #body="slotProps">
                    <div class="p-d-flex p-grid" style="padding:0; margin:0; margin-bottom:-1rem;">
                        <div class="p-col-12 p-md-4">
                            <div class="card" :style="`background:var(--surface-a); margin-top: -1rem; min-height: 17rem; ` ">
                                <table class="p-code-text" style="width: calc(100% + 2rem); padding: 0.25rem 0.5rem; margin: -2rem -1rem;" cellspacing="0">
                                    <tbody>
                                        <tr>
                                            <th colspan="3" class="p-code-text" style="text-align: left; padding: 0.50rem 0.5rem; border-bottom: 0.01rem solid var(--surface-b); background: var(--surface-b)">
                                                GENERAL
                                            </th>
                                        </tr>
                                        <tr v-if="slotProps.data.carrierReference" style="border-bottom:thin; border:0.01rem;">
                                            <th class="p-code-text" style="text-align: left; padding: 0.25rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); border-right: 0.1rem solid var(--surface-d);">Shipping Reference</th>
                                            <td colspan="2" class="p-code-text" style="width:calc(80%);text-align: left; padding: 0.25rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); "><div><a :href="`${$filters.formatString($vm.$providers.filter((x) => x.id == formData?.serviceProviderID)[0]?.trackingURL, slotProps.data.carrierReference,formData?.shipTo_PostalCode)}`" target="_blank" style="font-weight: bold;"> {{slotProps.data.carrierReference}} </a></div></td>
                                        </tr>
                                        <tr v-if="slotProps.data.returnReference" style="border-bottom:thin; border:0.01rem;">
                                            <th class="p-code-text" style="text-align: left; padding: 0.25rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); border-right: 0.1rem solid var(--surface-d);">Return Reference</th>
                                            <td colspan="2" class="p-code-text" style="width:calc(80%);text-align: left; padding: 0.25rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); "><div v-html="slotProps.data.returnReference"></div></td>
                                        </tr>
                                        <tr style="border-bottom:thin; border:0.01rem;">
                                            <th class="p-code-text" style="text-align: left; padding: 0.25rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); border-right: 0.1rem solid var(--surface-d);">Reference</th>
                                            <td colspan="2" class="p-code-text" style="width:calc(80%);text-align: left; padding: 0.25rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); "><div v-html="slotProps.data.code"></div></td>
                                        </tr>
                                        <tr style="border-bottom:thin; border:0.01rem;">
                                            <th class="p-code-text" style="text-align: left; padding: 0.25rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); border-right: 0.1rem solid var(--surface-d);">Content Summary</th>
                                            <td colspan="2" class="p-code-text" style="width:calc(80%);text-align: left; padding: 0.25rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); "><div v-html="slotProps.data.name"></div></td>
                                        </tr>
                                        <tr style="border-bottom:thin; border:0.01rem;">
                                            <th class="p-code-text" style="text-align: left; padding: 0.25rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); border-right: 0.1rem solid var(--surface-d);">Dimension</th>
                                            <td colspan="2" class="p-code-text" style="width:calc(80%);text-align: left; padding: 0.25rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); "><div v-html="`${slotProps.data.length} ${dimension?.text?.toLowerCase()} x ${slotProps.data.width} ${dimension?.text?.toLowerCase()} x ${slotProps.data.height} ${dimension?.text?.toLowerCase()}`"></div></td>
                                        </tr>
                                        <tr style="border-bottom:thin; border:0.01rem;">
                                            <th class="p-code-text" style="text-align: left; padding: 0.25rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); border-right: 0.1rem solid var(--surface-d);">Volume</th>
                                            <td colspan="2" class="p-code-text" style="width:calc(80%);text-align: left; padding: 0.25rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); "><div v-html="`${$filters.formatNumber(slotProps.data?.volume ?? 0,4,country?.code)} ${$vm.$enums['volumeunit'].filter((x) => x.id == slotProps.data?.volumeUnit ?? 200)[0]?.code?.replace('3', '<sup>3</sup>')?.toLowerCase() ?? ''}`"></div></td>
                                        </tr>
                                        <tr>
                                            <th colspan="3" class="p-code-text" style="text-align: left; padding: 0.50rem 0.5rem; border-bottom: 0.01rem solid var(--surface-b); background: var(--surface-b)">
                                                WEIGHT
                                            </th>
                                        </tr>
                                        <tr style="border-bottom:thin; border:0.01rem;">
                                            <th class="p-code-text" style="text-align: left; padding: 0.25rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); border-right: 0.1rem solid var(--surface-d);">Gross Weight</th>
                                            <td colspan="2" class="p-code-text" style="width:calc(80%);text-align: left; padding: 0.25rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); "><div v-html="`${$filters.formatNumber(slotProps.data.weight,3,country?.code)} ${weightUnitRecord?.text}`"></div></td>
                                        </tr>
                                        <tr style="border-bottom:thin; border:0.01rem;">
                                            <th class="p-code-text" style="text-align: left; padding: 0.25rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); border-right: 0.1rem solid var(--surface-d);">Billable Weight</th>
                                            <td colspan="2" class="p-code-text" style="width:calc(80%);text-align: left; padding: 0.25rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); "><div v-html="`${$filters.formatNumber(slotProps.data.billableWeight ?? 0,3,country?.code)} ${($vm.$enums['weightunit'].filter((x) => x.id == slotProps.data.billableWeightUnit)[0]?.text ?? weightUnitRecord?.text)}`"></div></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div class="p-col-12 p-md-4" style="">
                            <div class="card" :style="`background:var(--surface-a); margin-top: -1rem; min-height: 17rem; ` ">
                                <table class="p-code-text" style="width: calc(100% + 2rem); padding: 0.25rem 0.5rem; margin: -2rem -1rem;" cellspacing="0">
                                    <tbody>
                                        <tr>
                                            <th colspan="3" class="p-code-text" style="text-align: left; padding: 0.50rem 0.5rem; border-bottom: 0.01rem solid var(--surface-b); background: var(--surface-b)">
                                                DECLARATION
                                            </th>
                                        </tr>
                                        <tr style="border-bottom:thin; border:0.01rem;">
                                            <th class="p-code-text" style="text-align: left; padding: 0.25rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); border-right: 0.1rem solid var(--surface-d);">Declared Value</th>
                                            <td colspan="2" class="p-code-text" style="width:calc(80%);text-align: right; padding: 0.25rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); "><div v-html="`${$filters.formatCurrency(slotProps.data.consignmentValue ?? 0.00,currency?.code?.toUpperCase(),country?.code,2)}`"></div></td>
                                        </tr>
                                        <tr style="border-bottom:thin; border:0.01rem;">
                                            <th class="p-code-text" style="text-align: left; padding: 0.25rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); border-right: 0.1rem solid var(--surface-d);">Customs Value</th>
                                            <td colspan="2" class="p-code-text" style="width: calc(80%); text-align: right; padding: 0.25rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); "><div v-html="`${$filters.formatCurrency(slotProps.data.customsValue ?? 0.00,currency?.code?.toUpperCase(),country?.code,2)}`"></div></td>
                                        </tr>
                                        <tr style="border-bottom:thin; border:0.01rem;">
                                            <th class="p-code-text" style="text-align: left; padding: 0.25rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); border-right: 0.1rem solid var(--surface-d);">Discount Value</th>
                                            <td colspan="2" class="p-code-text" style="width: calc(80%); text-align: right; padding: 0.25rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); "><div v-html="`${$filters.formatCurrency(slotProps.data.discountValue ?? 0.00,currency?.code?.toUpperCase(),country?.code,2)}`"></div></td>
                                        </tr>
                                        <tr style="border-bottom:thin; border:0.01rem;">
                                            <th class="p-code-text" style="text-align: left; padding: 0.25rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); border-right: 0.1rem solid var(--surface-d);">Insurance Value</th>
                                            <td colspan="2" class="p-code-text" style="width: calc(80%); text-align: right; padding: 0.25rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); "><div v-html="`${$filters.formatCurrency(slotProps.data.insuranceValue ?? 0.00,currency?.code?.toUpperCase(),country?.code,2)}`"></div></td>
                                        </tr>
                                        <tr style="border-bottom:thin; border:0.01rem;">
                                            <th class="p-code-text" style="text-align: left; padding: 0.25rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); border-right: 0.1rem solid var(--surface-d);">Duty Paid Value</th>
                                            <td colspan="2" class="p-code-text" style="width: calc(80%); text-align: right; padding: 0.25rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); "><div v-html="`${$filters.formatCurrency(slotProps.data.dutyValue ?? 0.00,currency?.code?.toUpperCase(),country?.code,2)}`"></div></td>
                                        </tr>
                                        <tr style="border-bottom:thin; border:0.01rem;">
                                            <th class="p-code-text" style="text-align: left; padding: 0.25rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); border-right: 0.1rem solid var(--surface-d);">Tax Paid Value</th>
                                            <td colspan="2" class="p-code-text" style="width: calc(80%); text-align: right; padding: 0.25rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); "><div v-html="`${$filters.formatCurrency(slotProps.data.taxValue ?? 0.00,currency?.code?.toUpperCase(),country?.code,2)}`"></div></td>
                                        </tr>
                                        <tr style="border-bottom:thin; border:0.01rem;">
                                            <th class="p-code-text" style="text-align: left; padding: 0.25rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); border-right: 0.1rem solid var(--surface-d);">Tax Rate Value</th>
                                            <td colspan="2" class="p-code-text" style="width: calc(80%); text-align: right; padding: 0.25rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); "><div v-html="`${$filters.formatNumber(slotProps.data.taxRate ?? 0.00,2,country?.code)}%`"></div></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div class="p-col-12 p-md-4" style="">
                            <div class="card" :style="`background:var(--surface-a); margin-top: -1rem; min-height: 17rem; ` ">
                                <table class="p-code-text" style="width: calc(100% + 2rem); padding: 0.25rem 0.5rem; margin: -2rem -1rem;" cellspacing="0">
                                    <tbody>
                                        <tr>
                                            <th colspan="4" class="p-code-text" style="text-align: left; padding: 0.50rem 0.5rem; border-bottom: 0.01rem solid var(--surface-b); background: var(--surface-b)">
                                                ADDITIONAL HANDLING
                                            </th>
                                        </tr>
                                        <tr style="border-bottom:thin; border:0.01rem;">
                                            <th colspan="2" class="p-code-text" style="text-align: left; padding: 0.25rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); border-right: 0.1rem solid var(--surface-d);">Additional Handling</th>
                                            <td colspan="2" class="p-code-text" style="width:calc(80%);text-align: left; padding: 0.25rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); "><div v-if="slotProps.data.additionalHandling" v-html="`[${$vm.$enums['packageadditionalhandlingtype'].filter((x) => x.id == slotProps.data.additionalHandling)[0]?.code}] , ${$vm.$enums['packageadditionalhandlingtype'].filter((x) => x.id == slotProps.data.additionalHandling)[0]?.text}`"></div></td>
                                        </tr>
                                        <tr v-if="!isDomestic" style="border-bottom:thin; border:0.01rem;">
                                            <th colspan="2" class="p-code-text" style="text-align: left; padding: 0.25rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); border-right: 0.1rem solid var(--surface-d);">CPC Number</th>
                                            <td colspan="2" class="p-code-text" style="width:calc(80%);text-align: left; padding: 0.25rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); "><div v-html="slotProps.data.cpcNumber"></div></td>
                                        </tr>
                                        <tr style="border-bottom:thin; border:0.01rem;">
                                            <th colspan="2" class="p-code-text" style="text-align: left; padding: 0.25rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); border-right: 0.1rem solid var(--surface-d);">SSCC Number</th>
                                            <td colspan="2" class="p-code-text" style="width:calc(80%);text-align: left; padding: 0.25rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); "><div v-html="slotProps.data.ssccNumber"></div></td>
                                        </tr>

                                        <tr>
                                            <th colspan="4" class="p-code-text" style="text-align: left; padding: 0.50rem 0.5rem; border-bottom: 0.01rem solid var(--surface-b); background: var(--surface-b)">
                                                MARKINGS
                                            </th>
                                        </tr>
                                        <tr style="border-bottom:thin; border:0.01rem;">
                                            <th class="p-code-text" style="text-align: left; padding: 0.25rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); border-right: 0.1rem solid var(--surface-d);">Is Pallet</th>
                                            <td colspan="1" class="p-code-text" style="width:calc(80%);text-align: left; padding: 0.25rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); "><div v-html="`${(slotProps.data.isPallet ? 'YES' : 'NO')}`"></div></td>
                                            <th class="p-code-text" style="text-align: left; padding: 0.25rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); border-right: 0.1rem solid var(--surface-d);">Use Tail Lift</th>
                                            <td colspan="1" class="p-code-text" style="width:calc(80%);text-align: left; padding: 0.25rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); "><div v-html="`${(slotProps.data.useTailLift ? 'YES' : 'NO')}`"></div></td>
                                        </tr>
                                        <tr style="border-bottom:thin; border:0.01rem;">
                                            <th class="p-code-text" style="text-align: left; padding: 0.25rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); border-right: 0.1rem solid var(--surface-d);">Has Liquid</th>
                                            <td colspan="1" class="p-code-text" style="width:calc(80%);text-align: left; padding: 0.25rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); "><div v-html="`${(slotProps.data.hasLiquid ? 'YES' : 'NO')}`"></div></td>
                                            <th class="p-code-text" style="text-align: left; padding: 0.25rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); border-right: 0.1rem solid var(--surface-d);">Is Fragile</th>
                                            <td colspan="1" class="p-code-text" style="width:calc(80%);text-align: left; padding: 0.25rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); "><div v-html="`${(slotProps.data.isFragile ? 'YES' : 'NO')}`"></div></td>
                                        </tr>

                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </template>
            </pv-grid-column>
            <pv-grid-column v-if="!isDisabled" field="mask" headerStyle="min-width: 8rem; max-width: 10rem; width: 7rem" style="`min-width: 7rem; max-width: 8rem; border-left: 1px solid var(--surface-d); text-align: center; object-position: center; padding: 0 5px;" :frozen="true" :alignFrozen="'right'">
                <template #body="slotProps">
                    <div class="p-d-flex p-jc-end">
                            <pv-button icon="pi pi-clone" iconPos="right" @click="onClone(slotProps.data)" class="p-button-info" style="margin-right:0.10rem" />
                            <pv-button icon="pi pi-pencil" iconPos="right" @click="onPackageEdit(slotProps.data)" class="p-button-info" style="margin-right: 0.10rem "  />
                            <pv-button icon="pi pi-trash" iconPos="right" @click="onPackageDelete(slotProps.data)" class="p-button-danger" />
                    </div>
                </template>
            </pv-grid-column>
            <template #expansion="slotProps">
                <cosmos-grid :id="`PackageItemList_${slotProps.data.code}`" :persistent="false" sourceType="array" :source="slotProps.data.items ?? []" :sourcePaged="true" :searchFilters="searchFields" :autoGenerate="false" :columns="columns" :deleteColumns="searchFields" :showExport="false" :showDelete="false" :showEdit="false" :showHeader="false" :showOperation="false" :showCheckbox="false">
                    <pv-grid-column field="code" :header="`ITEM DETAILS`" headerStyle="min-width: 25rem" style="min-width: 25rem;">
                        <template #body="slotProps">
                            <div class="p-d-flex p-grid" style="padding:0; margin:0; margin-bottom:-1rem;">
                                <div class="p-col-12 p-md-6">
                                    <div class="card" :style="`background:var(--surface-a); margin-top: -1rem; min-height: 20rem;` ">
                                        <table class="p-code-text" style="width: calc(100% + 2rem); padding: 0.25rem 0.5rem; margin: -2rem -1rem;" cellspacing="0">
                                            <tbody>
                                                <tr>
                                                    <th colspan="6" class="p-code-text" style="text-align: left; padding: 0.50rem 0.5rem; border-bottom: 0.01rem solid var(--surface-b); background: var(--surface-b)">
                                                        PRODUCT
                                                    </th>
                                                </tr>
                                                <tr style="border-bottom:thin; border:0.01rem;">
                                                    <th class="p-code-text" style="text-align: left; padding: 0.25rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); border-right: 0.1rem solid var(--surface-d);">Code</th>
                                                    <td colspan="3" class="p-code-text" style="width:calc(80%);text-align: left; padding: 0.25rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); "><div v-html="slotProps.data.code"></div></td>
                                                    <td colspan="2" rowspan="5" class="p-code-text" style="width:calc(80%);text-align: center; padding: 0.25rem 0.5rem; border: 0.01rem solid var(--surface-d); ">
                                                        <img v-if="slotProps.data?.id > 0 && ((slotProps?.data?.image?.contentType?.length ?? 0) === 0)" :src="`${cdnUrl}/${tenantCode}/product/images/${slotProps.data.mask}.${(slotProps.data?.contentType ?? 'png').replace('image/','') ?? 'png'}?${Math.random()}`" onerror="this.onerror=null; this.src = '/_assets/img/no-image-box.png'" class="image-center" style="width: 10rem !important; height: 8rem !important; margin: auto; object-fit:contain;" />
                                                        <img v-else-if="((slotProps.data?.image?.contentType?.length ?? 0) !== 0)" :src="`data:${slotProps.data.image?.contentType};base64, ${slotProps.data.image?.file}`" onerror="this.onerror=null; this.src = '/_assets/img/no-image-box.png'" class="image-center" style="width: 10rem !important; height: 8rem !important; margin: auto; object-fit:contain;" />
                                                        <img v-else-if="(Array.isArray(slotProps.data?.image))" :src="`data:${slotProps.data.image[0]?.contentType};base64, ${slotProps.data.image[0]?.file}`" onerror="this.onerror=null; this.src = '/_assets/img/no-image-box.png'" class="image-center" style="width: 10rem !important; height: 8rem !important; margin: auto; object-fit:contain;" />
                                                        <img v-else src="/_assets/img/no-image-box.png" onerror="this.onerror=null; this.src = '/_assets/img/no-image-box.png'" class="image-center" style="width: 10rem !important; height: 8rem !important; margin: auto; object-fit:contain;" />
                                                    </td>
                                                </tr>
                                                <tr style="border-bottom:thin; border:0.01rem;">
                                                    <th class="p-code-text" style="text-align: left; padding: 0.25rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); border-right: 0.1rem solid var(--surface-d);">Product Name</th>
                                                    <td colspan="4" class="p-code-text" style="width:calc(80%);text-align: left; padding: 0.25rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); "><div v-html="slotProps.data.name"></div></td>
                                                </tr>
                                                <tr style="border-bottom:thin; border:0.01rem;">
                                                    <th class="p-code-text" style="text-align: left; padding: 0.25rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); border-right: 0.1rem solid var(--surface-d);">Attribute</th>
                                                    <td colspan="4" class="p-code-text" style="width:calc(80%);text-align: left; padding: 0.25rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); "><div v-html="slotProps.data.attribute"></div></td>
                                                </tr>
                                                <tr style="border-bottom:thin; border:0.01rem;">
                                                    <th class="p-code-text" style="text-align: left; padding: 0.25rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); border-right: 0.1rem solid var(--surface-d);">SKU</th>
                                                    <td colspan="4" class="p-code-text" style="width:calc(80%);text-align: left; padding: 0.25rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); "><div v-html="slotProps.data.sku"></div></td>
                                                </tr>
                                                <tr style="border-bottom:thin; border:0.01rem;">
                                                    <th class="p-code-text" style="text-align: left; padding: 0.25rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); border-right: 0.1rem solid var(--surface-d);">GTIN</th>
                                                    <td colspan="4" class="p-code-text" style="width:calc(80%);text-align: left; padding: 0.25rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); "><div v-html="slotProps.data.gtin"></div></td>
                                                </tr>
                                                <tr>
                                                    <th colspan="6" class="p-code-text" style="text-align: left; padding: 0.50rem 0.5rem; border-bottom: 0.01rem solid var(--surface-b); background: var(--surface-b)">
                                                        COMMODITY
                                                    </th>
                                                </tr>
                                                <tr style="border-bottom:thin; border:0.01rem;">
                                                    <th colspan="2" class="p-code-text" style="text-align: left; padding: 0.25rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); border-right: 0.1rem solid var(--surface-d);">HS Code</th>
                                                    <td colspan="4" class="p-code-text" style="width:calc(80%);text-align: left; padding: 0.25rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); "><div v-html="slotProps.data.htsCode"></div></td>
                                                </tr>
                                                <tr style="border-bottom:thin; border:0.01rem;">
                                                    <th colspan="2" class="p-code-text" style="text-align: left; padding: 0.25rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); border-right: 0.1rem solid var(--surface-d);">Manufacturer Country</th>
                                                    <td colspan="4" class="p-code-text" style="width:calc(80%);text-align: left; padding: 0.25rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); "><div v-if="slotProps.data.manufacturerCountryID" v-html="`${$vm.$countries.filter(x => x.id === slotProps.data.manufacturerCountryID)[0]?.text}`"></div></td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div class="p-col-12 p-md-3">
                                    <div class="card" :style="`background:var(--surface-a); margin-top: -1rem; min-height: 20rem; ` ">
                                        <table class="p-code-text" style="width: calc(100% + 2rem); padding: 0.25rem 0.5rem; margin: -2rem -1rem;" cellspacing="0">
                                            <tbody>
                                                <tr>
                                                    <th colspan="3" class="p-code-text" style="text-align: left; padding: 0.50rem 0.5rem; border-bottom: 0.01rem solid var(--surface-b); background: var(--surface-b)">
                                                        PIECES, WEIGHT AND DIMENSION
                                                    </th>
                                                </tr>
                                                <tr style="border-bottom:thin; border:0.01rem;">
                                                    <th class="p-code-text" style="text-align: left; padding: 0.25rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); border-right: 0.1rem solid var(--surface-d);">Quantity</th>
                                                    <td colspan="2" class="p-code-text" style="width: calc(80%); text-align: right; padding: 0.25rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); "><div v-html="`${$filters.formatNumber(slotProps.data.quantity ?? 0.00,0,country?.code)}`"></div></td>
                                                </tr>
                                                <tr style="border-bottom:thin; border:0.01rem;">
                                                    <th class="p-code-text" style="text-align: left; padding: 0.25rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); border-right: 0.1rem solid var(--surface-d);">Net Weight</th>
                                                    <td colspan="2" class="p-code-text" style="width:calc(80%);text-align: left; padding: 0.25rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); "><div v-html="`${$filters.formatNumber(slotProps.data.weight,3,country?.code)} ${weightUnitRecord?.text}`"></div></td>
                                                </tr>
                                                <tr style="border-bottom:thin; border:0.01rem;">
                                                    <th class="p-code-text" style="text-align: left; padding: 0.25rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); border-right: 0.1rem solid var(--surface-d);">Dimension</th>
                                                    <td colspan="2" class="p-code-text" style="width:calc(80%);text-align: left; padding: 0.25rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); "><div v-html="`${slotProps.data.length} ${dimension?.text?.toLowerCase()} x ${slotProps.data.width} ${dimension?.text?.toLowerCase()} x ${slotProps.data.height} ${dimension?.text?.toLowerCase()}`"></div></td>
                                                </tr>
                                                <tr style="border-bottom:thin; border:0.01rem;">
                                                    <th class="p-code-text" style="text-align: left; padding: 0.25rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); border-right: 0.1rem solid var(--surface-d);">Volume</th>
                                                    <td colspan="2" class="p-code-text" style="width:calc(80%);text-align: left; padding: 0.25rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); "><div v-html="`${$filters.formatNumber(slotProps.data?.volume ?? 0,4,country?.code)} ${$vm.$enums['volumeunit'].filter((x) => x.id == slotProps.data?.volumeUnit ?? 200)[0]?.code?.replace('3', '<sup>3</sup>')?.toLowerCase() ?? ''}`"></div></td>
                                                </tr>
                                                <tr>
                                                    <th colspan="3" class="p-code-text" style="text-align: left; padding: 0.50rem 0.5rem; border-bottom: 0.01rem solid var(--surface-b); background: var(--surface-b)">
                                                        HAZARDOUS MATERIAL
                                                    </th>
                                                </tr>
                                                <tr style="border-bottom:thin; border:0.01rem;">
                                                    <th class="p-code-text" style="text-align: left; padding: 0.25rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); border-right: 0.1rem solid var(--surface-d);">UN Code</th>
                                                    <td colspan="2" class="p-code-text" style="width:calc(80%);text-align: left; padding: 0.25rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); "><div v-html="`${(slotProps.data?.dG_HazmatID > 0 ? 'UN' : '')}${hazMatDS?.data.filter(x => x.id === slotProps.data.dG_HazmatID)[0]?.code ?? ''}`"></div></td>
                                                </tr>
                                                <tr style="border-bottom:thin; border:0.01rem;">
                                                    <th class="p-code-text" style="text-align: left; padding: 0.25rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); border-right: 0.1rem solid var(--surface-d);">Net Weight</th>
                                                    <td colspan="2" class="p-code-text" style="width:calc(80%);text-align: left; padding: 0.25rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); "><div v-html="`${(slotProps.data?.dG_HazmatID === 1202 ?  $filters.formatNumber(slotProps.data.dG_DryIceNetWeight ?? 0,3,country?.code) : '')}`"></div></td>
                                                </tr>
                                                <tr style="border-bottom:thin; border:0.01rem;">
                                                    <th class="p-code-text" style="text-align: left; padding: 0.25rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); border-right: 0.1rem solid var(--surface-d);">Weight Unit</th>
                                                    <td colspan="2" class="p-code-text" style="width:calc(80%);text-align: left; padding: 0.25rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); "><div v-html="`${(slotProps.data?.dG_HazmatID === 1202 ? $vm.$enums['dg_weightunit'].filter((x) => x.id == slotProps.data?.dG_DryIceUnitWeight)[0]?.text ?? '' : '')}`"></div></td>
                                                </tr>
                                                <tr style="border-bottom:thin; border:0.01rem;">
                                                    <th class="p-code-text" style="text-align: left; padding: 0.25rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); border-right: 0.1rem solid var(--surface-d);">Regulated Code</th>
                                                    <td colspan="2" class="p-code-text" style="width:calc(80%);text-align: left; padding: 0.25rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); "><div v-html="`${$vm.$enums['dg_regulationtype'].filter((x) => x.id == slotProps.data?.dG_RegulatedCode)[0]?.text ?? ''}`"></div></td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div class="p-col-12 p-md-3">
                                    <div class="card" :style="`background:var(--surface-a); margin-top: -1rem; min-height: 20rem; ` ">
                                        <table class="p-code-text" style="width: calc(100% + 2rem); padding: 0.25rem 0.5rem; margin: -2rem -1rem;" cellspacing="0">
                                            <tbody>
                                                <tr>
                                                    <th colspan="3" class="p-code-text" style="text-align: left; padding: 0.50rem 0.5rem; border-bottom: 0.01rem solid var(--surface-b); background: var(--surface-b)">
                                                        DECLARATION
                                                    </th>
                                                </tr>
                                                <tr style="border-bottom:thin; border:0.01rem;">
                                                    <th class="p-code-text" style="text-align: left; padding: 0.25rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); border-right: 0.1rem solid var(--surface-d);">Declared Value</th>
                                                    <td colspan="2" class="p-code-text" style="width:calc(80%);text-align: right; padding: 0.25rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); "><div v-html="`${$filters.formatCurrency(slotProps.data.consignmentValue ?? 0.00,currency?.code?.toUpperCase(),country?.code,2)}`"></div></td>
                                                </tr>
                                                <tr style="border-bottom:thin; border:0.01rem;">
                                                    <th class="p-code-text" style="text-align: left; padding: 0.25rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); border-right: 0.1rem solid var(--surface-d);">Customs Value</th>
                                                    <td colspan="2" class="p-code-text" style="width: calc(80%); text-align: right; padding: 0.25rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); "><div v-html="`${$filters.formatCurrency(slotProps.data.customsValue ?? 0.00,currency?.code?.toUpperCase(),country?.code,2)}`"></div></td>
                                                </tr>
                                                <tr style="border-bottom:thin; border:0.01rem;">
                                                    <th class="p-code-text" style="text-align: left; padding: 0.25rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); border-right: 0.1rem solid var(--surface-d);">Discount Value</th>
                                                    <td colspan="2" class="p-code-text" style="width: calc(80%); text-align: right; padding: 0.25rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); "><div v-html="`${$filters.formatCurrency(slotProps.data.discountValue ?? 0.00,currency?.code?.toUpperCase(),country?.code,2)}`"></div></td>
                                                </tr>
                                                <tr style="border-bottom:thin; border:0.01rem;">
                                                    <th class="p-code-text" style="text-align: left; padding: 0.25rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); border-right: 0.1rem solid var(--surface-d);">Insurance Value</th>
                                                    <td colspan="2" class="p-code-text" style="width: calc(80%); text-align: right; padding: 0.25rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); "><div v-html="`${$filters.formatCurrency(slotProps.data.insuranceValue ?? 0.00,currency?.code?.toUpperCase(),country?.code,2)}`"></div></td>
                                                </tr>
                                                <tr style="border-bottom:thin; border:0.01rem;">
                                                    <th class="p-code-text" style="text-align: left; padding: 0.25rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); border-right: 0.1rem solid var(--surface-d);">Duty Paid Value</th>
                                                    <td colspan="2" class="p-code-text" style="width: calc(80%); text-align: right; padding: 0.25rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); "><div v-html="`${$filters.formatCurrency(slotProps.data.dutyValue ?? 0.00,currency?.code?.toUpperCase(),country?.code,2)}`"></div></td>
                                                </tr>
                                                <tr style="border-bottom:thin; border:0.01rem;">
                                                    <th class="p-code-text" style="text-align: left; padding: 0.25rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); border-right: 0.1rem solid var(--surface-d);">Tax Paid Value</th>
                                                    <td colspan="2" class="p-code-text" style="width: calc(80%); text-align: right; padding: 0.25rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); "><div v-html="`${$filters.formatCurrency(slotProps.data.taxValue ?? 0.00,currency?.code?.toUpperCase(),country?.code,2)}`"></div></td>
                                                </tr>
                                                <tr style="border-bottom:thin; border:0.01rem;">
                                                    <th class="p-code-text" style="text-align: left; padding: 0.25rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); border-right: 0.1rem solid var(--surface-d);">Tax Rate Value</th>
                                                    <td colspan="2" class="p-code-text" style="width: calc(80%); text-align: right; padding: 0.25rem 0.5rem; border-bottom: 0.01rem solid var(--surface-d); "><div v-html="`${$filters.formatNumber(slotProps.data.taxRate ?? 0.00,2,country?.code)}%`"></div></td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </template>
                    </pv-grid-column>
                </cosmos-grid>
            </template>
        </cosmos-grid>
</template>
<script>
    import { useVuelidate } from '@vuelidate/core';
    import { mapGetters } from 'vuex';
    import { required, minLength, maxLength, helpers, minValue, maxValue } from '@vuelidate/validators';
    /** CUSTOM VALIDATOR **/
    const regex = (param) => helpers.withParams({ type: 'regex', value: param }, (value) => value.match(param) != null);
    export default {
        name: 'PackageFormTemplate',
        /*emits: ["is-sync"],*/
        props: {
            parentID: { type: Number, default: null },
            countryId: { type: Number, default: 238 },
            currencyID: { type: Number, default: 1 },
            weightUnit: { type: Number, default: 100 },
            tenantID: { type: Number, default: null },
            dimensionUnit: { type: Number, default: 100 },
            isHazardous: { type: Boolean, default: false },
            isDisabled: { type: Boolean, default: false },
            isInitSource: { type: Boolean, default: false },
            packageSource: { type: Object },
            formConsignment: { type: Object },
            defaultPackageContent: { type: String },
            defaultDeclaredValue: { type: Number, default: 0.00 },
            defaultRegulatedCode: { type: Number }


        },
        setup: () => ({ v$: useVuelidate() }),
        data() {
            return {
                model: {  
                },
                forceRefresh: {
                    package: false,
                    item: false
                },
                source: {
                    total: 0,
                    data: []
                },
                sourceItem: {
                    total: 0,
                    data: []
                },
                dialog: {
                    header: "Delete Record?",
                    add: false,
                    edit: false,
                    delete: false,
                    clone: false
                },
                itemDialog: {
                    header: "Delete Record?",
                    add: false,
                    edit: false,
                    delete: false
                },
                packageModel: {
                    id: 0,
                    temporaryID: Math.random(),
                    consignmentID: 0,
                    carrierReference: null,
                    cpcNumber: null,
                    ssccNumber: null,
                    consignmentValue: 0,
                    insuranceValue: 0,
                    dutyValue: 0,
                    taxValue: 0,
                    customsValue: 0,
                    discountValue: 0,
                    weight: 0,
                    netWeight: 0,
                    chargeableWeight: 0,
                    volumeAutoCompute: true,
                    length: 0,
                    width: 0,
                    height: 0,
                    additionalHandling: null,
                    isFragile: false,
                    hasLiquid: false,
                    isPallet: false,
                    useTailLift: false,
                    lastScan_Date: null,
                    lastScan_Location: null,
                    lastScan_Activity: null,
                    environmentID: null,
                    code: null,
                    name: null,
                    mask: null,
                    items: [],
                    itemGrid: {
                        id: "SubGrid" + Math.round().toString().replace(".", ""),
                        source: {
                            total: 0,
                            data: []
                        },
                        page: {
                            first: 0,
                            current: 0,
                            size: 10
                        },
                    }
                },
                itemModel: {
                    id: 0,
                    packageID: 0,
                    temporaryID: Math.random(),
                    productID: null,
                    barcodeID: null,
                    htsCode: null,
                    gtin: null,
                    sku: null,
                    attribute: null,
                    quantity: 0,
                    weight: 0,
                    length: 0,
                    width: 0,
                    height: 0,
                    consignmentValue: 0,
                    insuranceValue: 0,
                    customsValue: 0,
                    discountValue: 0,
                    taxRate: 0,
                    taxValue: 0,
                    manufacturerCountryID: null,
                    dG_HazmatID: null,
                    dG_UnCode: null,
                    dG_DryIceNetWeight: 0,
                    dG_DryIceUnitWeight: 1,
                    dG_RegulatedCode: null,
                    image:null,
                    environmentID: null,
                    code: null,
                    name: null,
                    mask: null
                },
                cloneModel: {
                    source: null,
                    code: null,
                    iteration: 1

                },
                deletePackageDisplay: ["Reference", "Content"],
                forDeletePackage: null,
                deleteItemDisplay: ["productCode","description","attribute","sku","gtin","hscode"],
                forDeleteItem: null
              
            }
        },
        validations() {
            let validator = {
            };

            if (this.dialog.add || this.dialog.edit) {
                validator = {
                    packageModel: {
                        code: { required, $autoDirty: true },
                        name: {  $autoDirty: true },
                        weight: { $autoDirty: true },
                        length: { $autoDirty: true },
                        width: { $autoDirty: true },
                        height: { $autoDirty: true },
                        volume: { $autoDirty: true },
                        consignmentValue: { $autoDirty: true },
                        customsValue: { $autoDirty: true },
                        discountValue: { $autoDirty: true },
                        dutyValue: { $autoDirty: true },
                        taxValue: { $autoDirty: true },
                        taxRate: { $autoDirty: true },
                        additionalHandling: { $autoDirty: true },
                        cpcNumber: { $autoDirty: true },
                        ssccNumber: { $autoDirty: true },
                        isPallet: { $autoDirty: true },
                        isFragile: { $autoDirty: true },
                        hasLiquid: { $autoDirty: true },
                        useTailLift: { $autoDirty: true },

                    }
                }
                validator.packageModel.code = { required, maxLength: maxLength(20), $autoDirty: true, regex: helpers.withMessage(`Invalid numeric value`, regex('^[0-9]{0,20}$')) };
                if ((this.packageModel.code?.length ?? 0) > 0) {
                    if ((this.packageUniqueCode.filter(x => x.code.toLowerCase() == this.packageModel.code.toLowerCase() && ((this.packageModel.id > 0) ? x.id !== this.packageModel.id : true) && ((this.packageModel.temporaryID > 0) ? x.temporaryID !== this.packageModel.temporaryID : true))?.length ?? 0) > 0) {
                        validator.packageModel.code = { required, maxLength: maxLength(20), $autoDirty: true, regex: helpers.withMessage(`Value must be unique`, regex('^[�]{0,20}$')) };        
                    }
                }
                if (((this.packageModel.name?.length ?? 0) > 0))
                    validator.packageModel.name = { maxLength: maxLength(255), $autoDirty: true };
                if (((this.packageModel.cpcNumber?.length ?? 0) > 0))
                    validator.packageModel.cpcNumber = { maxLength: maxLength(15), $autoDirty: true, regex: helpers.withMessage(`Invalid format. Only accepts alphanumeric values`, regex('^[a-zA-Z0-9]{0,15}$')) };
                if (((this.packageModel.ssccNumber?.length ?? 0) > 0))
                    validator.packageModel.ssccNumber = { maxLength: maxLength(30), $autoDirty: true, regex: helpers.withMessage(`Invalid format. Only accepts alphanumeric values`, regex('^[a-zA-Z0-9]{0,30}$')) };
                validator.packageModel.weight = { required, minValue: helpers.withMessage(`Minimum value must be at least ${this.minWeightValue}${this.weightUnitRecord?.text} `, minValue(this.minWeightValue)) };
                validator.packageModel.discountValue = { maxValue: helpers.withMessage(`The value must not exceed the declared value ${this.packageModel.consignmentValue} `, maxValue(this.packageModel.consignmentValue)) };
                if (this.formConsignment.incoterm == 64) {
                    validator.packageModel.dutyValue = { required, minValue: helpers.withMessage(`The value must be at least 0.01`, minValue(0.01)) };
                    validator.packageModel.taxValue = { required, minValue: helpers.withMessage(`The value must be at least 0.01`, minValue(0.01)) };
                }
                
                if (!this.isDomestic) {
                    validator.packageModel.name = { required, maxLength: maxLength(255), $autoDirty: true };
                    validator.packageModel.consignmentValue = { required, minValue: helpers.withMessage(`The value must be at least 0.01`, minValue(0.01)), $autoDirty: true }
                } 
            }

            if (this.itemDialog.add || this.itemDialog.edit) {
                validator = {

                    itemModel: {
                        code: { required, $autoDirty: true },
                        name: { required,$autoDirty: true },
                        attribute: { $autoDirty: true },
                        sku: { $autoDirty: true },
                        gtin: { $autoDirty: true },
                        htsCode: { $autoDirty: true },
                        manufacturerCountryID: { $autoDirty: true },
                        quantity: { $autoDirty: true },
                        weight: { $autoDirty: true },
                        length: { $autoDirty: true },
                        width: { $autoDirty: true },
                        height: { $autoDirty: true },
                        dG_HazmatID: { $autoDirty: true },
                        dG_DryIceNetWeight: { $autoDirty: true },
                        dG_DryIceUnitWeight: { $autoDirty: true },
                        dG_RegulatedCode: { $autoDirty: true },
                        consignmentValue: { $autoDirty: true },
                        customsValue: { $autoDirty: true },
                        discountValue: { $autoDirty: true },
                        dutyValue: { $autoDirty: true },
                        taxValue: { $autoDirty: true },
                        taxRate: { $autoDirty: true },

                    }
                };
                validator.itemModel.consignmentValue = { required, minValue: helpers.withMessage(`The value must be at least 0.01`, minValue(0.01)), $autoDirty: true }
                validator.itemModel.code = { required, maxLength: maxLength(255), $autoDirty: true, regex: helpers.withMessage(`Invalid format allowed special characters # _ . / \\ | - `, regex('^[a-zA-Z0-9#_./\\|-]{0,255}$')) };
                if ((this.itemModel.code?.length ?? 0) > 0) {
                    if ((this.packageItemUniqueCode.filter(x => x.code.toLowerCase() == this.itemModel.code.toLowerCase() && ((this.itemModel.id > 0) ? x.id !== this.itemModel.id : true) && ((this.itemModel.temporaryID > 0) ? x.temporaryID !== this.itemModel.temporaryID : true))?.length ?? 0) > 0) {
                        validator.itemModel.code = { required, maxLength: maxLength(255), $autoDirty: true, regex: helpers.withMessage(`Value must be unique`, regex('^[�]{0,255}$')) };
                    }
                }
                if (((this.itemModel.name?.length ?? 0) > 0))
                    validator.itemModel.name = { required, maxLength: maxLength(255), $autoDirty: true };
                validator.itemModel.weight = { required, minValue: helpers.withMessage(`Minimum value must be at least ${this.minWeightValue}${this.weightUnitRecord?.text} `, minValue(this.minWeightValue)) };
                validator.itemModel.discountValue = { maxValue: helpers.withMessage(`The value must not exceed the declared value ${this.itemModel.consignmentValue} `, maxValue(this.itemModel.consignmentValue)) };
                if (this.formConsignment.incoterm == 64) {
                    validator.itemModel.dutyValue = { required, minValue: helpers.withMessage(`The value must be at least 0.01`, minValue(0.01)) };
                    validator.itemModel.taxValue = { required, minValue: helpers.withMessage(`The value must be at least 0.01`, minValue(0.01)) };
                }

                if (!this.isDomestic) {
                    validator.itemModel.htsCode = { required, minLength: minLength(6), maxLength: maxLength(10), $autoDirty: true, regex: helpers.withMessage(`The value must be a string with a length of either 6, 8, or 10 (without spaces)`, regex('^([0-9]{6}|[0-9]{8}|[0-9]{10})$')) };
                    validator.itemModel.manufacturerCountryID = { required, $autoDirty: true, minValue: helpers.withMessage(`The value is required`, minValue(1)) }
                    validator.itemModel.consignmentValue = { required, minValue: helpers.withMessage(`The value must be at least 0.01`, minValue(0.01)), $autoDirty: true }
                } 
            }

            if (this.dialog.clone) {
                validator = {
                    cloneModel: {
                        code: { required, $autoDirty: true },
                        iteration: { required, $autoDirty: true }
                    }
                }

                validator.cloneModel.code = { required, maxLength: maxLength(20), $autoDirty: true, regex: helpers.withMessage(`Invalid numeric value`, regex('^[0-9]{0,35}$')) };
                if ((this.cloneModel.code?.length ?? 0) > 0) {
                    if ((this.packageUniqueCode.filter(x => x.code.toLowerCase() == this.cloneModel.code.toLowerCase())?.length ?? 0) > 0) {
                        validator.cloneModel.code = { required, maxLength: maxLength(20), $autoDirty: true, regex: helpers.withMessage(`Value must be unique`, regex('^[�]{0,20}$')) };
                    }
                }
            }

            return validator;
        },
        watch: {
            keys() {
                this.$nextTick(() => {
                    this.session = this.sessionKeys;
                });
            },
            'formData': {
                handler() {
                    this.$nextTick(() => {
                        this.model = this.$props.formData;
                    });
                }, deep: true
            },
            'packageSource': {
                handler() {
                    this.$nextTick(() => {
                        this.source.data = this.packageSource ?? [];
                        this.source.total = this.source.data.length;
                        //if ((this.packageSource?.length ?? 0) === 0) {
                        //    this.$nextTick(() => {
                        //        this.source.data = this.packageSource;
                        //        this.source.total = 0;
                        //    });
                        //}

                    });
                }, deep: true
            },
            'packageModel.length': {
                handler() {
                    this.$nextTick(() => {
                        this.packageModel.volume = this.packageVolume;
                    });
                }, deep: true
            },
            'packageModel.width': {
                handler() {
                    this.$nextTick(() => {
                        this.packageModel.volume = this.packageVolume;
                    });
                }, deep: true
            },
            'packageModel.height': {
                handler() {
                    this.$nextTick(() => {
                        this.packageModel.volume = this.packageVolume;
                    });
                }, deep: true
            },
            'packageModel.volumeAutoCompute': {
                handler() {
                    this.$nextTick(() => {
                        if (this.packageModel?.volumeAutoCompute)
                            this.packageModel.volume = this.packageVolume;
                    });
                }, deep: true
            },
            'itemModel.length': {
                handler() {
                    this.$nextTick(() => {
                        this.itemModel.volume = this.packageItemVolume;
                    });
                }, deep: true
            },
            'itemModel.width': {
                handler() {
                    this.$nextTick(() => {
                        this.itemModel.volume = this.packageItemVolume;
                    });
                }, deep: true
            },
            'itemModel.height': {
                handler() {
                    this.$nextTick(() => {
                        this.itemModel.volume = this.packageItemVolume;
                    });
                }, deep: true
            },
            'itemModel.volumeAutoCompute': {
                handler() {
                    this.$nextTick(() => {
                        if (this.itemModel?.volumeAutoCompute)
                            this.itemModel.volume = this.packageItemVolume;
                    });
                }, deep: true
            },
        },
        computed: {
            showToolBar() {
                return !this.$props.readOnly;
            },
            ...mapGetters({
                user: "auth/currentUser"
            }),
            sessionKeys() { return this.$store.getters["auth/session"]; },  
            cdnUrl() {
                return this.$config.config.endpoint.cdn;
            },
            tenantCode() {
                let tenantID = parseInt((this.$props?.tenantID ?? this.sessionKeys?.tenant ?? 1));
                return ((this.$config.common.$tenant() ?? []).filter((x) => x.id === tenantID)[0]?.code ?? "").toLowerCase();
            },
            dialogItemHeader() {
                if (this.itemDialog.add) return "Add Item";
                if (this.itemDialog.edit) return "Edit Item";
                return "Delete Record?"
            },
            isInValidPackage() {
                return this.v$.$invalid;
                //if (this.isDomestic)
                //    return !((this.packageModel?.code?.length ?? 0 > 0) && (this.packageModel?.weight ?? 0 > 0));
                //else
                //    return !((this.packageModel?.code?.length ?? 0 > 0) && (this.packageModel?.name?.length ?? 0 > 0) && (this.packageModel?.weight ?? 0 > 0) && ((this.packageModel?.consignmentValue ?? 0 > 0) || (this.packageModel?.customsValue ?? 0 > 0)));
            },
            isInValidItem() {
                return this.v$.$invalid;
                /*return !((this.itemModel?.code?.length ?? 0 > 0) && (this.itemModel?.name?.length ?? 0 > 0) && (this.itemModel?.weight ?? 0 > 0) && (this.itemModel?.quantity ?? 0 > 0));*/
            },
            weightUnitRecord() {
                return this.$vm.$enums['weightunit'].filter((x) => x.id == this.$props.weightUnit)[0];
            },
            minWeightValue() {
                switch(this.weightUnitRecord?.code)
                {
                    case "GM":
                        return 100;
                    case "LB":
                        return 0.22;
                    default:
                        return 0.1;
                }
            },
            dimension() {
                return this.$vm.$enums['lengthunit'].filter((x) => x.id == this.$props.dimensionUnit)[0];
            },
            country() {
                return this.$vm.$countries?.filter((x) => x.id == this.$props.countryID)[0];
            },
            currency() {
                return this.$vm.$currencies?.filter((x) => x.id == this.$props.currencyID)[0];
            },
            packageVolume() {
                if (this.packageModel.volumeAutoCompute ?? false) {
                    if (((this.packageModel?.length ?? 0) > 0) && ((this.packageModel?.width ?? 0) > 0) && ((this.packageModel?.height ?? 0) > 0)) {
                        return this.$filters.convertVolume(200, this.$props.dimensionUnit, (this.packageModel?.length ?? 0), (this.packageModel?.width ?? 0), (this.packageModel?.height ?? 0));
                    }
                }
                return (this.packageModel?.volume ?? 0);
            },
            packageItemVolume() {
                if (this.itemModel.volumeAutoCompute ?? false) {
                    if (((this.itemModel?.length ?? 0) > 0) && ((this.itemModel?.width ?? 0) > 0) && ((this.itemModel?.height ?? 0) > 0)) {
                        return this.$filters.convertVolume(200, this.$props.dimensionUnit, (this.itemModel?.length ?? 0), (this.itemModel?.width ?? 0), (this.itemModel?.height ?? 0));
                    }
                }
                return (this.itemModel?.volume ?? 0);
            },
            packageUniqueCode() {
                return (this.source?.data ?? []).map(x => ({ id: x.id, temporaryID: x.temporaryID, code: x.code }));
            },
            packageItemUniqueCode() {
                return (this.packageModel?.items ?? []).map(x => ({ id: x.id, temporaryID: x.temporaryID, code: x.code }));
            },
            packageGridSource() {
                //return {
                //    type: "array",
                //    source: this.$props.packageSource ?? []
                //};
                if (!this.isInitSource) {
                    return {
                        type:"array",
                        source:
                            ((this.source?.data?.length ?? 0) > 0 ? this.source?.data : (this.$props.packageSource ?? []))
                    };
                    //return {
                    //    type: ((this.$props.parentID ?? 0) > 0) ? "url" : "array",
                    //    source: ((this.$props.parentID ?? 0) > 0) ? ("/services/tms/package/consignment/id/" + (this.$props.parentID ?? 0) + "/") : ((this.source?.data?.length ?? 0) > 0 ? this.source?.data : (this.$props.packageSource ?? []))
                    //};
                }
                else {
                    return {
                        type: "array",
                        source: this.$props.packageSource ?? []
                    };
                }
                
            },
            packageRecord() { return this.$store.getters["package/record"]; },
            packageErrors() { return this.$store.getters["package/errors"]; },
            packageItemRecord() { return this.$store.getters["packageitem/record"]; },
            packageItemErrors() { return this.$store.getters["packageitem/errors"]; },
            hazMatDS() { return this.$store.getters["dangerousgood/ds"]; },
            OriginISO2() {
                return this.$vm.$countries.filter(x => x.id === this.$props.formConsignment?.shipFrom_CountryID)[0]?.code;
            },
            DestinationISO2() {
                return this.$vm.$countries.filter(x => x.id === this.$props.formConsignment?.shipTo_CountryID)[0]?.code;
            },
            isDomestic() {
                return this.$filters.isDomestic(this.OriginISO2, this.$props.formConsignment?.shipFrom_PostalCode ?? '', this.DestinationISO2, this.$props.formConsignment?.shipTo_PostalCode ?? '');
            },
        },
        methods: {
              async updateRecord(modulename, model) { return await this.$store.dispatch(modulename + "/modifyRecord", model); }
            , async cloneRecord(modulename, mask, model) { return await this.$store.dispatch(modulename + "/cloneRecord", {mask : mask, record : model}); }
            , async insertRecord(modulename, model) { return await this.$store.dispatch(modulename + "/addRecord", model); }
            , async deleteRecord(modulename, mask) { return await this.$store.dispatch(modulename + "/deleteRecordByMask", mask); }
            , async getEntityRecord(modulename, mask) { return await this.$store.dispatch(modulename + "/getRecord", mask); }
            , async getSource() {
                if ((this.$props.parentID ?? 0) > 0) {
                    await this.$axios.get(this.$config.config.endpoint.api + `/services/tms/package/consignment/id/${(this.$props.parentID ?? 0)}`.replaceAll("//", "/")).then((result) => {
                        this.source.data = result?.data?.result ?? [];
                        this.source.total = (this.source.data?.length ?? 0);
                        this.$emit("is-source", this.source);
                    });
                }                
            }
            , async generateCartonNumber() {
                let cartonNumber = this.source.data?.length ?? 0;
                let cartonUnique = false;
                let terminateLoop = 100;
                /*let i = 0;*/
                while (!cartonUnique) {
                    cartonNumber++;
                    if (this.source.data === undefined || this.source.data == null)
                        this.source.data === [];
                    let idx = (this.source.data?.length ?? 0 > 0) ? this.source.data.findIndex(x => x.code.toLowerCase() === cartonNumber.toString().toLowerCase()) : -1;
                    if (idx === -1) {
                        cartonUnique = true;
                    }
                    else {
                        cartonNumber++;
                    }
                    terminateLoop++;
                    if (terminateLoop > 100)
                        break;
                }
                this.packageModel.code = cartonNumber.toString();
                this.cloneModel.code = cartonNumber.toString();
            }
            , async getHazMat() {
                return await this.$store.dispatch("dangerousgood/getAll", {sort: null, where: null, fields: null});
            }
            , onComputePackageWeight() {
                let totalItemWeight = 0;
                (this.packageModel.items ?? []).forEach(x => {
                    totalItemWeight += (x.weight * x.quantity);
                });
                if ((totalItemWeight ?? 0) > (this.packageModel.weight ?? 0))
                    this.packageModel.weight = totalItemWeight;
            }
            , onDeclaredValuePackageWeight() {
                let totalDeclareValue = 0;
                (this.packageModel.items ?? []).forEach(x => {
                    totalDeclareValue += (x.consignmentValue * x.quantity);
                });
                if ((totalDeclareValue ?? 0) > (this.packageModel.consignmentValue ?? 0))
                    this.packageModel.consignmentValue = totalDeclareValue;
            }
            , onPackageRefresh() {
               this.forceRefresh.package = true;
            }
            , onPackageSource(e) {
                
                this.$nextTick(() => {
                    if (((this.source?.data?.length ?? 0) === 0) && (e.total > 0)) {
                        this.source.data = e.data;
                        this.source.total = (this.source.data?.length ?? 0);
                        this.$emit("is-source", e);
                    }
                });
                if (this.forceRefresh.package) {
                    this.$emit("is-source", e);
                    
                }
                
                this.$nextTick(() => {
                    this.forceRefresh.package = false;
                });
                
            }
            , onPackageCreate() {
                this.dialog.header = "Add Package";
                this.packageModel = {
                    id: Number(Math.random().toString().replace(".", "")),
                    temporaryID: Number(Math.random().toString().replace(".", "")),
                    consignmentID: (this.$props?.parentID ?? 0) > 0 ? this.$props?.parentID : 0,
                    carrierReference: null,
                    cpcNumber: null,
                    ssccNumber: null,
                    consignmentValue: this.defaultDeclaredValue ?? 0,
                    insuranceValue: 0,
                    dutyValue: 0.01,
                    taxValue: 0.01,
                    customsValue: 0,
                    discountValue: 0,
                    weight: 0,
                    netWeight: 0,
                    chargeableWeight: 0,
                    volumeAutoCompute: true,
                    length: 0,
                    width: 0,
                    height: 0,
                    additionalHandling: null,
                    isFragile: false,
                    hasLiquid: false,
                    isPallet: false,
                    useTailLift: false,
                    lastScan_Date: null,
                    lastScan_Location: null,
                    lastScan_Activity: null,
                    environmentID: null,
                    code: null,
                    name: this.defaultPackageContent ?? null,
                    mask: null,
                    items: []
                };
                this.generateCartonNumber();
                this.sourceItem.data = [],
                this.sourceItem.total = 0;
                this.dialog.delete = true;
                this.dialog.add = true;
                this.onRowEdit();
            }
            , onPackageEdit(e) {
                this.dialog.header = "Edit Package";
                this.packageModel = this.$filters.init(e);
                this.sourceItem.data = this.packageModel.items;
                this.sourceItem.total = this.sourceItem.data.length ?? 0;
                this.dialog.delete = true;
                this.dialog.edit = true;
                this.onRowEdit();
            }
            , onPackageDelete(data) {
                this.packageModel = this.$filters.init(data);
                this.dialog.delete = true;
                
                this.forDeletePackage = this.packageModel;
                this.forDeletePackage.Reference = this.packageModel.code;
                this.forDeletePackage.Content = this.packageModel.name;

            }
            , onManagePackage(e) {
                var location = this.$filters.init(e);
                var index = -1;
                if (location.id > 0)
                    index = this.source.data.findIndex(x => x.id === location.id);
                else
                    index = this.source.data.findIndex(x => x.temporaryID === location.temporaryID);

                if (index === -1) {
                    this.source.data.push(this.$filters.init(e));
                    this.source.total = this.source.data.length;
                    if ((this.$props?.parentID ?? 0) > 0) {
                        if (this.cloneModel.source) {
                            this.cloneRecord("package", this.cloneModel.source.mask, { code: this.packageModel.code, name: this.packageModel.name }).then((response) => {
                                if (response.errors)
                                    this.$toast.add({ severity: 'error', summary: 'Record Clone Failed!', detail: response.errors[0].message, life: 3000 });
                                else {
                                    index = this.source.data.findIndex(x => x.temporaryID === location.temporaryID);
                                    this.getEntityRecord("package", this.packageRecord.mask).then(() => {
                                        this.source.data[index] = this.$filters.init(this.packageRecord);
                                        this.source.total = this.source.data.length;
                                    });
                                }
                            }).catch((err) => {
                                this.$toast.add({ severity: 'error', summary: 'Record Clone Failed!', detail: err.xhr, life: 3000 });
                            }).finally(() => {
                                this.onPackageRefresh();
                                this.$emit("is-source", this.source);
                            });
                        }
                        else {
                            this.insertRecord("package", this.packageModel).then(() => {
                                if (this.packageErrors)
                                    this.$toast.add({ severity: 'error', summary: 'Record Insert Failed!', detail: this.packageErrors[0].message, life: 3000 });
                                else {
                                    index = this.source.data.findIndex(x => x.temporaryID === location.temporaryID);
                                    this.getEntityRecord("package", this.packageRecord.mask).then(() => {
                                        this.source.data[index] = this.$filters.init(this.packageRecord);
                                        this.source.total = this.source.data.length;
                                    });
                                    // this.$toast.add({ severity: 'success', summary: 'Record Insert Success!', detail: 'The record was added successfully.', life: 3000 });
                                }

                            }).catch((err) => {
                                this.$toast.add({ severity: 'error', summary: 'Record Insert Failed!', detail: err.xhr, life: 3000 });
                            }).finally(() => {
                                this.onPackageRefresh();
                                this.$emit("is-source", this.source);
                            });

                        }
                        
                    }
                    else {
                        this.onPackageRefresh();
                    }
                    
                }
                else {
                    if (this.dialog.edit || this.dialog.add) {
                        this.source.data[index] = this.$filters.init(e);
                        if (location.mask !== null && location.mask !== "") {
                            this.updateRecord("package", this.packageModel).then(() => {
                                if (this.errors)
                                    this.$toast.add({ severity: 'error', summary: 'Record Update Failed!', detail: this.errors[0].message, life: 3000 });
                                //else {
                                //    this.$toast.add({ severity: 'success', summary: 'Record Update Success!', detail: 'The record was updated successfully.', life: 3000 });

                                //}
                                
                            }).catch((err) => {
                                this.$toast.add({ severity: 'error', summary: 'Record Update Failed!', detail: err.xhr, life: 3000 });
                            }).finally(() => {
                                this.onPackageRefresh();
                            });
                        }
                    }
                    else {
                        this.source.data.splice(index, 1);
                        if (location.mask !== null && location.mask !== "") {
                            this.deleteRecord("package", location.mask).then(() => {
                                if (this.errors)
                                    this.$toast.add({ severity: 'error', summary: 'Record Delete Failed!', detail: this.errors[0].message, life: 3000 });
                            }).catch((err) => {
                                this.$toast.add({ severity: 'error', summary: 'Record Delete Failed!', detail: err.xhr, life: 3000 });
                            }).finally(() => {
                                this.onPackageRefresh();
                            });
                        }
                    }
                }
                this.source.total = this.source.data.length;
                this.packageModel = {
                    id: 0,
                    temporaryID: null,
                    consignmentID: 0,
                    carrierReference: null,
                    cpcNumber: null,
                    ssccNumber: null,
                    consignmentValue: 0,
                    insuranceValue: 0,
                    dutyValue: 0,
                    taxValue: 0,
                    customsValue: 0,
                    discountValue: 0,
                    weight: 0,
                    netWeight: 0,
                    chargeableWeight: 0,
                    length: 0,
                    width: 0,
                    height: 0,
                    additionalHandling: null,
                    isFragile: false,
                    hasLiquid: false,
                    isPallet: false,
                    useTailLift: false,
                    lastScan_Date: null,
                    lastScan_Location: null,
                    lastScan_Activity: null,
                    environmentID: null,
                    code: null,
                    name: null,
                    mask: null,
                    items: []
                },
                this.dialog.delete = false;
                this.dialog.edit = false;
                this.dialog.add = false;
                this.dialog.header = "Delete Record?";
                if ((this.$props?.parentID ?? 0) === 0) {
                    this.onPackageRefresh();
                    //this.$emit("is-source",  this.source );
                    //this.$emit("is-sync", { packages: this.source });
                }
                /*this.$emit("is-sync", { model: this.source });*/
            }
            , onItemCreate() {
                this.itemModel = {
                    id: 0,
                    packageID: this.packageModel.mask !== null ? this.packageModel.id : this.packageModel.temporaryID,
                    temporaryID: Number(Math.random().toString().replace(".", "")),
                    productID: null,
                    barcodeID: null,
                    htsCode: null,
                    gtin: null,
                    sku: null,
                    attribute: null,
                    quantity: 1,
                    volumeAutoCompute: this.packageModel?.volumeAutoCompute ?? true,
                    weight: 0,
                    length: 0,
                    width: 0,
                    height: 0,
                    consignmentValue: 0,
                    insuranceValue: 0,
                    customsValue: 0,
                    discountValue: 0,
                    taxRate: 0,
                    dutyValue: 0.01,
                    taxValue: 0.01,
                    manufacturerCountryID: null,
                    dG_HazmatID: null,
                    dG_DryIceNetWeight: 0,
                    dG_DryIceUnitWeight: 1,
                    dG_RegulatedCode: this.defaultRegulatedCode,
                    environmentID: null,
                    code: null,
                    name: null,
                    mask: null
                },
                this.itemDialog.delete = true;
                this.itemDialog.add = true;
                this.v$.$touch();
                this.onRowEdit();
            }
            , onItemEdit(e) {
                this.itemModel = this.$filters.init(e);
                this.itemDialog.delete = true;
                this.itemDialog.edit = true;
                this.onRowEdit();
            }
            , onItemDelete(data) {
                this.itemModel = this.$filters.init(data);
                this.itemDialog.delete = true;
                //["productCode","description","attribute","sku","gtin","hscode"],
                this.forDeleteItem = this.itemModel;
                this.forDeleteItem.productCode = this.itemModel.code;
                this.forDeleteItem.description = this.itemModel.name;
                this.forDeleteItem.attribute = this.itemModel.attribute;
                this.forDeleteItem.SKU = this.itemModel.sku;
                this.forDeleteItem.GTIN = this.itemModel.gtin;
                this.forDeleteItem.HSCode = this.itemModel.htsCode;
                

            }
            , onManageItem(e) {
                var location = this.$filters.init(e);
                var index = -1;
                try {
                    if (location.id > 0)
                        index = this.sourceItem.data.findIndex(x => x.id === location.id);
                    else
                        index = this.sourceItem.data.findIndex(x => x.temporaryID === location.temporaryID);
                }
                catch { this.sourceItem.data = [] }

                if (index === -1) {
                    this.sourceItem.data.push(this.$filters.init(e));
                    if ((this.$props?.parentID ?? 0) > 0) {
                        if (this.packageModel.mask) {
                            this.insertRecord("packageitem", this.itemModel).then(() => {
                                if (this.errors) {
                                    index = this.sourceItem.data.findIndex(x => x.temporaryID === location.temporaryID);
                                    if (index !== -1)
                                        this.sourceItem.data.splice(index, 1);
                                    this.$toast.add({ severity: 'error', summary: 'Record Insert Failed!', detail: this.errors[0].message, life: 3000 });
                                }
                                else {
                                    index = this.sourceItem.data.findIndex(x => x.temporaryID === location.temporaryID);
                                    this.sourceItem.data[index] = this.$filters.init(this.packageItemRecord);
                                    this.sourceItem.total = this.sourceItem.data.length;
                                    this.packageModel.items = this.sourceItem.data;
                                    // this.$toast.add({ severity: 'success', summary: 'Record Insert Success!', detail: 'The record was added successfully.', life: 3000 });
                                }
                            }).catch((err) => {
                                this.$toast.add({ severity: 'error', summary: 'Record Insert Failed!', detail: err.xhr, life: 3000 });
                                index = this.sourceItem.data.findIndex(x => x.temporaryID === location.temporaryID);
                                if (index !== -1)
                                    this.sourceItem.data.splice(index, 1);
                            });
                        }

                    }
                }
                else {
                    if (this.itemDialog.edit || this.itemDialog.add) {
                        this.sourceItem.data[index] = this.$filters.init(e);
                        if (location.mask !== null && location.mask !== "") {
                            this.updateRecord("packageitem", this.itemModel).then(() => {
                                if (this.packageItemErrors)
                                    this.$toast.add({ severity: 'error', summary: 'Record Update Failed!', detail: this.packageItemErrors[0].message, life: 3000 });
                                //else {
                                //    this.$toast.add({ severity: 'success', summary: 'Record Update Success!', detail: 'The record was updated successfully.', life: 3000 });
                                //}
                            }).catch((err) => {
                                this.$toast.add({ severity: 'error', summary: 'Record Update Failed!', detail: err.xhr, life: 3000 });
                            });
                        }
                    }
                    else {
                        this.sourceItem.data.splice(index, 1);
                        if (location.mask !== null && location.mask !== "") {
                            this.deleteRecord("packageitem", location.mask).then(() => {
                                if (this.packageItemErrors)
                                    this.$toast.add({ severity: 'error', summary: 'Record Delete Failed!', detail: this.packageItemErrors[0].message, life: 3000 });
                                //else {
                                //    this.$toast.add({ severity: 'success', summary: 'Record Delete Success!', detail: 'The record was deleted successfully.', life: 3000 });

                                //}
                            }).catch((err) => {
                                this.$toast.add({ severity: 'error', summary: 'Record Delete Failed!', detail: err.xhr, life: 3000 });
                            });
                        }
                    }
                }
                this.sourceItem.total = this.sourceItem.data.length;
                this.packageModel.items = this.sourceItem.data;
                this.onComputePackageWeight();
                this.onDeclaredValuePackageWeight();
                this.itemModel = {
                    id: 0,
                    packageID: 0,
                    temporaryID: null,
                    productID: null,
                    barcodeID: null,
                    htsCode: null,
                    gtin: null,
                    sku: null,
                    attribute: null,
                    quantity: 0,
                    weight: 0,
                    length: 0,
                    width: 0,
                    height: 0,
                    consignmentValue: 0,
                    insuranceValue: 0,
                    customsValue: 0,
                    discountValue: 0,
                    taxRate: 0,
                    taxValue: 0,
                    manufacturerCountryID: null,
                    dG_HazmatID: null,
                    dG_DryIceNetWeight: 0,
                    dG_DryIceUnitWeight: 1,
                    environmentID: null,
                    code: null,
                    name: null,
                    mask: null

                },
                this.itemDialog.delete = false;
                this.itemDialog.edit = false;
                this.itemDialog.add = false;
            }
            , onImageUpload(event) {
                this.itemModel.image = event;
            }
            , onClone(data) {
                this.cloneModel.source = this.$filters.init(data);
                this.generateCartonNumber();
                this.dialog.clone = true;
            }
            ,onClone_KeyupEvent(e) {
                if (e.keyCode === 13 && (this.cloneModel.code?.length ?? 0) > 0) {
                    this.manageClone();
                }
                if (e.keyCode === 27) {
                    
                        this.dialog.clone = false;
                        this.cloneModel = { source: null, code: null, iteration: 1}; 
                }
            }
            , async manageClone() {
                for (let i = 0; i < this.cloneModel.iteration; i++) {
                    let clonePackage = this.$filters.init(this.cloneModel.source);
                    clonePackage.id = Number(Math.random().toString().replace(".", ""));
                    clonePackage.mask = null;
                    clonePackage.temporaryID = Number(Math.random().toString().replace(".", ""));
                    clonePackage.code = this.cloneModel.code;
                    this.packageModel = clonePackage;
                    this.sourceItem.data = [];
                    this.sourceItem.total = [];
                    for (let c = 0; c < this.cloneModel.source.items.length; c++) {
                        let cloneItem = this.$filters.init(this.cloneModel.source.items[c]);
                        cloneItem.id = 0;
                        cloneItem.temporaryID = Number(Math.random().toString().replace(".", ""));
                        cloneItem.packageID = clonePackage.mask !== null ? clonePackage.id : clonePackage.temporaryID;
                        this.onManageItem(this.$filters.init({
                            id: 0,
                            packageID: cloneItem.packageID,
                            temporaryID: cloneItem.temporaryID,
                            productID: cloneItem.productID,
                            barcodeID: cloneItem.barcodeID,
                            htsCode: cloneItem.htsCode,
                            gtin: cloneItem.gtin,
                            sku: cloneItem.sku,
                            attribute: cloneItem.attribute,
                            quantity: cloneItem.quantity,
                            weight: cloneItem.weight,
                            length: cloneItem.length,
                            width: cloneItem.width,
                            height: cloneItem.height,
                            consignmentValue: cloneItem.consignmentValue,
                            insuranceValue: cloneItem.insuranceValue,
                            customsValue: cloneItem.customsValue,
                            discountValue: cloneItem.discountValue,
                            taxRate: cloneItem.taxRate,
                            taxValue: cloneItem.taxValue,
                            manufacturerCountryID: cloneItem.manufacturerCountryID,
                            dG_HazmatID: cloneItem.dG_HazmatID,
                            dG_DryIceNetWeight: cloneItem.dG_DryIceNetWeight,
                            dG_DryIceUnitWeight: cloneItem.dG_DryIceUnitWeight,
                            environmentID: cloneItem.environmentID,
                            code: cloneItem.code,
                            name: cloneItem.name,
                            mask: cloneItem.mask,
                            image: this.$filters.init(cloneItem.image)
                        }));   
                    }
                    this.onManagePackage(this.packageModel);
                    this.generateCartonNumber();
                }
                this.dialog.clone = false;
                this.cloneModel = {
                    source: null,
                    code: null,
                    iteration: 1
                };
            }
            , onRowEdit() {
                //setTimeout(() => {
                //    const kbButtons = document.querySelectorAll(".p-button.p-component");
                //        kbButtons.forEach(elem => {
                //            elem.setAttribute('tabindex', '-1')
                //        });
                //}, 100);
            }
        },
        created() {
            this.$nextTick(() => {
                this.source.data = [];
                this.source.total = (this.source.data?.length ?? 0);
                this.getHazMat();
            });
        },
        mounted() {
            //this.model = this.$props.formData;
            this.$nextTick(() => {
                this.getSource();
                this.source.data = [];
                this.source.total = (this.source.data?.length ?? 0);
                this.getHazMat();
            });
            window.addEventListener('setItem', () => {
                this.keys = sessionStorage.getItem('activekeys');
            });
            this.keys = sessionStorage.getItem('activekeys');
            this.onRowEdit();
        },
        unmounted() {
            try {
                window.removeEventListener('setItem');
            } catch { /**/ }
            this.$nextTick(() => {
                this.source.data = [];
                this.source.total = 0;
            });
        }
    }
</script>
<style>
    .p-picklist-header {
        padding: 0 10px !important;
    }
</style>