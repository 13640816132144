import { mapGetters } from 'vuex';
var sessionModel = {
    keys: null,
    customerScopes: null,
    session: {
        tenant: null,
        environment: null,
        warehouse: null,
        customer: null,
        currentUser: null,
        executiveUser: null
    },
}

var crudModel = {
    code: null,
    name: null,
    status: 100,
    warehouseID: null,
    environmentID: null,
    retailerID: null
}

var model = {
    model: null,
    modelState: null

};

var addressesFormModel = {
    model: {
        ...crudModel,
        resetPackage: false,
        resetProvider: false,
        defaultPackageContent: null,
        customerID: null,
        customerCode: null,
        shipFrom_AddressBookID: null,
        shipFrom_FirstName: null,
        shipFrom_LastName: null,
        shipFrom_Company: null,
        shipFrom_CountryID: 0,
        shipFrom_Address1: null,
        shipFrom_Address2: null,
        shipFrom_Address3: null,
        shipFrom_City: null,
        shipFrom_State: null,
        shipFrom_PostalCode: null,
        shipFrom_EstablishmentType: 100,
        shipFrom_Telephone: null,
        shipFrom_Mobile: null,
        shipFrom_Email: null,
        shipFrom_TaxID: null,
        shipFrom_EORI: null,
        shipFrom_IOSSNumber: null,
        shipFrom_IDentityNumber: null,
        shipFrom_IDentityNumberType: 300,


        shipTo_AddressBookID: null,
        shipTo_FirstName: null,
        shipTo_LastName: null,
        shipTo_Company: null,
        shipTo_CountryID: 0,
        shipTo_Address1: null,
        shipTo_Address2: null,
        shipTo_Address3: null,
        shipTo_City: null,
        shipTo_State: null,
        shipTo_PostalCode: null,
        shipTo_EstablishmentType: 100,
        shipTo_Telephone: null,
        shipTo_Mobile: null,
        shipTo_Email: null,
        shipTo_TaxID: null,
        shipTo_EORI: null,
        shipTo_IOSSNumber: null,
        shipTo_IDentityNumber: null,
        shipTo_IDentityNumberType: 300,

        viewOnly: true
    },
    initContactLoad: false,
    
    initAddressLoad: false,
}

var collectionFormModel = {
    model: {
        ...crudModel,
        resetPackage: false,
        resetProvider: false,
        defaultPackageContent: null,
        customerID: null,
        customerCode:null,
        shipFrom_AddressBookID: null,
        shipFrom_FirstName: null,
        shipFrom_LastName: null,
        shipFrom_Company: null,
        shipFrom_CountryID: 0,
        shipFrom_Address1: null,
        shipFrom_Address2: null,
        shipFrom_Address3: null,
        shipFrom_City: null,
        shipFrom_State: null,
        shipFrom_PostalCode: null,
        shipFrom_EstablishmentType: 100,
        shipFrom_Telephone: null,
        shipFrom_Mobile: null,
        shipFrom_Email: null,
        shipFrom_TaxID: null,
        shipFrom_EORI: null,
        shipFrom_UKIMS: null,
        shipFrom_IOSSNumber: null,
        shipFrom_IDentityNumber: null,
        shipFrom_IDentityNumberType: 300,

        shipTo_AddressBookID: null,
        shipTo_FirstName: null,
        shipTo_LastName: null,
        shipTo_Company: null,
        shipTo_CountryID: 0,
        shipTo_Address1: null,
        shipTo_Address2: null,
        shipTo_Address3: null,
        shipTo_City: null,
        shipTo_State: null,
        shipTo_PostalCode: null,
        shipTo_EstablishmentType: 100,
        shipTo_Telephone: null,
        shipTo_Mobile: null,
        shipTo_Email: null,
        shipTo_TaxID: null,
        shipTo_EORI: null,
        shipTo_IOSSNumber: null,
        shipTo_IDentityNumber: null,
        shipTo_IDentityNumberType: 300,
        viewOnly: true
    },
    initContactLoad: false,
    initAddressLoad: false,
   
}
var recipientFormModel = {
    model: {
        ...crudModel,
        shipTo_AddressBookID: null,
        shipTo_FirstName: null,
        shipTo_LastName: null,
        shipTo_Company: null,
        shipTo_CountryID: 0,
        shipTo_Address1: null,
        shipTo_Address2: null,
        shipTo_Address3: null,
        shipTo_City: null,
        shipTo_State: null,
        shipTo_PostalCode: null,
        shipTo_EstablishmentType: 100,
        shipTo_Telephone: null,
        shipTo_Mobile: null,
        shipTo_Email: null,
        shipTo_TaxID: null,
        shipTo_EORI: null,
        shipTo_UKIMS: null,
        shipTo_IOSSNumber: null,
        shipTo_IDentityNumber: null,
        shipTo_IDentityNumberType: 300,
    }
}

var orderpackageFormModel = {
    model: {
        ...crudModel,
        customerReference: null,
        customerReference2: null,
        customerReference3: null,
        customerReference4: null,
        customerReference5: null,
        customerReference6: null,
        customerReference7: null,
        tradeType: 1,
        pickupType: 100,
        packageType: 4,
        paymentMode: 1,
        currencyID: 3,
        weightUnit: 100,
        dimensionUnit: 100,
        isHazardous: false,
        dG_EmergencyContact: null,
        dG_EmergencyTelephone: null,
        isInsured: false,
        isReturnable: false,
        pushReturns: false,
        isPaperlessTrade: false,
        notification: 0,
        safePlace: null,
        deliveryInstruction: null,
        packages: []
        
    },
    source: {
        total: 0,
        data: []
    }
}

var internationalformsFormModel = {
    model: {
        ...crudModel,
        shipmentType: 8,
        customsCategoryType: 2,
        exportReason: 1,
        incoterm: 64,
        packageContent: null,
        invoiceNumber: null,
        invoiceDate: new Date(),
        invoiceDateValue: new Date(),
        invoiceNote: null,
        cpcNumber: null,
        shippingCost: 0
    },
    initContactLoad: false,
    initAddressLoad: false
}

var allocateFormModel = {
    model: {
        ...crudModel,
        serviceProviderAccountID: null,
        serviceProviderProductAccountID: null,
        carrierCode: null,
        productCode: null,
        productName: null,
        serviceGroupID: null,
        serviceGroupCode: null,
        serviceGroupText: null,
        accessPointLocationCode: null,
        accessPointLocationName: null,
        accessPointLocationAddress: null,
        parcelReleaseCode: null,
        returnProductCode: null,
        returnProductName: null,
        pickupType: 100,
        request_CollectionDate: new Date(),
        request_CollectionDateValue: new Date(),
        request_ReadyTime: new Date(),
        request_ReadyTimeValue: new Date(),
        request_ClosingTime: new Date(),
        request_ClosingTimeValue: new Date()
    },
    formModel: null,
    productservice: [{
        ProviderCode: null,
        ProviderName: null,
        ProductCode: null,
        ProductName: null,
        Disclaimer: null,
        Quote: {
            Description: null,
            CurrencyCode: null,
            Value: 0.00,
            ItemizedCharges: [
                {
                    Description: null,
                    CurrencyCode: null,
                    Value: 0.00
                },
            ]
        },
        Schedule: {
            CollectionSchedule: {
                CollectionDate: null,
                CollectionTime: null,
                CutOffTime: null
            },
            EstimatedDelivery: {
                CollectionDate: null,
                CollectionTime: null
            },
            TimeInTransit: 1
        }
    }, {
        ProviderCode: null,
        ProviderName: null,
        ProductCode: null,
        ProductName: null,
        Disclaimer: null,
        Quote: {
            Description: null,
            CurrencyCode: null,
            Value: 0.00,
            ItemizedCharges: [
                {
                    Description: null,
                    CurrencyCode: null,
                    Value: 0.00
                },
            ]
        },
        Schedule: {
            CollectionSchedule: {
                CollectionDate: null,
                CollectionTime: null,
                CutOffTime: null
            },
            EstimatedDelivery: {
                CollectionDate: null,
                CollectionTime: null
            },
            TimeInTransit: 1
        }
    }, {
        ProviderCode: null,
        ProviderName: null,
        ProductCode: null,
        ProductName: null,
        Disclaimer: null,
        Quote: {
            Description: null,
            CurrencyCode: null,
            Value: 0.00,
            ItemizedCharges: [
                {
                    Description: null,
                    CurrencyCode: null,
                    Value: 0.00
                },
            ]
        },
        Schedule: {
            CollectionSchedule: {
                CollectionDate: null,
                CollectionTime: null,
                CutOffTime: null
            },
            EstimatedDelivery: {
                CollectionDate: null,
                CollectionTime: null
            },
            TimeInTransit: 1
        }
    }
    ],
    serviceGroupSource: [],
    accesspointLocation: [],
    returnservice: [],
    activity: {
        serviceloading: true
        //,
        //loading: true
    }

}


var browser = {
    language() { return navigator.language; },
    touchDevice() { return navigator.maxTouchPoints > 0; },
    isMobile() { return /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|ipad|iris|kindle|Android|Silk|lge |maemo|midp|mmp|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino/i.test(navigator.userAgent) || /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw-(n|u)|c55\/|capi|ccwa|cdm-|cell|chtm|cldc|cmd-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc-s|devi|dica|dmob|do(c|p)o|ds(12|-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(-|_)|g1 u|g560|gene|gf-5|g-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd-(m|p|t)|hei-|hi(pt|ta)|hp( i|ip)|hs-c|ht(c(-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i-(20|go|ma)|i230|iac( |-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|-[a-w])|libw|lynx|m1-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|-([1-8]|c))|phil|pire|pl(ay|uc)|pn-2|po(ck|rt|se)|prox|psio|pt-g|qa-a|qc(07|12|21|32|60|-[2-7]|i-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h-|oo|p-)|sdk\/|se(c(-|0|1)|47|mc|nd|ri)|sgh-|shar|sie(-|m)|sk-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h-|v-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl-|tdg-|tel(i|m)|tim-|t-mo|to(pl|sh)|ts(70|m-|m3|m5)|tx-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas-|your|zeto|zte-/i.test(navigator.userAgent.substr(0, 4)) },
};

//var data = {
//    activity: {
//        loading: {
//            form: true,
//            save: false,
//            delete: false,
//            print: false,
//            send: false,
//            clone: false,
//            faq: false,
//            support: false
//        }
//    },
//    display: { width: 1000, height: 1000 },
//    router: {
//        name: null,
//        module: null,
//        model: null,
//        schema: null,
//        title: null,
//        subtitle: null,
//        type: null
//    }
//};


var props = {
    parentID: { type: Number, default: -1 },
    customerID: { type: Number, default: -1 },
    formData: { type: Object },
    recordformData: { type: Object }  
}

var computed = {
    ...browser,
    ...mapGetters({
        user: "auth/currentUser"
    }),
    sessionKeys() { return this.$store.getters["auth/session"]; },
    buttonTicker() { return (this.$props?.recordformData?.nextButtonTicker ?? 0) },
    customerSelectedID() {
        return this.model.customerID ?? this.scopeCustomerDS[0]?.id;
    },
    collectionContact() {

        return {
            firstName: this.model?.shipFrom_FirstName,
            lastName: this.model?.shipFrom_LastName,
            email: this.model?.shipFrom_Email,
            mobile: this.model?.shipFrom_Mobile,
            telephone: this.model?.shipFrom_Telephone,
            eori: this.model?.shipFrom_EORI,
            ukims: this.model?.shipFrom_UKIMS,
            ioss: this.model?.shipFrom_IOSSNumber,
            vat: this.model?.shipFrom_TaxID,
            idNumber: this.model?.shipFrom_IDentityNumber,
            idType: this.model?.shipFrom_IDentityNumberType ?? 300
        };

        //try {
        //    if ((this.model.id === null || this.model.id === undefined || this.model.id === 0) && (this.model?.shipFrom_FirstName ?? "") == "" && this.initContactLoad && this.tradeTypeSelectedID == 1) {
        //        return {
        //            firstName: this.companyProfileRecord?.firstName,
        //            lastName: this.companyProfileRecord?.lastName,
        //            email: this.companyProfileRecord?.email,
        //            mobile: this.companyProfileRecord?.mobile,
        //            telephone: this.companyProfileRecord?.telephone,
        //            eori: this.companyProfileRecord?.eori,
        //            ioss: this.companyProfileRecord?.iossNumber,
        //            vat: this.companyProfileRecord?.vatNo,
        //            idNumber: this.companyProfileRecord?.identityNumber,
        //            idType: this.companyProfileRecord?.identityNumberType ?? 300,
        //        };
        //    }
        //    else if ((this.model.id === null || this.model.id === undefined || this.model.id === 0) && (this.model?.shipFrom_FirstName ?? "") == "" && this.initContactLoad && this.tradeTypeSelectedID == 2) {
        //        return {
        //            firstName: null,
        //            lastName: null,
        //            email: null,
        //            mobile: null,
        //            telephone: null,
        //            eori: null,
        //            ioss: null,
        //            vat: null,
        //            idNumber: null,
        //            idType: this.model?.shipTo_IDentityNumberType ?? 300
        //        };
        //    }
        //    else {
        //        return {
        //            firstName: this.model?.shipFrom_FirstName,
        //            lastName: this.model?.shipFrom_LastName,
        //            email: this.model?.shipFrom_Email,
        //            mobile: this.model?.shipFrom_Mobile,
        //            telephone: this.model?.shipFrom_Telephone,
        //            eori: this.model?.shipFrom_EORI,
        //            ioss: this.model?.shipFrom_IOSSNumber,
        //            vat: this.model?.shipFrom_TaxID,
        //            idNumber: this.model?.shipFrom_IDentityNumber,
        //            idType: this.model?.shipFrom_IDentityNumberType ?? 300
        //        };
        //    }
        //}
        //catch {
        //    return {
        //        firstName: this.model?.shipFrom_FirstName,
        //        lastName: this.model?.shipFrom_LastName,
        //        email: this.model?.shipFrom_Email,
        //        mobile: this.model?.shipFrom_Mobile,
        //        telephone: this.model?.shipFrom_Telephone,
        //        eori: this.model?.shipFrom_EORI,
        //        ioss: this.model?.shipFrom_IOSSNumber,
        //        vat: this.model?.shipFrom_TaxID,
        //        idNumber: this.model?.shipFrom_IDentityNumber,
        //        idType: this.model?.shipFrom_IDentityNumberType ?? 300
        //    };
        //}

    },
    collectionAddress() {
        return {
            address1: this.model?.shipFrom_Address1,
            address2: this.model?.shipFrom_Address2,
            address3: this.model?.shipFrom_Address3,
            city: this.model?.shipFrom_City,
            state: this.model?.shipFrom_State,
            postalCode: this.model?.shipFrom_PostalCode,
            countryID: this.model?.shipFrom_CountryID,
            companyName: this.model?.shipFrom_Company,
            establishmentType: this.model?.shipFrom_EstablimentType ?? 100,
            atRisk: this.model?.shipFrom_AtRisk
        };
        //try {
        //    if ((this.model.id === null || this.model.id === undefined || this.model.id === 0) && ((this.model?.countryID ?? 0) === 0 && (this.model?.postalCode ?? "") === "") && !this.initAddressLoad && this.tradeTypeSelectedID == 1) {
        //        return {
        //            address1: this.warehouse?.address1,
        //            address2: this.warehouse?.address2,
        //            address3: this.warehouse?.address3,
        //            city: this.warehouse?.city,
        //            state: this.warehouse?.state,
        //            postalCode: this.warehouse?.postalCode,
        //            countryID: this.warehouse?.countryID,
        //            companyName: this.model?.shipFrom_Company,
        //            establishmentType: 100
        //        };
        //    }
        //    else {
        //        return {
        //            address1: this.model?.shipFrom_Address1,
        //            address2: this.model?.shipFrom_Address2,
        //            address3: this.model?.shipFrom_Address3,
        //            city: this.model?.shipFrom_City,
        //            state: this.model?.shipFrom_State,
        //            postalCode: this.model?.shipFrom_PostalCode,
        //            countryID: this.model?.shipFrom_CountryID,
        //            companyName: this.model?.shipFrom_Company,
        //            establishmentType: this.model?.shipFrom_EstablimentType ?? 100
        //        };
        //    }
        //}
        //catch {
        //    return {
        //        address1: this.model?.shipFrom_Address1,
        //        address2: this.model?.shipFrom_Address2,
        //        address3: this.model?.shipFrom_Address3,
        //        city: this.model?.shipFrom_City,
        //        state: this.model?.shipFrom_State,
        //        postalCode: this.model?.shipFrom_PostalCode,
        //        countryID: this.model?.shipFrom_CountryID,
        //        companyName: this.model?.shipFrom_Company,
        //        establishmentType: this.model?.shipFrom_EstablimentType ?? 100
        //    };
        //}
        
    },
    disablecollectionAddress() {
        return (this.tradeTypeSelectedID ?? 0) == 1;
    },
    deliveryContact() {
        return {
            firstName: this.model?.shipTo_FirstName,
            lastName: this.model?.shipTo_LastName,
            email: this.model?.shipTo_Email,
            mobile: this.model?.shipTo_Mobile,
            telephone: this.model?.shipTo_Telephone,
            eori: this.model?.shipTo_EORI,
            ioss: this.model?.shipTo_IOSSNumber,
            ukims: this.model?.shipTo_UKIMS,
            vat: this.model?.shipTo_TaxID,
            idNumber: this.model?.shipTo_IDentityNumber,
            idType: this.model?.shipTo_IDentityNumberType ?? 300
        };
        //try {
        //    if ((this.model.id === null || this.model.id === undefined || this.model.id === 0) && (this.model?.shipTo_FirstName ?? "") == "" && this.initContactLoad && this.tradeTypeSelectedID == 2) {
        //        return {
        //            firstName: this.companyProfileRecord?.firstName,
        //            lastName: this.companyProfileRecord?.lastName,
        //            email: this.companyProfileRecord?.email,
        //            mobile: this.companyProfileRecord?.mobile,
        //            telephone: this.companyProfileRecord?.telephone,
        //            eori: this.companyProfileRecord?.eori,
        //            ioss: this.companyProfileRecord?.iossNumber,
        //            vat: this.companyProfileRecord?.vatNo,
        //            idNumber: this.companyProfileRecord?.identityNumber,
        //            idType: this.companyProfileRecord?.identityNumberType ?? 300,
        //        };
        //    }
        //    else if ((this.model.id === null || this.model.id === undefined || this.model.id === 0) && (this.model?.shipTo_FirstName ?? "") == "" && this.initContactLoad && this.tradeTypeSelectedID == 1) {
        //        return {
        //            firstName: null,
        //            lastName: null,
        //            email: null,
        //            mobile: null,
        //            telephone: null,
        //            eori: null,
        //            ioss: null,
        //            vat: null,
        //            idNumber: null,
        //            idType: this.model?.shipTo_IDentityNumberType ?? 300
        //        };
        //    }
        //    else {

        //        return {
        //            firstName: this.model?.shipTo_FirstName,
        //            lastName: this.model?.shipTo_LastName,
        //            email: this.model?.shipTo_Email,
        //            mobile: this.model?.shipTo_Mobile,
        //            telephone: this.model?.shipTo_Telephone,
        //            eori: this.model?.shipTo_EORI,
        //            ioss: this.model?.shipTo_IOSSNumber,
        //            vat: this.model?.shipTo_TaxID,
        //            idNumber: this.model?.shipTo_IDentityNumber,
        //            idType: this.model?.shipTo_IDentityNumberType ?? 300
        //        };
        //    }
        //}
        //catch {
        //    return {
        //        firstName: this.model?.shipTo_FirstName,
        //        lastName: this.model?.shipTo_LastName,
        //        email: this.model?.shipTo_Email,
        //        mobile: this.model?.shipTo_Mobile,
        //        telephone: this.model?.shipTo_Telephone,
        //        eori: this.model?.shipTo_EORI,
        //        ioss: this.model?.shipTo_IOSSNumber,
        //        vat: this.model?.shipTo_TaxID,
        //        idNumber: this.model?.shipTo_IDentityNumber,
        //        idType: this.model?.shipTo_IDentityNumberType ?? 300
        //    };
        //}
       
    },
    deliveryAddress() {

        return {
            address1: this.model?.shipTo_Address1,
            address2: this.model?.shipTo_Address2,
            address3: this.model?.shipTo_Address3,
            city: this.model?.shipTo_City,
            state: this.model?.shipTo_State,
            postalCode: this.model?.shipTo_PostalCode,
            countryID: this.model?.shipTo_CountryID,
            companyName: this.model?.shipTo_Company,
            establishmentType: this.model?.shipTo_EstablimentType ?? 100,
            atRisk: this.model?.shipTo_AtRisk
        }; 

        //try {
        //    if ((this.model.id === null || this.model.id === undefined || this.model.id === 0) && ((this.model?.countryID ?? 0) === 0 && (this.model?.postalCode ?? "") === "") && !this.initAddressLoad && this.tradeTypeSelectedID == 2) {
        //        return {
        //            address1: this.warehouse?.address1,
        //            address2: this.warehouse?.address2,
        //            address3: this.warehouse?.address3,
        //            city: this.warehouse?.city,
        //            state: this.warehouse?.state,
        //            postalCode: this.warehouse?.postalCode,
        //            countryID: this.warehouse?.countryID,
        //            companyName: this.model?.shipFrom_Company,
        //            establishmentType: 100
        //        };
        //    }
        //    else {
        //        return {
        //            address1: this.model?.shipTo_Address1,
        //            address2: this.model?.shipTo_Address2,
        //            address3: this.model?.shipTo_Address3,
        //            city: this.model?.shipTo_City,
        //            state: this.model?.shipTo_State,
        //            postalCode: this.model?.shipTo_PostalCode,
        //            countryID: this.model?.shipTo_CountryID,
        //            companyName: this.model?.shipTo_Company,
        //            establishmentType: this.model?.shipTo_EstablimentType ?? 100
        //        };
        //    }
        //}
        //catch {
        //    return {
        //        address1: this.model?.shipTo_Address1,
        //        address2: this.model?.shipTo_Address2,
        //        address3: this.model?.shipTo_Address3,
        //        city: this.model?.shipTo_City,
        //        state: this.model?.shipTo_State,
        //        postalCode: this.model?.shipTo_PostalCode,
        //        countryID: this.model?.shipTo_CountryID,
        //        companyName: this.model?.shipTo_Company,
        //        establishmentType: this.model?.shipTo_EstablimentType ?? 100
        //    }; 
        //}

     
    },
    disabledeliveryAddress() {
        return (this.tradeTypeSelectedID ?? 0) == 2; 
    },
    companyDS() { return this.$store.getters["company/ds"]; },
    scopeCustomerDS() {
        return this.$config.common.$customerFiltered(this.user?.environmentID, (this.session?.warehouseID ?? this.$vm.$appSession?.warehouse?.id), this.user) ?? [];
    },
    customerDS() { return this.companyDS.data.filter((x) => x.type === 16); },
    currencyDS() { return this.$store.getters["currency/ds"]; },
    currencySymbol() { return this.currencyDS.data.filter((x) => x.id === this.model?.currencyID ?? this.$props.recordformData?.currencyID)[0].symbolText; },
    currencyCode() { return this.currencyDS.data.filter((x) => x.id === this.model?.currencyID ?? this.$props.recordformData?.currencyID)[0].code; },
    currentWarehouse() {
        return this.session?.warehouseID ?? this.$vm.$appSession?.warehouse?.id ?? this.model?.warehouseID ?? null;
    },
    tradeTypeParam() {
        return parseInt(this.$route.query?.type ?? 1);
    },

    tradeTypeDS() {
        return [
            { id: 1, text: 'Export', name: 'EXPORT' },
            { id: 2, text: 'Import', name: 'IMPORT' },
            { id: 4, text: 'Third Country/Party', name: 'THIRDCOUNTRY' }
        ]
    },
    tradeTypeSelectedID() {
        switch (this.model.tradeType ?? this.tradeTypeParam) {
            case 1: return 1;
            case 2: return 2;
            default:return 4;
        }
    },
    scopeWarehouseDS() {
        return this.$config.common.$warehouse(this.user?.environmentID, (this.session?.warehouseID ?? this.$vm.$appSession?.warehouse?.id), this.user) ?? [];
    },
    warehouse() {
        return this.scopeWarehouseDS.filter(x => x.id == this.currentWarehouse)[0];
    },
    customerFlagID() {
        return this.model.customerID;
    },
    customer() {
        return this.scopeCustomerDS.filter(x => x.id == (this.model?.customerID ?? this.$props?.formData?.customerID ?? this.$props?.recordformData?.customerID))[0];
    },
    companyProfileRecord() {
        return this.$store.getters["companyprofile/record"];
    },
    companySettingRecord() {
        return this.$store.getters["companygeneralsetting/record"];
    },
    customerPackageDS() {
        return this.$store.getters["companydefaultpackage/record"];
    },
    packageSummary() {
        let totalPackageQty = 0;
        let totalWeight = 0;
        let totaldeclaredvalue = 0;
        let totalinsurancevalue = 0
        let totalcustomsvalue = 0;
        let totaldutypaidvalue = 0;
        let totaltaxpaidvalue = 0;

        if (this.model?.packages != null) {
            this.model.packages.forEach((e) => {
                if (e.items?.length ?? 0 > 0) {
                    e.items?.forEach((x) => {
                        totalPackageQty = totalPackageQty + x.quantity
                    }, this);
                }
                else {
                    totalPackageQty = totalPackageQty + 1;
                }
                totalWeight = totalWeight + e.weight;
                totaldeclaredvalue = totaldeclaredvalue + e.consignmentValue;
                totalinsurancevalue = totalinsurancevalue + e.insuranceValue;
                totalcustomsvalue = totalcustomsvalue + e.customsValue;
                totaldutypaidvalue = totaldutypaidvalue + e.dutyValue;
                totaltaxpaidvalue = totaltaxpaidvalue + e.taxValue;
            }, this);
        }
        return {
            totalPackage: this.model?.packages?.length ?? 1,
            totalPackageWeight: totalWeight,
            totalPackageQty: totalPackageQty,
            totalPackageDeclaredValue: totaldeclaredvalue,
            totalPackageInsuranceValue: totalinsurancevalue,
            totalPackageCustomsValue: totalcustomsvalue,
            totalPackageDutyPaidValue: totaldutypaidvalue,
            totalPackageTaxPaidValue: totaltaxpaidvalue
        };
    },
    NotificationDisplay() {
        if ((this.model?.notification ?? 0) > 0) {
            let _self = this;
            let _notificationtext = ""
            let flagValues = this.$filters.getFlags(this.model.notification);
            let idx = 1;
            flagValues.forEach((value) => {
                _notificationtext += _self.$vm.$enums['notificationtype'].filter((x) => x.id === value)[0]?.text;
                if (flagValues.length > 1 && idx !== flagValues.length)
                    _notificationtext += ', ';
                idx++;
            });
            return _notificationtext;
        }
        else {
            return "";
        }
    },
    OriginCountry() {
        return this.$vm.$countries.filter(x => x.id === this.$props.formData?.shipFrom_CountryID)[0];
    },
    OriginISO2() {
        return this.OriginCountry?.code;
    },
    DestinationCountry() {
        return this.$vm.$countries.filter(x => x.id === this.$props.formData?.shipTo_CountryID)[0];
    },
    DestinationISO2() {
        return this.DestinationCountry?.code;
    },
    isDomestic() {
        return this.$filters.isDomestic(this.OriginISO2, this.$props.formData?.shipFrom_PostalCode ?? '', this.DestinationISO2, this.$props.formData?.shipTo_PostalCode ?? '');
    }
};

var crud = {
    
};

var watch = {
    keys() {
        this.$nextTick(() => {
            this.session = this.sessionKeys;
            if ((this.model?.id ?? 0 === 0) && this.warehouse !== undefined) {
                this.$nextTick(() => {
                    this.onInitAddress();
                })
            }
            if (((this.model?.id ?? 0) === 0) && (this.customer === undefined)) {
                this.$nextTick(() => {
                    this.model.customerID = this.scopeCustomerDS[0]?.id;
                    //this.model.resetPackage = true;
                });
            } 
        });
    }
}

var method = {
    populateCollectionContact(e) {
        if (e) {
            this.$nextTick(() => {
                this.model.shipFrom_FirstName = e.firstName;
                this.model.shipFrom_LastName = e.lastName;
                this.model.shipFrom_Email = e.email;
                this.model.shipFrom_Mobile = e.mobile;
                this.model.shipFrom_Telephone = e.telephone;
                this.model.shipFrom_EORI = e.eori;
                this.model.shipFrom_IOSSNumber = e?.ioss ?? e.iossNumber;
                this.model.shipFrom_UKIMS = e?.ukims ?? e.ukimsNumber;
                this.model.shipFrom_TaxID = e?.vat ?? e.vatNo;
                this.model.shipFrom_IDentityNumber = e?.idNumber ?? e.identityNumber;
                this.model.shipFrom_IDentityNumberType = e?.idType ?? e?.identityNumberType ?? 300;
                //this.collectionContact = {
                //    firstName: this.model?.shipFrom_FirstName,
                //    lastName: this.model?.shipFrom_LastName,
                //    email: this.model?.shipFrom_Email,
                //    mobile: this.model?.shipFrom_Mobile,
                //    telephone: this.model?.shipFrom_Telephone,
                //    eori: this.model?.shipFrom_EORI,
                //    ioss: this.model?.shipFrom_IOSSNumber,
                //    vat: this.model?.shipFrom_TaxID,
                //    idNumber: this.model?.shipFrom_IDentityNumber,
                //    idType: this.model?.shipFrom_IDentityNumberType
                //};
                
                if (e.address && e.address?.address1) {
                    //if (!this.initAddressLoad)
                    //    this.initAddressLoad = true;
                    if (this.tradeTypeSelectedID !== 1)
                        this.populateCollectionAddress(e.address);
                }
            });
        }
    },
    populateDeliveryContact(e) {
        this.$nextTick(() => {
            this.model.shipTo_FirstName = e.firstName;
            this.model.shipTo_LastName = e.lastName;
            this.model.shipTo_Email = e.email;
            this.model.shipTo_Mobile = e.mobile;
            this.model.shipTo_Telephone = e.telephone;
            this.model.shipTo_EORI = e.eori;
            this.model.shipTo_IOSSNumber = e?.ioss ?? e.iossNumber;
            this.model.shipTo_UKIMS = e?.ukims ?? e.ukimsNumber;
            this.model.shipTo_TaxID = e?.vat ?? e.vatNo;
            this.model.shipTo_IDentityNumber = e?.idNumber ?? e.identityNumber;
            this.model.shipTo_IDentityNumberType = e?.idType ?? e?.identityNumberType ?? 300;
            //this.collectionContact = {
            //    firstName: this.model.shipTo_FirstName,
            //    lastName: this.model.shipTo_LastName,
            //    email: this.model.shipTo_Email,
            //    mobile: this.model.shipTo_Mobile,
            //    telephone: this.model.shipTo_Telephone,
            //    eori: this.model.shipTo_EORI,
            //    ioss: this.model.shipTo_IOSSNumber,
            //    vat: this.model.shipTo_TaxID,
            //    idNumber: this.model.shipTo_IDentityNumber,
            //    idType: this.model.shipTo_IDentityNumberType
            //};

            if (e.address && e.address?.address1) {
                if (this.tradeTypeSelectedID !== 2)
                    this.populateDeliveryAddress(e.address);
            }
        });
    },
    populateDeliveryAddress(e) {
        this.$nextTick(() => {
            if (this.model !== undefined) {
                this.model.shipTo_Address1 = e.address1;
                this.model.shipTo_Address2 = e.address2;
                this.model.shipTo_Address3 = e.address3;
                this.model.shipTo_City = e.city;
                this.model.shipTo_State = e.state;
                this.model.shipTo_PostalCode = e.postalCode;
                this.model.shipTo_CountryID = e.countryID;
                this.model.shipTo_Company = e.companyName;
                this.model.shipTo_EstablishmentType = e.establishmentType ?? this.model.shipTo_EstablishmentType ?? 100;
                
                //if (e.countryID === null || e.countryID === undefined) {
                //    this.model.shipTo_CountryID = this.model.shipFrom_CountryID ?? e.countryID;
                //}
                
                if ((e.address1?.length ?? 0) > 0);
                {
                    if (!this.initAddressLoad && this.tradeTypeSelectedID == 2)
                        this.initAddressLoad = false;
                }
            }
        });

       
       
    },
    populateCollectionAddress(e) {
        this.$nextTick(() => {
            if (this.model !== undefined) {

                this.model.shipFrom_Address1 = e.address1;
                this.model.shipFrom_Address2 = e.address2;
                this.model.shipFrom_Address3 = e.address3;
                this.model.shipFrom_City = e.city;
                this.model.shipFrom_State = e.state;
                this.model.shipFrom_PostalCode = e.postalCode;
                this.model.shipFrom_CountryID = e.countryID;
                this.model.shipFrom_Company = e.companyName;
                this.model.shipFrom_EstablishmentType = e.establishmentType ?? this.model.shipFrom_EstablishmentType ?? 100;

                //this.collectionAddress = {
                //    address1: this.model?.shipFrom_Address1,
                //    address2: this.model?.shipFrom_Address2,
                //    address3: this.model?.shipFrom_Address3,
                //    city: this.model?.shipFrom_City,
                //    state: this.model?.shipFrom_State,
                //    postalCode: this.model?.shipFrom_PostalCode,
                //    countryID: this.model?.shipFrom_CountryID,
                //    companyName: this.model?.shipFrom_Company,
                //    establishmentType: this.model?.shipFrom_EstablishmentType ?? 200
                //};
                if ((this.model?.shipTo_CountryID ?? 0) === 0) {
                    this.model.shipTo_CountryID = e.countryID;
                }

                if (e.countryID === null || e.countryID === undefined) {
                    if ((this.model?.shipFrom_CountryID ?? 0) === 0) {
                        this.model.shipFrom_CountryID = this.model.shipTo_CountryID ?? e.countryID;
                    }
                }
                if ((e.address1?.length ?? 0) > 0);
                {
                    if (!this.initAddressLoad && this.tradeTypeSelectedID == 1)
                        this.initAddressLoad = false;
                }
                
            }
        });

      
      
    },
    switchAddress() {
        let originAddress = this.collectionAddress;
        let originContact = this.collectionContact;
        let originSaveAddressBook = this.model.shipFrom_SaveAddressBook;

        let destinationAddress = this.deliveryAddress;
        let destinationContact = this.deliveryContact;
        let destinationSaveAddressBook = this.model.shipTo_SaveAddressBook;

        this.model.shipFrom_AddressBookID = null;
        this.model.shipFrom_FirstName = destinationContact.firstName;
        this.model.shipFrom_LastName = destinationContact.lastName;
        this.model.shipFrom_Telephone = destinationContact.telephone;
        this.model.shipFrom_Mobile = destinationContact.mobile;
        this.model.shipFrom_Email = destinationContact.email;
        this.model.shipFrom_TaxID = destinationContact.vat;
        this.model.shipFrom_EORI = destinationContact.eori;
        this.model.shipFrom_UKIMS = destinationContact.ukims;
        this.model.shipFrom_IOSSNumber = destinationContact.ioss;
        this.model.shipFrom_IDentityNumber = destinationContact.idNumber;
        this.model.shipFrom_IDentityNumberType = destinationContact.idType,

        this.model.shipFrom_Company = destinationAddress.companyName;
        this.model.shipFrom_CountryID = destinationAddress.countryID;
        this.model.shipFrom_Address1 = destinationAddress.address1;
        this.model.shipFrom_Address2 = destinationAddress.address2;
        this.model.shipFrom_Address3 = destinationAddress.address3;
        this.model.shipFrom_City = destinationAddress.city;
        this.model.shipFrom_State = destinationAddress.state;
        this.model.shipFrom_PostalCode = destinationAddress.postalCode;
        this.model.shipFrom_EstablishmentType = destinationAddress.establishmentType;
        this.model.shipFrom_AtRisk = destinationAddress.atRisk;
        this.model.shipFrom_SaveAddressBook = destinationSaveAddressBook;

        this.model.shipTo_AddressBookID = null;
        this.model.shipTo_FirstName = originContact.firstName;
        this.model.shipTo_LastName = originContact.lastName;
        this.model.shipTo_Telephone = originContact.telephone;
        this.model.shipTo_Mobile = originContact.mobile;
        this.model.shipTo_Email = originContact.email;
        this.model.shipTo_TaxID = originContact.vat;
        this.model.shipTo_EORI = originContact.eori;
        this.model.shipTo_IOSSNumber = originContact.ioss;
        this.model.shipTo_UKIMS = originContact.ukims;
        this.model.shipTo_IDentityNumber = originContact.idNumber;
        this.model.shipTo_IDentityNumberType = originContact.idType;

        this.model.shipTo_Company = originAddress.companyName;
        this.model.shipTo_CountryID = originAddress.countryID;
        this.model.shipTo_Address1 = originAddress.address1;
        this.model.shipTo_Address2 = originAddress.address2;
        this.model.shipTo_Address3 = originAddress.address3;
        this.model.shipTo_City = originAddress.city;
        this.model.shipTo_State = originAddress.state;
        this.model.shipTo_PostalCode = originAddress.postalCode;
        this.model.shipTo_EstablishmentType = originAddress.establishmentType;
        this.model.shipTo_AtRisk = originAddress.atRisk;
        this.model.shipTo_SaveAddressBook = originSaveAddressBook;
        

    },
    onClearContactAddress() {
        this.model.shipFrom_AddressBookID = null;
        this.model.shipFrom_FirstName = null;
        this.model.shipFrom_LastName = null;
        this.model.shipFrom_Telephone = null;
        this.model.shipFrom_Mobile = null;
        this.model.shipFrom_Email = null;
        this.model.shipFrom_TaxID = null;
        this.model.shipFrom_EORI = null;
        this.model.shipFrom_IOSSNumber = null;
        this.model.shipFrom_UKIMS = null;
        this.model.shipFrom_IDentityNumber = null;
        this.model.shipFrom_IDentityNumberType = 300,

        this.model.shipFrom_Company = null;
        this.model.shipFrom_CountryID = this.warehouse?.countryID;
        this.model.shipFrom_Address1 = null;
        this.model.shipFrom_Address2 = null;
        this.model.shipFrom_Address3 = null;
        this.model.shipFrom_City = null;
        this.model.shipFrom_State = null;
        this.model.shipFrom_PostalCode = null;
        this.model.shipFrom_EstablishmentType = 100;
        this.model.shipFrom_AtRisk = false;

        this.model.shipTo_AddressBookID = null;
        this.model.shipTo_FirstName = null;
        this.model.shipTo_LastName = null;
        this.model.shipTo_Telephone = null;
        this.model.shipTo_Mobile = null;
        this.model.shipTo_Email = null;
        this.model.shipTo_TaxID = null;
        this.model.shipTo_EORI = null;
        this.model.shipTo_IOSSNumber = null;
        this.model.shipTo_UKIMS = null;
        this.model.shipTo_IDentityNumber = null;
        this.model.shipTo_IDentityNumberType = 300;

        this.model.shipTo_Company = null;
        this.model.shipTo_CountryID = this.warehouse?.countryID;
        this.model.shipTo_Address1 = null;
        this.model.shipTo_Address2 = null;
        this.model.shipTo_Address3 = null;
        this.model.shipTo_City = null;
        this.model.shipTo_State = null;
        this.model.shipTo_PostalCode = null;
        this.model.shipTo_EstablishmentType = 100;
        this.model.shipTo_AtRisk = false;

    },
    onInitAddress() {
        let _self = this;
        if ((this.model?.id ?? 0 === 0) && this.warehouse !== undefined) {
            this.$nextTick(() => {
                if (this.tradeTypeSelectedID == 1) {
                    _self.populateCollectionAddress(this.warehouse);
                    this.$nextTick(() => {
                        if (this.model.shipTo_CountryID == null || this.model.shipTo_CountryID == undefined)
                            this.model.shipTo_CountryID = this.model.shipFrom_CountryID
                    })
                }
                else if (this.tradeTypeSelectedID == 2) {
                    _self.populateDeliveryAddress(this.warehouse);
                    this.$nextTick(() => {
                        if (this.model.shipFrom_CountryID == null || this.model.shipFrom_CountryID == undefined)
                            this.model.shipFrom_CountryID = this.model.shipTo_CountryID
                    })
                }    
            })
        }
    },
    onInitContact() {
        let _self = this;
        if ((this.customer.id ?? 0) > 0) {
            _self.getProfile(_self.customer?.mask).then(() => {
                if (_self.companyProfileRecord?.companyID !== -1) {
                    if (this.tradeTypeSelectedID == 1) {
                        _self.populateCollectionContact(_self.companyProfileRecord);
                        if (this.model)
                            this.model.shipFrom_Company = _self.customer?.altName ?? _self.customer?.name ?? "";
                    }
                    else if (this.tradeTypeSelectedID == 2) {
                        _self.populateDeliveryContact(_self.companyProfileRecord);
                        if (this.model)
                            this.model.shipTo_Company = _self.customer?.altName ?? _self.customer?.name ?? "";
                    }
                }
                _self.initContactLoad = false;
            });
            _self.getSetting(_self.customer?.mask).then(() => {
                let _setting = this.companySettingRecord;
                if (_setting !== undefined) {
                    if (this.model) {
                        this.model.autoNumber = _setting.setting_AutoNumberConsignment ?? false;
                        this.model.defaultPackageContent = _setting.default_PackageContent ?? "";
                        this.model.packageType = _setting.default_PackageType;
                    }
                }
            });
        }
        
    },
    onEventCustomerChange(e) {
        if (e.value != this.model.customerID && !isNaN(e.value) && !isNaN(this.model.customerID)
            && (e.value !== null && e.value !== undefined) && (this.model.customerID !== null && this.model.customerID !== undefined)
            && (this.buttonTicker ?? 0) > 0
        ) {
            this.customerDialog.show = true;
            this.customerDialog.newValue = e;
            this.customerDialog.currentID = this.model.customerID;
            this.customerDialog.apply = false;
            this.customerDialog.retain = false;
        }
        else {
            if (!(this.$route.params.mask) && this.model.customerID !== e.value && e.value !== null && e.value !== undefined) {
                this.$nextTick(() => {
                    if (this.customer?.mask) {
                        this.initContactLoad = true;
                        this.onInitContact();
                    }
                });
            }
            this.model.customerID = e.value;
            this.model.customerCode = e.record?.code;
        }
    },
    onEventTradeTypeChange(e) {
        if (((this.$route.params.mask) ? (this.model.tradeType !== null && this.model.tradeType !== undefined) : true) && this.model.tradeType !== e.value && e.value !== null && e.value !== undefined) {
            if (this.customer?.mask) {
                this.$nextTick(() => {
                    if (this.tradeTypeSelectedID <= 2)
                        this.switchAddress();

                }).then(() => {
                    if ((this.model.tradeType == 1 && !(this.model.shipFrom_FirstName) && !(this.model.shipFrom_LastName) && !(this.model.shipFrom_Email)) ||
                        (this.model.tradeType == 2 && !(this.model.shipTo_FirstName) && !(this.model.shipTo_LastName) && !(this.model.shipTo_Email))
                    ) {
                        this.initContactLoad = true;
                        this.onInitContact();
                    }
                    
                    this.$nextTick(() => {
                        this.onInitAddress();
                    })
                });
            }
        }
        this.model.tradeType = e.value;
    },
    async getCompanyList() {
        return this.$store.dispatch("company/getAll", {sort:null, where:null});
    },
    formatDecimal(value, decimalPart) {
        return Number(value ?? 0).toFixed(decimalPart ?? 2);
    },
    formatCurrency(value, currencyCode) {
        return value.toLocaleString('en-US', { style: 'currency', currency: currencyCode ?? this.currencyDS.data.filter((x) => x.id === this.model?.currencyID ?? this.$props.recordformData?.currencyID)[0]?.code ?? "USD" });
    },
    getDate(data) {
        if (data != null) {
            return new Date().setFullYear(Number(data.substring(0, 4)), Number(data.substring(4, 6)) - 1, Number(data.substring(6, 8)))
        }
        return new Date();
    },
    getTime(data) {
        if (data != null) {
            return new Date().setHours(Number(data.substring(0, 2)), Number(data.substring(2, 4)), Number(data.substring(4, 6)))
        }
        return new Date();
    },
    isWithFuelCharge(data) {
        return data?.Quote?.ItemizedCharges?.filter((x) => x.Description === "Fuel Surcharge").length > 0;
    },
    productDS() { return this.$store.getters["serviceproviderproduct/ds"]; },
    async getProfile(mask) {
        return this.$store.dispatch("companyprofile/getURL", { url: `/services/settings/companyprofile/ByCompany/${mask ?? this.customer?.mask}`.replaceAll("//", "/") });
    },
    async getSetting(mask) {
        if ((mask ?? this.customer?.mask) !== undefined && (mask ?? this.customer?.mask) !== null)
            return this.$store.dispatch("companygeneralsetting/getURL", { url: `/services/settings/companygeneralsetting/ByCompany/${(mask ?? this.customer?.mask)}`.replaceAll("//", "/") });
    },
    async getCustomerDefaultPackage(id) {
        return this.$store.dispatch("companydefaultpackage/getURL", { url: `/services/settings/companydefaultpackage/customer/id/${id ?? this.customer?.id ?? this.$props?.recordformData?.customerID ?? this.$props?.customerID ?? -100}`.replaceAll("//", "/") });
    },
    displayReference(model) {
        if (model?.autoNumber) {
            if ((model?.customerReference ?? "").indexOf("TEMP") !== -1 || model.customerReference == null)
                return "(auto number)"
            else
                return model?.customerReference
        }
        return model?.customerReference
    },
    async GetCustomers() {
        return this.$config.common.$customer(this.session.environmentID, this.session.warehouseID, this.user);
    }
      
};

import { required, minLength, maxLength, email, helpers } from '@vuelidate/validators';
import validator from '@/assets/_js/cosmos-validators.js';

/** CUSTOM VALIDATOR **/
const regex = (param) => helpers.withParams({ type: 'regex', value: param }, (value) => value.match(param) != null);

export default {
    STEPPER: {
        data() { return { ...model } },
        computed: { ...computed },
        methods: { ...method, ...crud },
        watch: {
            v$: { handler() { this.modelState = this.v$ }, deep: true },

        },
        async created() {
            
        },
        async mounted() {
           
        },
        async updated() {
           
        },
        async beforeUnmount() {
           
        },
        async unmount() {  }
    },
    ADDRESSES: {
        STEPPER: {
            props: {
                ...props
            },
            data() { return { ...addressesFormModel, ...sessionModel } },
            computed: {
                tradeTypeParam() {
                    return (this.$route.query?.type ?? 1);
                },

            },
            validations() {
                let state = {
                    model: {
                        customerID: { required, $autoDirty: true },
                        shipFrom_FirstName: { required, minLength: minLength(1), maxLength: maxLength(35), $autoDirty: true },
                        shipFrom_LastName: { minLength: minLength(0), maxLength: maxLength(35), $autoDirty: true },
                        shipFrom_Address1: { required, minLength: minLength(1), maxLength: maxLength(35), $autoDirty: true },
                        shipFrom_City: { required, minLength: minLength(1), maxLength: maxLength(35), $autoDirty: true },
                        //shipFrom_PostalCode: { required, minLength: minLength(1), maxLength: maxLength(35), $autoDirty: true },
                        shipFrom_Email: { required, email, minLength: minLength(1), maxLength: maxLength(100), $autoDirty: true },
                        shipTo_FirstName: { required, minLength: minLength(1), maxLength: maxLength(35), $autoDirty: true },
                        shipTo_LastName: { minLength: minLength(0), maxLength: maxLength(35), $autoDirty: true },
                        shipTo_Address1: { required, minLength: minLength(1), maxLength: maxLength(35), $autoDirty: true },
                        shipTo_City: { required, minLength: minLength(1), maxLength: maxLength(35), $autoDirty: true },
                        //shipTo_PostalCode: { required, minLength: minLength(1), maxLength: maxLength(35), $autoDirty: true },
                        shipTo_Email: { required, email, minLength: minLength(1), maxLength: maxLength(100), $autoDirty: true },
                        shipTo_CountryID: { required, $autoDirty: true }

                    }
                }

                if (this.OriginCountry != null && this.OriginCountry !== undefined) {

                    if ((this.companySettingRecord?.setting_CheckState ?? true) && this.OriginCountry.stateRequired)
                        state.model.shipFrom_State = { required, maxLength: maxLength(35), $autoDirty: true };
                    else
                        state.model.shipFrom_State = { maxLength: maxLength(35), $autoDirty: true };

                    if (this.OriginCountry.postalCodeRequired) {
                        state.model.shipFrom_PostalCode = { required, maxLength: maxLength(15), $autoDirty: true };
                        if ((this.companySettingRecord?.setting_CheckPostalCode ?? true) && this.OriginCountry.postalCodeRegExPattern && this.OriginCountry.postalCodePattern) {
                            state.model.shipFrom_PostalCode = { required, maxLength: maxLength(15), $autoDirty: true, regex: helpers.withMessage(`The postal code must follow this format: ${this.OriginCountry.postalCodePattern}.`, regex(this.OriginCountry.postalCodeRegExPattern)) };
                        }
                    }
                    else
                        state.model.shipFrom_PostalCode = { maxLength: maxLength(15), $autoDirty: true };
                }

                if (this.DestinationCountry != null && this.DestinationCountry !== undefined) {

                    if ((this.companySettingRecord?.setting_CheckState ?? true) && this.DestinationCountry.stateRequired)
                        state.model.shipTo_State = { required, maxLength: maxLength(35), $autoDirty: true };
                    else
                        state.model.shipTo_State = { maxLength: maxLength(35), $autoDirty: true };

                    if (this.DestinationCountry.postalCodeRequired) {
                        state.model.shipTo_PostalCode = { required, maxLength: maxLength(15), $autoDirty: true };
                        if ((this.companySettingRecord?.setting_CheckPostalCode ?? true) && this.DestinationCountry.postalCodeRegExPattern && this.DestinationCountry.postalCodePattern) {
                            state.model.shipTo_PostalCode = { required, maxLength: maxLength(15), $autoDirty: true, regex: helpers.withMessage(`The postal code must follow this format: ${this.OriginCountry.postalCodePattern}.`, regex(this.DestinationCountry.postalCodeRegExPattern)) };
                        }
                    }
                    else
                        state.model.shipTo_PostalCode = { maxLength: maxLength(15), $autoDirty: true };

                }

                return state;
            },
            'formData': {
                handler() {
                    this.$nextTick(() => {
                        //this.model = this.$props.formData;
                        for (let field in this.$props.formData) {
                            this.model[field] = this.$props.formData[field];
                        }
                    });
                }, deep: true
            },
            methods: {
                onSync(e) {
                    this.$nextTick(() => {
                        for (let field in e.model) {
                            this.model[field] = e.model[field];
                        }
                    });
                }
            },
            async created() {
                this.model = {
                    ...addressesFormModel.model
                };

                this.model.tradeType = this.tradeTypeParam;
            },
            unmounted() {
                this.$nextTick(() => {
                    this.model = { ...addressesFormModel.model };
                    this.$props.formData = null;
                    this.$props.recordformData = null;
                });
            }
        },
        FORM: {
            props: {
                ...props
            },
            data() {
                return {
                    ...addressesFormModel, ...sessionModel, customerDialog: {
                        show: false,
                        newValue: null,
                        currentID: null,
                        apply: false,
                        retain: false
                } } },
            validations() {
                let state = {
                    model: {
                        customerID: { required, $autoDirty: true },
                        shipFrom_FirstName: { required, minLength: minLength(1), maxLength: maxLength(35), $autoDirty: true },
                        shipFrom_LastName: {  minLength: minLength(0), maxLength: maxLength(35), $autoDirty: true },
                        shipFrom_Address1: { required, minLength: minLength(1), maxLength: maxLength(35), $autoDirty: true },
                        shipFrom_City: { required, minLength: minLength(1), maxLength: maxLength(35), $autoDirty: true },
                        //shipFrom_PostalCode: { required, minLength: minLength(1), maxLength: maxLength(35), $autoDirty: true },
                        shipFrom_Email: { required, email, minLength: minLength(1), maxLength: maxLength(100), $autoDirty: true },
                        shipTo_FirstName: { required, minLength: minLength(1), maxLength: maxLength(35), $autoDirty: true },
                        shipTo_LastName: { minLength: minLength(0), maxLength: maxLength(35), $autoDirty: true },
                        shipTo_Address1: { required, minLength: minLength(1), maxLength: maxLength(35), $autoDirty: true },
                        shipTo_City: { required, minLength: minLength(1), maxLength: maxLength(35), $autoDirty: true },
                        //shipTo_PostalCode: { required, minLength: minLength(1), maxLength: maxLength(35), $autoDirty: true },
                        shipTo_Email: { required, email, minLength: minLength(1), maxLength: maxLength(100), $autoDirty: true },
                        shipTo_CountryID: { required, $autoDirty: true }
                        
                    }
                }

                if (this.OriginCountry != null && this.OriginCountry !== undefined) {

                    if ((this.companySettingRecord?.setting_CheckState ?? true) && this.OriginCountry.stateRequired)
                        state.model.shipFrom_State = { required, maxLength: maxLength(35), $autoDirty: true };
                    else
                        state.model.shipFrom_State = { maxLength: maxLength(35), $autoDirty: true };

                    if (this.OriginCountry.postalCodeRequired) {
                        state.model.shipFrom_PostalCode = { required, maxLength: maxLength(15), $autoDirty: true };
                        if ((this.companySettingRecord?.setting_CheckPostalCode ?? true) && this.OriginCountry.postalCodeRegExPattern && this.OriginCountry.postalCodePattern) {
                            state.model.shipFrom_PostalCode = { required, maxLength: maxLength(15), $autoDirty: true, regex: helpers.withMessage(`The postal code must follow this format: ${this.OriginCountry.postalCodePattern}.`, regex(this.OriginCountry.postalCodeRegExPattern)) };
                        }
                    }
                    else
                        state.model.shipFrom_PostalCode = { maxLength: maxLength(15), $autoDirty: true };
                }

                if (this.DestinationCountry != null && this.DestinationCountry !== undefined) {

                    if ((this.companySettingRecord?.setting_CheckState ?? true) && this.DestinationCountry.stateRequired)
                        state.model.shipTo_State = { required, maxLength: maxLength(35), $autoDirty: true };
                    else
                        state.model.shipTo_State = { maxLength: maxLength(35), $autoDirty: true };

                    if (this.DestinationCountry.postalCodeRequired) {
                        state.model.shipTo_PostalCode = { required, maxLength: maxLength(15), $autoDirty: true };
                        if ((this.companySettingRecord?.setting_CheckPostalCode ?? true) && this.DestinationCountry.postalCodeRegExPattern && this.DestinationCountry.postalCodePattern) {
                            state.model.shipTo_PostalCode = { required, maxLength: maxLength(15), $autoDirty: true, regex: helpers.withMessage(`The postal code must follow this format: ${this.OriginCountry.postalCodePattern}.`, regex(this.DestinationCountry.postalCodeRegExPattern)) };
                        }
                    }
                    else
                        state.model.shipTo_PostalCode = { maxLength: maxLength(15), $autoDirty: true };

                }
                
                return state;
            },
            computed: { ...computed },
            methods: {
                ...method, ...crud,
            },

            watch: {
                ...watch,
                'formData': {
                    handler() {
                        if ((this.$props?.formData?.id ?? 0) > 0) {
                            //ETO AY PARA SA EDIT PARA MAREFLECT YUNG CHANGES SA PROXY
                            this.$nextTick(() => {
                                this.model = this.$props.formData;
                            });
                            ////ETO PO AY PARA MAREBIND/HANDLE YUNG DISPLAY NG ADDRESS SA EDIT PAGE FROM DEALLOCATE PROCESS
                            //if (this.$props?.formData.isDeallocate) {
                            //    for (let field in this.$props.formData) {
                            //        this.model[field] = this.$props.formData[field];
                            //    }
                            //    this.model.isDeallocate = false;
                            //}
                            //else {
                                
                            //}
                        }
                    }, deep: true
                },
                'model': {
                    handler() {
                        this.$nextTick(() => {
                            this.$emit("is-sync", { model: this.model }); 
                        });
                    }, deep: true
                }
               
            },
            async created() {
                this.model = {
                    ...addressesFormModel.model
                };  
            },
            async mounted() {
                window.addEventListener('setItem', () => {
                    this.keys = sessionStorage.getItem('activekeys');
                });
                this.keys = sessionStorage.getItem('activekeys');
            },
            async unmounted() {
                try {
                    window.removeEventListener('setItem');
                } catch { /**/ }
                this.$nextTick(() => {
                    this.model = null;
                    this.$props.formData = null;
                    this.$props.recordformData = null;
                });
            }
        }
    },
    COLLECTION: {
        STEPPER: {
            props: {
                ...props
            },
            data() { return { ...collectionFormModel, ...sessionModel } },
            validations() {
                return {
                    model: {
                        shipFrom_FirstName: { required, minLength: minLength(1), maxLength: maxLength(50), code: validator.code(50), $autoDirty: true },
                        shipFrom_Address1: { required, minLength: minLength(1), maxLength: maxLength(35), $autoDirty: true },
                        shipFrom_City: { required, minLength: minLength(1), maxLength: maxLength(35), $autoDirty: true },
                        shipFrom_PostalCode: { required, minLength: minLength(1), maxLength: maxLength(35), $autoDirty: true },
                        shipFrom_Email: { required, email, minLength: minLength(1), maxLength: maxLength(100), $autoDirty: true },
                        customerID: { required, $autoDirty: true },
                    }
                }
            },
            watch: {
                'formData': {
                    handler() {
                        this.$nextTick(() => {
                            //this.model = this.$props.formData;
                            for (let field in this.$props.formData) {
                                this.model[field] = this.$props.formData[field];
                            }
                        });
                    }, deep: true
                }
            },
            methods: {
                onSync(e) {
                    this.$nextTick(() => {
                        for (let field in e.model) {
                            this.model[field] = e.model[field];
                        }
                    });
                }
            },
            async mounted() {
                //for (let field in this.$props.formData) {
                //    this.model[field] = this.$props.formData[field];
                //}
            },
            async unmounted() {
                this.$nextTick(() => {
                    this.model = { ...collectionFormModel.model };
                    this.$props.formData = null;
                    this.$props.recordformData = null;
                });
            }
        },
        FORM: {
            props: {
                ...props
            },
            data() { return { ...collectionFormModel, ...sessionModel } },
            validations() {
                return {
                    model: {
                        shipFrom_FirstName: { required, minLength: minLength(1), maxLength: maxLength(50), code: validator.code(50), $autoDirty: true },
                        shipFrom_Address1: { required, minLength: minLength(1), maxLength: maxLength(35), $autoDirty: true },
                        shipFrom_City: { required, minLength: minLength(1), maxLength: maxLength(35), $autoDirty: true },
                        shipFrom_PostalCode: { required, minLength: minLength(1), maxLength: maxLength(35), $autoDirty: true },
                        shipFrom_Email: { required, email, minLength: minLength(1), maxLength: maxLength(100), $autoDirty: true },
                        customerID: { required, $autoDirty: true },
                    }
                }
            },
            computed: { ...computed },
            methods: {
                ...method, ...crud,
            },

            watch: {
                ...watch,
                'model': {
                    handler() {
                        this.$nextTick(() => {
                            // this.$emit("is-sync", { model: this.model });
                        });
                    }, deep: true
                },
                'model.customerID': {
                    handler() {
                        let _self = this;
                        this.$nextTick(() => {
                            if (_self.customer?.mask) {
                                _self.initContactLoad = true;
                                _self.getProfile(_self.customer?.mask).then(() => {
                                    if (_self.companyProfileRecord?.companyID !== -1) {
                                        _self.populateCollectionContact(_self.companyProfileRecord);

                                    }
                                    this.model.shipFrom_Company = _self.customer?.altName ?? _self.customer?.name;
                                    //if ((this.model.customerID !== this.$props.formData.customerID)) {

                                    //}
                                    _self.initContactLoad = false;
                                });
                                _self.getSetting(_self.customer?.mask).then(() => {
                                    let _setting = this.companySettingRecord;
                                    if (_setting !== undefined) {
                                        this.model.autoNumber = _setting.setting_AutoNumberConsignment;
                                        this.model.defaultPackageContent = _setting.default_PackageContent;
                                    }
                                });
                            }
                            //if ((this.model.customerID !== this.$props.formData.customerID) || this.model.shipFrom_FirstName === null) { 
                            //}

                        });
                    }, deep: true
                }
            },
            async created() {
            },
            async mounted() {
                window.addEventListener('setItem', () => {
                    this.keys = sessionStorage.getItem('activekeys');
                });
                this.keys = sessionStorage.getItem('activekeys');
                //let _self = this;
                //this.$nextTick(() => {
                //    for (let field in model) {
                //        this.model[field] = _self.$props.formData[field];
                //    }
                //});
            },
            async unmounted() {
                try {
                    window.removeEventListener('setItem');
                } catch { /**/ }
                this.$nextTick(() => {
                    this.model = null;
                    this.$props.formData = null;
                    this.$props.recordformData = null;
                });
                //let _self = this;
                //this.$nextTick(() => {
                //    for (let field in _self.$props.formData) {
                //        this.model[field] = _self.$props.formData[field];
                //    }
                //});
            }
        }
    },
    RECIPIENT: {
        STEPPER: {
            props: {
                ...props
            },
            data() { return { ...recipientFormModel } },
            validations() {
                return {
                    model: {
                        shipTo_FirstName: { required, minLength: minLength(1), maxLength: maxLength(35), $autoDirty: true },
                        shipTo_Address1: { required, minLength: minLength(1), maxLength: maxLength(35), $autoDirty: true },
                        shipTo_City: { required, minLength: minLength(1), maxLength: maxLength(35), $autoDirty: true },
                        shipTo_PostalCode: { required, minLength: minLength(1), maxLength: maxLength(35), $autoDirty: true },
                        shipTo_Email: { required, email, minLength: minLength(1), maxLength: maxLength(100), $autoDirty: true },
                        shipTo_CountryID: { required, $autoDirty: true }
                    }
                }
            },
            watch: {
                'formData': {
                    handler() {
                        this.$nextTick(() => {
                            for (let field in this.$props.formData) {
                                this.model[field] = this.$props.formData[field];
                            }
                            if ((this.model?.shipTo_CountryID ?? 0) === 0) {
                                this.model.shipTo_CountryID = this.$props.formData.shipFrom_CountryID;
                            }
                            
                        });
                    }, deep: true
                }
            },
            methods: {
                onSync(e) {
                    this.$nextTick(() => {
                        for (let field in e.model) {
                            this.model[field] = e.model[field];
                        }
                        if ((this.model?.shipTo_CountryID ?? 0) === 0) {
                            this.model.shipTo_CountryID = this.model.shipFrom_CountryID;
                        }
                    });
                }
            },
            async created() {
                this.model = {
                    ...recipientFormModel.model
                }
                this.$nextTick(() => {
                    for (let field in this.$props.formData) {
                        this.model[field] = this.$props.formData[field];
                    }
                    if ((this.model?.shipTo_CountryID ?? 0) === 0) {
                        this.model.shipTo_CountryID = this.$props.formData.shipFrom_CountryID;
                    }
                });
            },
            async mounted() {
                let _self = this;
                this.$nextTick(() => {
                    for (let field in _self.$props.formData) {
                        this.model[field] = _self.$props.formData[field];
                    }
                });
            },
            async unmounted() {
                this.$nextTick(() => {
                    this.model = { ...recipientFormModel.model };
                    this.$props.formData = null;
                    this.$props.recordformData = null;
                });
            }
        },
        FORM: {
            props: {
                ...props
            },
            data() { return { ...recipientFormModel } },
            validations() {
                return {
                    model: {
                        shipTo_FirstName: { required, minLength: minLength(1), maxLength: maxLength(35), $autoDirty: true },
                        shipTo_Address1: { required, minLength: minLength(1), maxLength: maxLength(35), $autoDirty: true },
                        shipTo_City: { required, minLength: minLength(1), maxLength: maxLength(35), $autoDirty: true },
                        shipTo_PostalCode: { required, minLength: minLength(1), maxLength: maxLength(35), $autoDirty: true },
                        shipTo_Email: { required, email, minLength: minLength(1), maxLength: maxLength(100), $autoDirty: true },
                        shipTo_CountryID: { required, $autoDirty: true }
                    }
                }
            },
            computed: { ...computed },
            methods: {
                ...method, ...crud,
                //onSync(e) {
                //    this.$nextTick(() => {
                //        this.model = e;
                //    });

                //}
            },
            watch: {
                'model': {
                    handler() {
                        this.$nextTick(() => {
                            this.$emit("is-sync", { model: this.model });
                        });
                    }, deep: true
                }
            },
            async created() {
                this.model = {
                    ...recipientFormModel.model
                };
                this.$nextTick(() => {
                    for (let field in this.$props.formData) {
                        this.model[field] = this.$props.formData[field];
                    }
                    if ((this.model?.shipTo_CountryID ?? 0) === 0) {
                        this.model.shipTo_CountryID = this.$props.formData.shipFrom_CountryID;
                    }
                });
            },
            async mounted() {
                //let _self = this;
                //this.$nextTick(() => {
                //    //for (let field in _self.$props.formData) {
                //    for (let field in this.model) {
                //        this.model[field] = _self.$props.formData[field];
                //    }
                //});
            },
            async unmounted() {
                this.$nextTick(() => {
                    this.model = null;
                    this.$props.formData = null;
                    this.$props.recordformData = null;
                });
            }
            //, async unmounted() {
            //    this.$nextTick(() => {
            //        for (let field in this.$props.formData) {
            //            this.model[field] = this.$props.formData[field];
            //        }
            //    });
            //}
        }
    },
    ORDERPACKAGE: {
        STEPPER: {
            props: {
                ...props
            },
            data() { return { ...orderpackageFormModel } },
           
            watch: {
                'formData': {
                    handler() {
                        this.$nextTick(() => {
                            //this.model = this.$props.formData;
                            for (let field in this.$props.formData) {
                                this.model[field] = this.$props.formData[field];
                            }
                        });
                    }, deep: true
                }
                
            },
            methods: {
                onSync(e) {
                    this.$nextTick(() => {
                        for (let field in e.model) {
                            this.model[field] = e.model[field];
                        }
                    });
                }
            },
            async created() {
                this.model = {
                    ...orderpackageFormModel.model
                };
            },
            async mounted() {  
                //let _self = this;
                //this.$nextTick(() => {
                //    for (let field in _self.$props.formData) {
                //        this.model[field] = _self.$props.formData[field];
                //    }
                //});
            }
        },
        FORM: {
            props: {
                ...props
            },
            data() { return { ...orderpackageFormModel, defaultInitialLoad : false } },
         
            computed: { ...computed },
            methods: {
                ...method, ...crud,
                async manageDefaultPackage() {                    
                    if ((this.source?.data?.length ?? 0) === 0) {
                        let cartonNumber = 0;
                        let packages = [];
                        (this.customerPackageDS ?? []).forEach((x) => {
                            cartonNumber++;
                            packages.push({
                                id : 0,
                                temporaryID: Number(Math.random().toString().replace(".", "")),
                                consignmentID: (this.$props?.recordformData?.id ?? 0) > 0 ? this.$props?.recordformData?.id : 0,
                                carrierReference: null,
                                cpcNumber: null,
                                ssccNumber: null,
                                consignmentValue: x.consignmentValue,
                                insuranceValue: x.insuranceValue,
                                dutyValue: x.dutyValue,
                                taxValue: x.taxValue,
                                customsValue: 0,
                                weight: x.weight,
                                netWeight: x.netWeight,
                                chargeableWeight: 0,
                                length: x.length,
                                width: x.width,
                                height: x.height,
                                volumeAutoCompute: true,
                                volume: this.$filters.convertVolume(200, this.model?.dimensionUnit, (x?.length ?? 0), (x?.width ?? 0), (x?.height ?? 0)),
                                additionalHandling: x.additionalHandling,
                                lastScan_Date: null,
                                lastScan_Location: null,
                                lastScan_Activity: null,
                                environmentID: null,
                                code: cartonNumber.toString(),
                                name: x.name,
                                mask: null,
                                items: []//,
                            });

                        });
                        //this.model.packages = packages;
                        //if (this.source !== undefined) {
                        //    this.source.data = packages;
                        //    this.source.total = this.source.data?.length ?? 0;
                        //}
                        this.source.data = packages ?? [];
                        this.source.total = this.source.data?.length ?? 0;
                        this.model.packages = this.source.data;
                        //if (this.source?.data?.length ?? 0 > 0) {
                        //    this.source.total = this.source.data?.length ?? 0;
                        //    this.model.packages = this.source.data;
                        //}
                        this.$emit("is-sync", { model: this.model });
                    }
                },
                async manageDefaultSetting() {
                    let _setting = this.companySettingRecord;
                    if (_setting !== undefined && _setting != null) {
                        this.model.shipmentType = _setting.default_ParcelCategory;
                        this.model.currencyID = _setting.default_CurrencyID ?? 1;
                        this.model.weightUnit = _setting.default_WeightUnit;
                        this.model.dimensionUnit = _setting.default_LengthUnit;
                        this.model.autoNumber = _setting.setting_AutoNumberConsignment;
                        this.model.defaultPackageContent = _setting.default_PackageContent;
                        this.model.packageType = _setting.default_PackageType;
                        this.model.notification = _setting.default_NotificationType;
                        this.model.dG_EmergencyContact = _setting.default_DGEmergencyContact;
                        this.model.dG_EmergencyTelephone = _setting.default_DGEmergencyTelephone;
                    }
                }
            },
            watch: {
                'model': {
                    handler() {
                        this.$nextTick(() => {
                            this.$emit("is-sync", { model: this.model });
                        });
                    }, deep: true
                },
                'formData': {
                    handler() {
                        this.$nextTick(() => {
                            if (this.formData.initializeOrderForm || this.formData.initializeOrderForm === undefined) {
                                this.defaultInitialLoad = false;
                                if ((this.$props.recordformData?.customerID ?? 0 > 0) && !this.defaultInitialLoad) {
                                    this.defaultInitialLoad = true;
                                    this.getSetting(this.customer?.mask).then(() => {                                        
                                        this.manageDefaultSetting().then(() => {
                                            if (this.formData.initializePackages || this.formData.initializePackages === undefined) {
                                                this.defaultInitialLoad = false;
                                                if ((this.$props.recordformData?.customerID ?? 0 > 0) && !this.defaultInitialLoad) {
                                                    if (this.model.currencyID > 0) {                                                        
                                                        this.getCustomerDefaultPackage().then(() => {
                                                            this.source.data = [];
                                                            this.source.total = 0;
                                                            this.manageDefaultPackage();
                                                            this.defaultInitialLoad = true;
                                                            this.formData.initializeOrderForm = false;
                                                            this.formData.initializePackages = false;
                                                        });
                                                    }
                                                }
                                            }

                                        });
                                    });
                                }
                            }
                           
                            for (let field in this.$props.formData) {
                                if (field === 'packages') {
                                    this.model[field] = this.$props.formData[field];
                                    this.source.data = this.model[field];
                                    this.source.total = this.source.data?.length ?? 0;
                                }
                            }
                        });
                    }, deep: true, immediate : true
                }
            },
            async created() {
                this.model = {
                    ...orderpackageFormModel.model
                };
                this.$nextTick(() => {
                    //if ((this.$props.formData?.customerID ?? 0 > 0) && (this.source?.data?.length ?? 0) === 0) {
                    //    this.getSetting(this.customer?.mask).then(() => {
                    //        this.manageDefaultSetting()
                    //    });
                    //}
                    for (let field in this.$props.formData) {
                        if (field === 'packages') {
                            this.model[field] = this.$props.formData[field];
                            this.source.data = this.model[field];
                            this.source.total = this.source.data?.length ?? 0;
                        }
                    }
                });
            },
            async mounted() {
                //this.$nextTick(() => {
                //    if (this.model?.id <= 0 || this.model?.id === undefined || this.model?.id === null) {
                //        this.getSetting(this.customer?.mask).then(() => {
                //            this.manageDefaultSetting();
                //        });
                //    }
                //});
            },
            async unmounted() {
                this.$nextTick(() => {
                    this.model = null;
                    this.$props.formData = null;
                    this.$props.recordformData = null;
                });
            }
        }
    },
    INTERNATIONALFORMS: {
        STEPPER: {
            props: {
                ...props
            },
            data() { return { ...internationalformsFormModel } },
            validations() {
                return {
                    model: {
                        shipmentType: { required, $autoDirty: true },
                        incoterm: { required, $autoDirty: true },
                        exportReason: { required, $autoDirty: true }
                    }
                }
            },
            watch: {
                'formData': {
                    handler() {
                        this.$nextTick(() => {
                            for (let field in this.$props.formData) {
                                this.model[field] = this.$props.formData[field];
                            }
                        });
                    }, deep: true
                }
            },
            methods: {
                onSync(e) {
                    this.$nextTick(() => {
                        for (let field in e.model) {
                            this.model[field] = e.model[field];
                        }
                    });
                }
            }
            ,async created() {
                this.model = {
                    ...internationalformsFormModel.model
                };
            
            },
            //,async mounted() {
            //    let _self = this;
            //    this.$nextTick(() => {
            //        for (let field in _self.$props.formData) {
            //            this.model[field] = _self.$props.formData[field];
            //        }
            //    });
            //}
        },
        FORM: {
            props: {
                ...props
            },
            data() { return { ...internationalformsFormModel, defaultInitialLoad: false } },
            validations() {
                return {
                    model: {
                        //shipmentType: { required, $autoDirty: true },
                        incoterm: { required, $autoDirty: true },
                        exportReason: { required, $autoDirty: true },
                        customsCategoryType: { required, $autoDirty: true },
                        packageContent: { $autoDirty: true },
                        cpcNumber: { $autoDirty: true },
                    }
                }
            },
            computed: { ...computed },
            methods: {
                ...method, ...crud,
                onInvoiceDate(e) {
                    this.$nextTick(() => {
                        this.model.invoiceDateValue = e.value;
                        this.model.invoiceDate = (e.record.data ?? new Date());
                    });

                },
                manageInternationDefaultSetting() {
                    let _setting = this.companySettingRecord;
                    if (_setting !== undefined && _setting != null) {
                        this.model.incoterm = _setting.default_IncoTerm;
                        this.model.customsCategoryType = _setting.default_CustomsCategory;
                        this.model.exportReason = _setting.default_ExportReason;                        
                        this.model.packageContent = _setting.default_ParcelContent;
                    }
                }
            },
            watch: {
                'formData': {
                    handler() {
                            this.$nextTick(() => {
                                if (this.formData.initializeInternationalForms || this.formData.initializeInternationalForms === undefined) {
                                    this.defaultInitialLoad = false;
                                    if ((this.$props.recordformData?.customerID ?? 0 > 0) && !this.defaultInitialLoad) {
                                        this.defaultInitialLoad = true;
                                        this.formData.initializeInternationalForms = false;
                                        this.getSetting(this.customer?.mask).then(() => {
                                            this.manageInternationDefaultSetting();
                                        });
                                    }
                                }        
                            });
                        
                    }, deep: true
                },
                'model': {
                    handler() {
                        this.$nextTick(() => {
                            if (this.model.invoiceDate === null) {
                                this.model.invoiceDate = new Date();
                            }
                            this.$emit("is-sync", { model: this.model });
                        });
                    }, deep: true
                }

            },
            async created() {
                this.model = {
                    ...internationalformsFormModel.model
                };

            },
            //async mounted() {
            //    let _self = this;
            //    this.$nextTick(() => {
            //        for (let field in _self.$props.formData) {
            //            this.model[field] = _self.$props.formData[field];
            //        }
            //    });
            //}
            unmounted() {
                this.$nextTick(() => {
                    this.model = null;
                    this.$props.formData = null;
                    this.$props.recordformData = null;
                });
            }
        }
    },
    ALLOCATE: {
        STEPPER: {
            props: {
                ...props
            },
            data() {
                return {
                    ...allocateFormModel
                }
            },
            watch: {
                'formData': {
                    handler() {
                        this.$nextTick(() => {
                            for (let field in this.$props.formData) {
                                this.model[field] = this.$props.formData[field];
                            }
                        });
                    }, deep: true
                }
            },
            methods: {
                onSync(e) {
                    this.$nextTick(() => {
                        for (let field in e.model) {
                            this.model[field] = e.model[field];
                        }
                    });
                }
            },
            mounted() {
                this.$nextTick(() => {
                    for (let field in this.$props.formData) {
                        this.model[field] = this.$props.formData[field];
                    }
                });
            }
        },
        FORM: {
            props: {
                ...props
            },
            data() {
                return {
                    ...allocateFormModel,
                    processing: {
                        shipping: false,
                        return: false,
                        shippingInfo: {
                            servicegroup: false,
                            provider: false,
                            product: false,
                            accesspoint: false

                        },
                        returnInfo: {
                            provider: false,
                            product: false,
                            accesspoint: false
                        },
                        companyInfo: {
                            generalSetting: false,
                            accountCustomer: false
                        }

                    },
                    source: {
                        shippingInfo: {
                            serviceGroup: {
                                serviceGroupID: null,
                                code: null,
                                text: null
                            },
                            carrier: {
                                serviceProviderAccountID: null,
                                code: null,
                                text: null
                            },
                            product: {
                                serviceProviderProductAccountID: null,
                                code: null,
                                text: null,
                                configuration: null
                            },
                            option: {
                                serviceProviderOptionAccountID: null,
                                code: null,
                                text: null,
                            },
                            customization: {
                                generateCustomsData: false
                            },
                            accesspoint: {
                                serviceProviderAccessPointAccountID: null,
                                code: null,
                                text: null,
                                address: null,
                                alternateDelivery: {
                                    enable: false,
                                    address: {
                                        address1: null,
                                        address2: null,
                                        address3: null,
                                        city: null,
                                        state: null,
                                        postalCode: null,
                                        countryID: null,
                                        companyName: null,
                                        establishmentType: null,
                                        fullDescription: null
                                    },
                                    contact: {

                                    }
                                }
                            },
                            release: {
                                code: null
                            },
                            additional: {
                                code: null
                            },
                            serviceGroupDS: {
                                result: []
                            },
                            providers: {
                                isBinded: false,
                                result: []
                            },
                            services: {
                                isBinded: false,
                                result: [],

                            },
                            productServices: [],
                            accesspointLocations: [],
                            accesspointProcessing: false
                        },
                        returnInfo: {
                            serviceGroup: {
                                serviceGroupID: null,
                                code: null,
                                text: null
                            },
                            carrier: {
                                serviceProviderAccountID: null,
                                code: null,
                                text: null
                            },
                            product: {
                                serviceProviderProductAccountID: null,
                                code: null,
                                text: null,
                                configuration: null
                            },
                            option: {
                                serviceProviderOptionAccountID: null,
                                code: null,
                                text: null,
                            },
                            accesspoint: {
                                serviceProviderAccessPointAccountID: null,
                                code: null,
                                text: null,
                                address: null,
                                alternateDelivery: {
                                    enable: false,
                                    address: {
                                        address1: null,
                                        address2: null,
                                        address3: null,
                                        city: null,
                                        state: null,
                                        postalCode: null,
                                        countryID: null,
                                        companyName: null,
                                        establishmentType: null,
                                        fullDescription: null
                                    },
                                    contact: {

                                    }
                                }
                            },
                            release: {
                                code: null
                            },
                            additional: {
                                code: null
                            },
                            providers: {
                                isBinded: false,
                                result: []
                            },
                            services: {
                                isBinded: false,
                                result: [],
                            },
                            productServices: [],
                            options: [],
                            accesspointLocations: [],
                        }
                        ,companyInfo: {
                            generalSetting: null,
                            accountCustomer: []
                        }
                    }
                }
            },
            computed: {
                ...computed,
                companySettingRecordSource() {
                    return this.source.companyInfo.generalSetting;
                    //return this.$store.getters["companygeneralsetting/record"];
                },
                OriginISO2() {
                    return this.$vm.$countries.filter(x => x.id === this.$props.formData?.shipFrom_CountryID)[0]?.code;
                },
                DestinationISO2() {
                    return this.$vm.$countries.filter(x => x.id === this.$props.formData?.shipTo_CountryID)[0]?.code;
                },
                isDomestic() {
                    return this.$filters.isDomestic(this.OriginISO2, this.$props.formData?.shipFrom_PostalCode ?? '', this.DestinationISO2, this.$props.formData?.shipTo_PostalCode ?? '');
                },
                columnNo() {
                    let isActive = 0;
                    if ((this.$props.formData?.isReturnable ?? false))
                        isActive++;
                    if ((this.source?.shippingInfo?.accesspoint?.alternateDelivery?.enable ?? false))
                        isActive++;
                    if ((this.source?.returnInfo?.accesspoint?.alternateDelivery?.enable ?? false))
                        isActive++;
                    return (isActive > 1) ? ((isActive > 2) ? "2" : "3") : "4";
                },
                isValidAllocate() {
                    return this.isValidShippingInfo && this.isValidShippingInfo;
                },
                isValidShippingInfo() {
                    return (((this.source?.shippingInfo?.serviceGroup?.serviceGroupID ?? 0) > 0) ? true : ((this.source?.shippingInfo.carrier?.serviceProviderAccountID ?? 0) > 0)) &&
                        (((this.source?.shippingInfo.carrier?.serviceProviderAccountID ?? 0) > 0) && ((this.source?.shippingInfo?.product?.serviceProviderProductAccountID ?? 0) > 0) && ((this.source?.shippingInfo?.product?.configuration?.RequireServiceOption ?? false) ? ((this.source?.shippingInfo.option?.serviceProviderOptionAccountID ?? 0) > 0) : true));
                },
                isValidReturnInfo() {
                    return ((this.model?.isReturnable ?? false) ?
                        ((((this.source?.returnInfo.carrier?.serviceProviderAccountID ?? 0) > 0) ? (((this.source?.returnInfo.carrier?.serviceProviderAccountID ?? 0) > 0) && ((this.source?.returnInfo?.product?.serviceProviderProductAccountID ?? 0) > 0) && ((this.source?.returnInfo?.product?.configuration?.RequireServiceOption ?? false) ? ((this.source?.returnInfo.option?.serviceProviderOptionAccountID ?? 0) > 0) : true)) : true))
                        : true);

                },
                productServiceInit() {
                    return [{
                        ProviderCode: null,
                        ProviderName: null,
                        ProductCode: null,
                        ProductName: null,
                        Disclaimer: null,
                        Quote: {
                            Description: null,
                            CurrencyCode: null,
                            Value: 0.00,
                            ItemizedCharges: [
                                {
                                    Description: null,
                                    CurrencyCode: null,
                                    Value: 0.00
                                },
                            ]
                        },
                        Schedule: {
                            CollectionSchedule: {
                                CollectionDate: null,
                                CollectionTime: null,
                                CutOffTime: null
                            },
                            EstimatedDelivery: {
                                CollectionDate: null,
                                CollectionTime: null
                            },
                            TimeInTransit: 1
                        }
                    }, {
                        ProviderCode: null,
                        ProviderName: null,
                        ProductCode: null,
                        ProductName: null,
                        Disclaimer: null,
                        Quote: {
                            Description: null,
                            CurrencyCode: null,
                            Value: 0.00,
                            ItemizedCharges: [
                                {
                                    Description: null,
                                    CurrencyCode: null,
                                    Value: 0.00
                                },
                            ]
                        },
                        Schedule: {
                            CollectionSchedule: {
                                CollectionDate: null,
                                CollectionTime: null,
                                CutOffTime: null
                            },
                            EstimatedDelivery: {
                                CollectionDate: null,
                                CollectionTime: null
                            },
                            TimeInTransit: 1
                        }
                    }, {
                        ProviderCode: null,
                        ProviderName: null,
                        ProductCode: null,
                        ProductName: null,
                        Disclaimer: null,
                        Quote: {
                            Description: null,
                            CurrencyCode: null,
                            Value: 0.00,
                            ItemizedCharges: [
                                {
                                    Description: null,
                                    CurrencyCode: null,
                                    Value: 0.00
                                },
                            ]
                        },
                        Schedule: {
                            CollectionSchedule: {
                                CollectionDate: null,
                                CollectionTime: null,
                                CutOffTime: null
                            },
                            EstimatedDelivery: {
                                CollectionDate: null,
                                CollectionTime: null
                            },
                            TimeInTransit: 1
                        }
                    }
                    ]
                },
                apiData() {
                    let packages = [];
                    let _self = this;
                    this?.$props.recordformData?.packages?.forEach((x) => {
                        packages.push({
                            Weight: {
                                GrossWeight: x.weight
                            },
                            WeightUnit: _self.$vm.$enums['weightunit'].filter((xz) => xz.id == _self.$props.recordformData?.weightUnit)[0]?.text?.toUpperCase(),
                            Dimension: {
                                Length: x.length,
                                Height: x.height,
                                Width: x.width
                            },
                            LengthUnit: _self.$vm.$enums['lengthunit'].filter((xz) => xz.id == _self.$props.recordformData?.weightUnit)[0]?.text?.toUpperCase(),
                            CurrencyCode: _self.$vm.$currencies.filter((xz) => xz.id === _self.$props.recordformData.currencyID)[0]?.code?.toUpperCase(),
                            Declaration: {
                                Declared: x.consignmentValue
                            }
                        });

                    });

                    return {
                        Request: {
                            CustomerCode: this.companyDS.data.filter(x => x.id === this?.$props.recordformData?.customerID)[0]?.code,
                            //ServiceGroupCode: ((this.model?.serviceGroupID  ?? 0) > 0) ? this.model?.serviceGroupCode ?? "TEMP" : null,
                            //ServiceGroupCode: this.model?.serviceGroupCode,
                            Carrier: ((this?.source.shippingInfo?.carrier?.code?.length ?? 0)) > 0 ? {
                                CarrierCode: this.source.shippingInfo.carrier.code
                            } : null,
                            Order: {
                                ShipFrom: {
                                    City: this.$props.recordformData?.shipFrom_City,
                                    State: this.$props.recordformData?.shipFrom_State,
                                    PostalCode: this.$props.recordformData?.shipFrom_PostalCode,
                                    Country: this.$vm.$countries.filter(x => x.id == this.$props.recordformData?.shipFrom_CountryID)[0]?.code
                                },
                                ShipTo: {
                                    City: this.$props.recordformData?.shipTo_City,
                                    State: this.$props.recordformData?.shipTo_State,
                                    PostalCode: this.$props.recordformData?.shipTo_PostalCode,
                                    Country: this.$vm.$countries.filter(x => x.id == this.$props.recordformData?.shipTo_CountryID)[0]?.code
                                },
                                IsDocument: false,
                                CollectionDate: this.$filters.formatDateWithFormat(this.model.request_CollectionDate ?? new Date())
                            },
                            Packages: packages
                        }
                    }
                },
                apiAccessPoint() {
                    return {
                        Request: {
                            CustomerCode: this.companyDS.data.filter(x => x.id === this?.$props.recordformData?.customerID)[0]?.code,
                            ProviderCode: this.model?.carrierCode,
                            MaxDistance: 100,
                            MaxResult: 100,
                            DistanceUnit: "MI",
                            Address: {
                                City: this.$props.recordformData?.shipTo_City,
                                State: this.$props.recordformData?.shipTo_State,
                                PostalCode: this.$props.recordformData?.shipTo_PostalCode,
                                Country: this.$vm.$countries.filter(x => x.id === this?.$props.recordformData?.shipTo_CountryID)[0]?.code
                            }
                        }
                    };
                },
                country() {
                    return this.$vm.$countries.filter(x => x.id === this.$props.formData?.shipFrom_CountryID)[0];
                },
                ReturnCarrierFilter() { return this.source.returnInfo.carrier.code ?? null },
                ReturnProductFilter() { return this.source.returnInfo.product.code ?? null },
                ShippingCarrierFilter() { return this.source.shippingInfo.carrier.code ?? null },
                ShippingProductFilter() { return this.source.shippingInfo.product.code ?? null },
                //SHIPPING INFO DROPDOWN OBJECTS
                shippingInfoServiceGroupDS() {
                    return this.source.shippingInfo.serviceGroupDS.result ?? [];
                },
                shippingInfoCarrierDS() {
                    return this.source.shippingInfo.providers.result ?? [];
                },
                shippingInfoProductDS() {
                    if (this.ShippingCarrierFilter !== null)
                        return this.source.shippingInfo.services.result.filter(x => x.CarrierCode === this.ShippingCarrierFilter)[0]?.AvailableServices ?? [];
                    let availalbleService = [];
                    (this.source.shippingInfo.services.result ?? []).forEach(x => {
                        (x.AvailableServices ?? []).forEach(av => {
                            availalbleService.push(av);
                        })
                    });
                    return availalbleService;
                },
                shipingInfoAccessPointDS() {
                    return this.source.shippingInfo.accesspointLocations ?? [];
                },
                shippingInfoOptionDS() {
                    if (this.ShippingCarrierFilter !== null && this.ShippingProductFilter)
                        return this.source.shippingInfo.services.result.filter(x => x.CarrierCode === this.ShippingCarrierFilter)[0]?.AvailableOptions ?? [];
                    return [];
                    //let availableOption = [];
                    //(this.source.shippingInfo.services.result ?? []).forEach(x => {
                    //    (x.AvailableOptions ?? []).forEach(av => {
                    //        availableOption.push(av);
                    //    })
                    //});
                    //return availableOption;
                },
                shippingInfoAlternateAddress() {
                    return {
                        companyName: this.source?.shippingInfo?.accesspoint?.alternateDelivery?.address?.companyName,
                        address1: this.source?.shippingInfo?.accesspoint?.alternateDelivery?.address?.address1,
                        address2: this.source?.shippingInfo?.accesspoint?.alternateDelivery?.address?.address2,
                        address3: this.source?.shippingInfo?.accesspoint?.alternateDelivery?.address?.address3,
                        city: this.source?.shippingInfo?.accesspoint?.alternateDelivery?.address?.city,
                        state: this.source?.shippingInfo?.accesspoint?.alternateDelivery?.address.state ?? "",
                        postalCode: this.source?.shippingInfo?.accesspoint?.alternateDelivery?.address?.postalCode,
                        countryID: this.source?.shippingInfo?.accesspoint?.alternateDelivery?.address?.countryID,
                        establishmentType: 200

                    };

                },

                // RETURN INFO DROPDWON OBJECTS
                returnInfoCarrierDS() {
                    return this.source.returnInfo.providers.result ?? [];
                },
                returnInfoProductDS() {
                    if (this.ReturnCarrierFilter !== null)
                        return this.source.returnInfo.services.result.filter(x => x.CarrierCode === this.ReturnCarrierFilter)[0]?.AvailableServices ?? [];
                    let availalbleService = [];
                    (this.source.returnInfo.services.result ?? []).forEach(x => {
                        (x.AvailableServices ?? []).forEach(av => {
                            availalbleService.push(av);
                        })
                    });
                    return availalbleService;
                },
                returnInfoAccessPointDS() {
                    return this.source.returnInfo.accesspointLocations ?? [];
                },
                returnInfoOptionDS() {
                    if (this.ReturnCarrierFilter !== null && this.ReturnProductFilter !== null)
                        return this.source.returnInfo.services.result.filter(x => x.CarrierCode === this.ReturnCarrierFilter)[0]?.AvailableOptions ?? [];
                    return [];
                    //let availableOption = [];
                    //(this.source.returnInfo.services.result ?? []).forEach(x => {
                    //    (x.AvailableOptions ?? []).forEach(av => {
                    //        availableOption.push(av);
                    //    })
                    //});
                    //return availableOption;
                },
                returnInfoAlternateAddress() {
                    return {
                        companyName: this.source?.returnInfo?.accesspoint?.alternateDelivery?.address?.companyName,
                        address1: this.source?.returnInfo?.accesspoint?.alternateDelivery?.address?.address1,
                        address2: this.source?.returnInfo?.accesspoint?.alternateDelivery?.address?.address2,
                        address3: this.source?.returnInfo?.accesspoint?.alternateDelivery?.address?.address3,
                        city: this.source?.returnInfo?.accesspoint?.alternateDelivery?.address?.city,
                        state: this.source?.returnInfo?.accesspoint?.alternateDelivery?.address.state ?? "",
                        postalCode: this.source?.returnInfo?.accesspoint?.alternateDelivery?.address?.postalCode,
                        countryID: this.source?.returnInfo?.accesspoint?.alternateDelivery?.address?.countryID,
                        establishmentType: 200
                    };

                },

                apiServiceRequest() {
                   
                    let packages = [];
                    // let _content = [];
                    (this?.$props.model?.packages ?? this?.$props.formData?.packages ??  []).forEach((_package) => {
                        packages.push({
                            Weight: _package?.weight ?? 0.00,
                            Declaration: {
                                DeclaredValue: _package?.consignmentValue ?? 0.00,
                                CustomsValue: _package?.customsValue ?? 0.00,
                                DiscountValue: _package?.discountValue ?? 0.00,
                                InsuranceValue: _package?.insuranceValue ?? 0.00,
                                DutyPaidValue: _package?.dutyPaidValue ?? 0.00,
                                TaxValue: _package?.taxValue ?? 0.00,
                            },
                            Dimension: (((_package?.length ?? 0) > 0) || ((_package?.width ?? 0) > 0) || ((_package?.height ?? 0) > 0)) ? {
                                Length: (_package?.length ?? 0),
                                Width: (_package?.width ?? 0),
                                Height: (_package?.height ?? 0)
                            } : null,
                        });
                    });
                    let senderCountry = (this.$vm.$countries.filter(x => x.id === this.model?.shipFrom_CountryID ?? -100)[0]);
                    let recipientCountry = (this.$vm.$countries.filter(x => x.id === this.model?.shipTo_CountryID ?? -100)[0]);
                    return {
                        Source: "WEB",
                        Request: {
                            Customer: (this.customer ?? this.scopeCustomerDS.filter(x => x.id === this.model?.customerID)[0])?.code,//  this.companyDS.data.filter(x => x.id === this?.model?.customerID)[0]?.code,
                            Warehouse: this.warehouse?.code,
                            ShippingType: "SHIPPING",
                            Consignment: {
                                Order: {
                                    Sender: {
                                        City: this.model?.shipFrom_City ?? "",
                                        State: (senderCountry?.stateRequired ?? false) ? ((senderCountry?.states ?? []).filter(x => x.name.toLowerCase().replace(/^a-zA-Z0-9 ]/g, '') == (this.model?.shipFrom_State ?? "").toLowerCase().replace(/^a-zA-Z0-9 ]/g, ''))[0]?.code ?? (this.model?.shipFrom_State ?? "")): (this.model?.shipFrom_State ?? ""),
                                        PostalCode: this.model.shipFrom_PostalCode ?? "",
                                        Country: this.$vm.$countries.filter(x => x.id === this.model?.shipFrom_CountryID ?? -100)[0]?.code ?? "",
                                        EstablishmentType: this.$vm.$enums['establishmenttype'].filter(x => x.id === this.model?.shipFrom_EstablishmentType ?? -100)[0]?.code ?? "BUSINESS"
                                    },
                                    Recipient: {
                                        City: this.model?.shipTo_City ?? "",
                                        State: (recipientCountry?.stateRequired ?? false) ? ((recipientCountry?.states ?? []).filter(x => x.name.toLowerCase().replace(/^a-zA-Z0-9 ]/g, '') == (this.model?.shipTo_State ?? "").toLowerCase().replace(/^a-zA-Z0-9 ]/g, ''))[0]?.code ?? (this.model?.shipTo_State ?? "")): (this.model?.shipTo_State ?? ""),
                                        PostalCode: this.model.shipTo_PostalCode ?? "",
                                        Country: this.$vm.$countries.filter(x => x.id === this.model?.shipTo_CountryID ?? -100)[0]?.code ?? "",
                                        EstablishmentType: this.$vm.$enums['establishmenttype'].filter(x => x.id === this.model?.shipTo_EstablishmentType ?? -100)[0]?.code ?? "BUSINESS"
                                    },
                                    PackageType: this.$vm.$enums['serviceproviderproductpackagingtype'].filter(x => x.id === this.model?.packageType ?? -100)[0]?.code ?? "PACKAGE",
                                    CollectionDate: this.$filters.formatDateWithFormat(this.model?.request_CollectionDate ?? new Date(), "YYYY-MM-DD"),
                                    WeightUnit: this.$vm.$enums['weightunit'].filter(x => x.id === this.model?.weightUnit ?? -100)[0]?.code ?? "KG",
                                    DimensionUnit: this.$vm.$enums['lengthunit'].filter(x => x.id === this.model?.dimensionUnit ?? -100)[0]?.code ?? "CM",
                                    Currency: this.$vm.$currencies.filter(x => x.id === this.model?.currencyID ?? -100)[0]?.code ?? "GBP",
                                },
                                Packages: packages
                            },
                            Provider: {
                                Carrier: null,
                                Service: null
                            }
                        }
                    }
                },
                apiProviderRequest() {
                    return {
                        Source: "WEB",
                        Request: {
                            Customer: this.scopeCustomerDS.filter(x => x.id === this.model?.customerID)[0]?.code,
                            Warehouse: this.warehouse?.code,
                            ShippingType: "SHIPPING",
                            TradeType: this.$vm.$enums['tradetype'].filter(x => x.id === this.model?.tradeType ?? -100)[0]?.code ?? "EXPORT"
                        }
                    }
                },
                apiAccessPointRequest() {
                    let recipientCountry = (this.$vm.$countries.filter(x => x.id === this.model?.shipTo_CountryID ?? -100)[0]);
                    return {
                        Request: {
                            Customer: this.scopeCustomerDS.filter(x => x.id === this.model?.customerID)[0]?.code,
                            Carrier: "",
                            Address: {
                                Company: this.model?.shipTo_Company ?? "",
                                Address1: this.model?.shipTo_Address1 ?? "",
                                Address2: this.model?.shipTo_Address2 ?? "",
                                Address3: this.model?.shipTo_Address3 ?? "",
                                City: this.model?.shipTo_City ?? "",
                                //State: this.model?.shipTo_State ?? "",
                                State: (recipientCountry?.stateRequired ?? false) ? ((recipientCountry?.states ?? []).filter(x => x.name.toLowerCase().replace(/^a-zA-Z0-9 ]/g, '') == (this.model?.shipTo_State ?? "").toLowerCase().replace(/^a-zA-Z0-9 ]/g, ''))[0]?.code ?? (this.model?.shipTo_State ?? "")) : (this.model?.shipTo_State ?? ""),
                                PostalCode: this.model?.shipTo_PostalCode ?? "",
                                Country: this.DestinationISO2 ?? ""

                            },
                            MaxDistance: 50,
                            DistanceUnit: "KM",
                            MaxResult: 100
                        }
                    }
                }
            },
            methods: {
                ...method, ...crud,
                getSettingSource(mask) {
                    if ((mask ?? this.customer?.mask) !== undefined && (mask ?? this.customer?.mask) !== null) {
                        let isProcessing = false;
                        isProcessing = (this.processing.companyInfo.generalSetting ?? false);
                        if (!isProcessing) {
                            this.processing.companyInfo.generalSetting = true;
                            this.$nextTick(() => {
                                this.$axios
                                    .get(this.$config.config.endpoint.api + `/services/settings/companygeneralsetting/ByCompany/${(mask ?? this?.customer?.mask)}`.replaceAll("//", "/"))
                                    .then((response) => {
                                        this.source.companyInfo.generalSetting = response?.data?.result ?? response?.result ?? null;
                                        this.processing.companyInfo.generalSetting = false;
                                        this.manageDefaultSetting();
                                    })
                                    .catch((ex) => {
                                        this.errors = ex.data;
                                        this.processing.companyInfo.generalSetting = false;
                                    });
                            });
                        }
                    }
                },
                getAccountCustomerSource() {
                    let isProcessing = false;
                    isProcessing = (this.processing.accountCustomer ?? false);
                    if (!isProcessing) {
                        this.$nextTick(() => {
                            if ((this.customer?.id ?? 0) != 0) {
                                this.processing.accountCustomer = true;
                                this.$axios.get(this.$config.config.endpoint.api + `/services/admin/serviceprovideraccountcustomer/handlerbyaccount/id/${this.customer?.id}`.replaceAll("//", "/")).then((result) => {
                                    this.source.companyInfo.accountCustomer = result?.data?.result ?? result?.result ?? [];
                                    this.processing.accountCustomer = false;
                                });
                            }
                            else {
                                this.processing.accountCustomer = false;
                            }
                        });
                    }
                },
                onCollectionDate(e) {
                    //console.log(this.model.request_CollectionDateValue);
                    //console.log(e.value);
                    //console.log(e);
                    //if (e !== null && e?.value !== null) {
                    //    //this.$nextTick(() => {
                    //    //    this.getConsignmentService(false);
                    //    //    if (!(this.model.isReturn ?? false))
                    //    //        this.getConsignmentService(true);
                    //    //});
                    //}
                    this.model.request_CollectionDateValue = e.value;
                    this.model.request_CollectionDate = (e.record.data ?? new Date());
                    
                    
                },
                onReadyTime(e) {
                    this.model.request_ReadyTimeValue = e.value;
                    this.model.request_ReadyTime = (e.record.data ?? new Date());
                },
                onClosingTime(e) {
                    this.model.request_ClosingTimeValue = e.value;
                    this.model.request_ClosingTime = (e.record.data ?? new Date());
                },
                rebindAllocationCarriers() {
                    this.source.shippingInfo.providers.isBinded = false;
                    this.source.returnInfo.providers.isBinded = false;
                    this.$nextTick(() => {
                        if (this?.model?.request_CollectionDate !== null) {
                            this.$nextTick(() => {
                                this.getConsignmentProvider(false).then(() => {
                                    this.$nextTick(() => {
                                        if (this.source.shippingInfo.carrier.code != null) {
                                            this.getConsignmentService(false).then(() => {
                                                this.source.shippingInfo.services.isBinded = true;
                                            });
                                        }
                                    });
                                });
                            });
                            this.$nextTick(() => {
                                if ((this.model.isReturnable ?? false))
                                    this.$nextTick(() => {
                                        this.getConsignmentProvider(true).then(() => {
                                            this.$nextTick(() => {
                                                if (this.source.returnInfo.carrier.code != null) {
                                                    this.getConsignmentService(true).then(() => {
                                                        this.source.returnInfo.services.isBinded = true;
                                                    });
                                                }
                                            });
                                        });
                                    });
                            });
                        }
                    });
                },
                async getServiceGroup() {
                    let isProcessing = false;
                    isProcessing = (this.processing.shippingInfo.servicegroup ?? false);
                    if (!isProcessing) {
                        this.processing.shippingInfo.servicegroup = true;
                        this.$nextTick(() => {
                            if (this.model?.customerID) {                                
                                this.$axios.get(this.$config.config.endpoint.api + `/services/facility/servicegroup/customer/id/${this.model?.customerID}`.replaceAll("//", "/")).then((result) => {
                                    this.source.shippingInfo.serviceGroupDS.result = result?.data?.result ?? [];
                                    this.processing.shippingInfo.servicegroup = false;
                                    this.$nextTick(() => {
                                        if ((this.companySettingRecord?.id ?? 0) == 0)
                                            this.getSettingSource()
                                        this.manageDefaultSetting();
                                    });
                                    
                                });
                            }
                            else {
                                this.processing.shippingInfo.servicegroup = false;
                            }
                        });
                    }
                },
                async getConsignmentProvider(isReturn) {
                    let isProcessing = false;
                    isProcessing = !isReturn ? (this.processing.shippingInfo.provider ?? false) : (this.processing.returnInfo.provider ?? false);
                    if (!isProcessing) {
                        if (!isReturn) {
                            this.source.shippingInfo.services.result = [];
                            this.processing.shippingInfo.provider = true;
                        }
                        else {
                            this.source.returnInfo.services.result = [];
                            this.processing.returnInfo.provider = true;
                        }
                        this.$nextTick(() => {
                            if (isReturn === null || isReturn === undefined)
                                isReturn = false
                            let apiRequest = this.apiServiceRequest;
                            if ((apiRequest?.Request?.Consignment?.Packages?.length ?? 0) > 0) {
                                let shippingType = isReturn ? 'RETURNS' : 'SHIPPING';
                                let apiRequest = this.apiProviderRequest;
                                apiRequest.Request.ShippingType = shippingType;
                                this.$axios.post(this.$config.config.endpoint.api + `/api/polaris/consignment/serviceprovider`.replaceAll("//", "/"), apiRequest).then((result) => {
                                    var warningErrors = "";
                                    if (result.data.Errors !== null && result.data.Errors !== undefined) {
                                        (result.data.Errors ?? []).forEach((x) => {
                                            warningErrors += `<b>${x.Source} ${x.Severity}</b>: ${x.Message}<br/>`;
                                        });
                                    }
                                    if (result.data.Faults !== null && result.data.Faults !== undefined) {
                                        (result.data.Faults ?? []).forEach((x) => {
                                            warningErrors += `<b>${x.Source} ${x.Severity}</b>: ${x.Message}<br/>`;
                                        });
                                    }

                                    if ((warningErrors ?? "").length > 0) {
                                        this.$toast.add({ severity: 'error', summary: (isReturn ? 'Return ' : '') + 'Carrier Failed!', detail: warningErrors, life: 30000 });
                                    }


                                    if (!isReturn) {
                                        if ((result?.data?.Response?.Customers ?? []).length > 0) {
                                            let carrierIndex = 0;
                                            let availServiceIndex = 0;
                                            let availOptionIndex = 0;
                                            let customer = result?.data?.Response?.Customers[0];
                                            let serviceResult = customer?.Carriers ?? [];
                                            (serviceResult ?? []).forEach(x => {
                                                carrierIndex++;
                                                x.Id = carrierIndex;
                                                x.CarrierCode = x.Code,
                                                    x.CarrierName = x.Name
                                                x.Text = '[' + x.Code + '] ' + x.Name;
                                                (x.AvailableServices ?? []).forEach(av => {
                                                    availServiceIndex++;
                                                    av.Id = availServiceIndex;
                                                    av.Text = '[' + av.Code + '] ' + av.Name;
                                                    av.ParentID = x.Id;
                                                    av.ParentText = x.Text;
                                                    av.ParentCode = x.CarrierCode;
                                                    av.ParentText = x.Text;
                                                    av.Logo = x.Logo;

                                                });
                                                (x.AvailableOptions ?? []).forEach(av => {
                                                    availOptionIndex++;
                                                    av.Id = availOptionIndex;
                                                    av.Text = '[' + av.Code + '] ' + av.Name;
                                                    av.ParentID = x.Id;
                                                    av.ParentText = x.Text;
                                                    av.ParentCode = x.CarrierCode;
                                                    av.ParentText = x.Text;
                                                });
                                            });
                                            this.source.shippingInfo.providers.result = serviceResult;
                                            this.$nextTick(() => {
                                                if ((this.companySettingRecord?.id ?? 0) == 0)
                                                    this.getSettingSource()
                                                if ((this.companySettingRecordSource?.id ?? 0) != 0) {
                                                    let defaultProvider = (this.source.companyInfo.accountCustomer ?? []).filter(x => x.id == this.companySettingRecord.default_AccountCustomerID)[0];
                                                    if (defaultProvider != null && defaultProvider != undefined) {
                                                        this.source.shippingInfo.carrier.serviceProviderAccountID = (this.source.shippingInfo.providers.result ?? []).filter(x => x.CarrierCode == defaultProvider.code)[0]?.Id ?? null;
                                                    }
                                                }
                                            });
                                        }
                                    }
                                    else {
                                        if ((result?.data?.Response?.Customers ?? []).length > 0) {
                                            let carrierIndex = 0;
                                            let availServiceIndex = 0;
                                            let availOptionIndex = 0;
                                            let customer = result?.data?.Response?.Customers[0];
                                            let serviceResult = customer?.Carriers ?? [];
                                            (serviceResult ?? []).forEach(x => {

                                                carrierIndex++;
                                                x.Id = carrierIndex;
                                                x.CarrierCode = x.Code,
                                                    x.CarrierName = x.Name
                                                x.Text = '[' + x.Code + '] ' + x.Name;
                                                (x.AvailableServices ?? []).forEach(av => {
                                                    availServiceIndex++;
                                                    av.Id = availServiceIndex;
                                                    av.Text = '[' + av.Code + '] ' + av.Name;
                                                    av.ParentID = x.Id;
                                                    av.ParentText = x.Text;
                                                    av.ParentCode = x.CarrierCode;
                                                    av.ParentText = x.Text;
                                                    av.Logo = x.Logo;
                                                });
                                                (x.AvailableOptions ?? []).forEach(av => {
                                                    availOptionIndex++;
                                                    av.Id = availOptionIndex;
                                                    av.Text = av.Name;
                                                    av.ParentID = x.Id;
                                                    av.ParentText = x.Text;
                                                    av.ParentCode = x.CarrierCode;
                                                    av.ParentText = x.Text;
                                                });
                                            });
                                            this.source.returnInfo.providers.result = serviceResult;
                                        }
                                    }

                                    if (!isReturn) {
                                        this.processing.shippingInfo.provider = false;
                                    }
                                    else {

                                        this.processing.returnInfo.provider = false;
                                    }
                                })
                                    .catch(err => {
                                        console.log(err);
                                        if (!isReturn) {
                                            this.processing.shippingInfo.provider = false;
                                        }
                                        else {

                                            this.processing.returnInfo.provider = false;
                                        }
                                    });
                            }
                            else {
                                if (!isReturn) {
                                    this.processing.shippingInfo.provider = false;
                                }
                                else {

                                    this.processing.returnInfo.provider = false;
                                }
                            }
                        });
                    }
                },
                async getConsignmentService(isReturn) {
                    let isProcessing = false;
                    isProcessing = !isReturn ? (this.processing.shippingInfo.product ?? false) : (this.processing.returnInfo.product ?? false);
                    if (!isProcessing) {
                        if (!isReturn) {
                            this.processing.shippingInfo.product = true;
                            this.source.shippingInfo.services.result = [];
                        }
                        else {
                            this.source.returnInfo.services.result = [];
                            this.processing.returnInfo.product = true;
                        }
                        this.$nextTick(() => {
                            if (isReturn === null || isReturn === undefined)
                                isReturn = false
                            let apiRequest = this.apiServiceRequest;
                            if ((apiRequest?.Request?.Consignment?.Packages?.length ?? 0) > 0) {
                                let shippingType = isReturn ? 'RETURNS' : 'SHIPPING';
                                let apiRequest = this.apiServiceRequest;
                                apiRequest.Request.ShippingType = shippingType;
                                apiRequest.Request.Provider.Carrier = (isReturn ? this.ReturnCarrierFilter : this.ShippingCarrierFilter) ?? null;
                                if (apiRequest.Request.Provider.Carrier) {
                                    this.$axios.post(this.$config.config.endpoint.api + `/api/polaris/consignment/service?additionalinfo=quote`.replaceAll("//", "/"), apiRequest).then((result) => {
                                        var warningErrors = "";
                                        if (result.data.Errors !== null && result.data.Errors !== undefined) {
                                            (result.data.Errors ?? []).forEach((x) => {
                                                warningErrors += `<b>${x.Source} ${x.Severity}</b>: ${x.Message}<br/>`;
                                            });
                                        }
                                        if (result.data.Faults !== null && result.data.Faults !== undefined) {
                                            (result.data.Faults ?? []).forEach((x) => {
                                                warningErrors += `<b>${x.Source} ${x.Severity}</b>: ${x.Message}<br/>`;
                                            });
                                        }

                                        if ((warningErrors ?? "").length > 0) {
                                            this.$toast.add({ severity: 'error', summary: (isReturn ? 'Return ' : '') + 'Service Failed!', detail: warningErrors, life: 30000 });
                                        }

                                        if (!isReturn) {
                                            if ((result?.data?.Response?.Data ?? []).length > 0) {
                                                let carrierIndex = 0;
                                                let availServiceIndex = 0;
                                                let availOptionIndex = 0;
                                                let serviceResult = result.data?.Response?.Data ?? [];
                                                //this.source.shippingInfo.services.result = result.data.Response.Data;
                                                (serviceResult ?? []).forEach(x => {
                                                    carrierIndex++;
                                                    x.Id = carrierIndex;
                                                    x.Text = '[' + x.CarrierCode + '] ' + x.CarrierName;
                                                    (x.AvailableServices ?? []).forEach(av => {
                                                        availServiceIndex++;
                                                        av.Id = availServiceIndex;
                                                        av.Text = '[' + av.Code + '] ' + av.Name;
                                                        av.ParentID = x.Id;
                                                        av.ParentText = x.Text;
                                                        av.ParentCode = x.CarrierCode;
                                                        av.ParentText = x.Text;
                                                        av.Logo = x.Logo;

                                                    });
                                                    (x.AvailableOptions ?? []).forEach(av => {
                                                        availOptionIndex++;
                                                        av.Id = availOptionIndex;
                                                        av.Text = '[' + av.Code + '] ' + av.Name;
                                                        av.ParentID = x.Id;
                                                        av.ParentText = x.Text;
                                                        av.ParentCode = x.CarrierCode;
                                                        av.ParentText = x.Text;
                                                    });
                                                });
                                                this.source.shippingInfo.services.result = serviceResult;
                                            }
                                        }
                                        else {
                                            if ((result?.data?.Response?.Data ?? []).length > 0) {
                                                let carrierIndex = 0;
                                                let availServiceIndex = 0;
                                                let availOptionIndex = 0;
                                                let serviceResult = result.data?.Response?.Data ?? [];
                                                //this.source.returnInfo.services.result = result.data.Response.Data;
                                                (serviceResult ?? []).forEach(x => {
                                                    carrierIndex++;
                                                    x.Id = carrierIndex;
                                                    x.Text = '[' + x.CarrierCode + '] ' + x.CarrierName;
                                                    (x.AvailableServices ?? []).forEach(av => {
                                                        availServiceIndex++;
                                                        av.Id = availServiceIndex;
                                                        av.Text = '[' + av.Code + '] ' + av.Name;
                                                        av.ParentID = x.Id;
                                                        av.ParentText = x.Text;
                                                        av.ParentCode = x.CarrierCode;
                                                        av.ParentText = x.Text;
                                                        av.Logo = x.Logo;

                                                    });
                                                    (x.AvailableOptions ?? []).forEach(av => {
                                                        availOptionIndex++;
                                                        av.Id = availOptionIndex;
                                                        av.Text = av.Name;
                                                        av.ParentID = x.Id;
                                                        av.ParentText = x.Text;
                                                        av.ParentCode = x.CarrierCode;
                                                        av.ParentText = x.Text;
                                                    });
                                                });
                                                this.source.returnInfo.services.result = serviceResult;
                                            }
                                        }
                                        if (!isReturn) {
                                            this.processing.shippingInfo.product = false;
                                        }
                                        else {

                                            this.processing.returnInfo.product = false;
                                        }
                                    })
                                        .catch(() => {
                                            if (!isReturn) {
                                                this.processing.shippingInfo.product = false;
                                            }
                                            else {

                                                this.processing.returnInfo.product = false;
                                            }
                                        });
                                }
                                else {
                                    if (!isReturn) {
                                        this.processing.shippingInfo.product = false;
                                    }
                                    else {

                                        this.processing.returnInfo.product = false;
                                    }
                                }

                            }
                            else {
                                if (!isReturn) {
                                    this.processing.shippingInfo.product = false;
                                }
                                else {

                                    this.processing.returnInfo.product = false;
                                }
                            }
                        });
                    }
                },
                async getAccessPoints(isReturn) {
                    if (isReturn === null || isReturn === undefined)
                        isReturn = false
                    let isProcessing = false;
                    isProcessing = !isReturn ? (this.processing.shippingInfo.accesspoint ?? false) : (this.processing.returnInfo.accesspoint ?? false);
                    if (!isProcessing) {
                        let isAllowAccessPoint = false;
                        if (!isReturn) {
                            this.processing.shippingInfo.accesspoint = true;
                            isAllowAccessPoint = (this.source?.shippingInfo?.product?.configuration?.AllowAccessPoint ?? false);
                        }
                        else {
                            this.processing.returnInfo.accesspoint = true;
                            isAllowAccessPoint = (this.source?.returnInfo?.product?.configuration?.AllowAccessPoint ?? false);
                        }
                        if (isAllowAccessPoint) {
                            this.$nextTick(() => {
                                let apiRequest = this.apiAccessPointRequest;
                                if ((this.apiServiceRequest?.Request?.Consignment?.Packages?.length ?? 0) > 0) {
                                    apiRequest.Request.Carrier = (!isReturn ? this.source.shippingInfo?.carrier?.code : this.source.returnInfo?.carrier?.code);
                                    if (apiRequest.Request.Carrier) {
                                        this.$axios.post(this.$config.config.endpoint.api + `/api/polaris/tools/accesspoint`.replaceAll("//", "/"), apiRequest).then((result) => {
                                            if (!isReturn) {
                                                if ((result?.data?.Response ?? []).length > 0) {
                                                    this.source.shippingInfo.accesspointLocations = result.data?.Response ?? [];
                                                }
                                            }
                                            else {
                                                if ((result?.data?.Response ?? []).length > 0) {
                                                    this.source.returnInfo.accesspointLocations = result.data?.Response ?? [];
                                                }
                                            }

                                            if (!isReturn) {
                                                this.processing.shippingInfo.accesspoint = false;
                                            }
                                            else {

                                                this.processing.returnInfo.accesspoint = false;
                                            }

                                        })
                                            .catch(() => {
                                                if (!isReturn) {
                                                    this.processing.shippingInfo.accesspoint = false;
                                                }
                                                else {

                                                    this.processing.returnInfo.accesspoint = false;
                                                }

                                            });
                                    }
                                    else {
                                        if (!isReturn) {
                                            this.processing.shippingInfo.accesspoint = false;
                                        }
                                        else {

                                            this.processing.returnInfo.accesspoint = false;
                                        }
                                    }
                                }
                                else {
                                    if (!isReturn) {
                                        this.processing.shippingInfo.accesspoint = false;
                                    }
                                    else {

                                        this.processing.returnInfo.accesspoint = false;
                                    }

                                }
                            });
                        }   
                    }
                },
                onSelectServiceGroup(event, isReturn) {
                    if (isReturn === null || isReturn === undefined)
                        isReturn = false

                    if (!isReturn) {
                        this.source.shippingInfo.serviceGroup.serviceGroupID = event?.value;
                        if (event?.value) {
                            this.source.shippingInfo.serviceGroup.code = event?.record?.code ?? null;
                            this.source.shippingInfo.serviceGroup.text = event?.record?.code ?? null;
                        }
                        else {
                            this.source.shippingInfo.serviceGroup.code = null;
                            this.source.shippingInfo.serviceGroup.text = null;
                        }

                    }
                    else {
                        this.source.returnInfo.serviceGroup.serviceGroupID = event?.value;
                        if (event?.value) {
                            this.source.returnInfo.serviceGroup.code = event?.record?.code ?? null;
                            this.source.returnInfo.serviceGroup.text = event?.record?.code ?? null;
                        }
                        else {
                            this.source.returnInfo.serviceGroup.code = null;
                            this.source.returnInfo.serviceGroup.text = null;
                        }
                    }
                    this.manageAllocationInfo();
                },
                onSelectCarrier(event, isReturn) {
                    if (isReturn === null || isReturn === undefined)
                        isReturn = false

                    if (!isReturn) {
                        this.source.shippingInfo.carrier.serviceProviderAccountID = event?.value;
                        if (event?.value) {
                            this.source.shippingInfo.carrier.code = event?.record?.CarrierCode ?? null;
                            this.source.shippingInfo.carrier.text = event?.record?.Text ?? null;
                        }
                        else {
                            this.source.shippingInfo.carrier.code = null;
                            this.source.shippingInfo.carrier.text = null;

                        }
                    }
                    else {
                        this.source.returnInfo.carrier.serviceProviderAccountID = event?.value;
                        if (event?.value) {
                            this.source.returnInfo.carrier.code = event?.record?.CarrierCode ?? null;
                            this.source.returnInfo.carrier.text = event?.record?.Text ?? null;
                        }
                        else {
                            this.source.returnInfo.carrier.code = null;
                            this.source.returnInfo.carrier.text = null;
                        }
                    }
                    this.manageAllocationInfo();
                },
                onSelectProduct(event, isReturn) {
                    if (isReturn === null || isReturn === undefined)
                        isReturn = false
                    if (!isReturn) {
                        if (event?.value !== null && event?.value !== undefined) {
                            this.source.shippingInfo.product.serviceProviderProductAccountID = event.value;
                            if (this.source.shippingInfo.carrier.serviceProviderAccountID === null) {
                                this.source.shippingInfo.carrier.serviceProviderAccountID = event.record.ParentID;
                                this.source.shippingInfo.carrier.code = event.record.ParentCode;
                                this.source.shippingInfo.carrier.text = event.record.ParentText;
                            }
                            this.source.shippingInfo.product.code = event.record?.Code ?? null;
                            this.source.shippingInfo.product.text = event.record?.Text ?? null;
                            this.source.shippingInfo.product.configuration = event.record?.Configuration ?? null;
                            // this.source.shippingInfo
                            //this.source.shippingInfo.product.apiCode = event.record.APICode;

                        }
                        else {
                            this.source.shippingInfo.product.serviceProviderProductAccountID = null;
                            if (this.source.shippingInfo.carrier.serviceProviderAccountID == null) {
                                this.source.shippingInfo.carrier.code = null;
                                this.source.shippingInfo.carrier.text = null;
                            }
                            this.source.shippingInfo.product.code = null;
                            this.source.shippingInfo.product.text = null;
                            this.source.shippingInfo.product.configuration = null;

                            this.source.shippingInfo.option.serviceProviderOptionAccountID = null;
                            this.source.shippingInfo.option.code = null;
                            this.source.shippingInfo.option.text = null;

                        }
                    }
                    else {
                        if (event?.value !== null && event?.value !== undefined) {
                            this.source.returnInfo.product.serviceProviderProductAccountID = event.value;
                            if (this.source.returnInfo.carrier.serviceProviderAccountID === null) {
                                this.source.returnInfo.carrier.serviceProviderAccountID = event.record.ParentID;
                                this.source.returnInfo.carrier.code = event.record.ParentCode;
                                this.source.returnInfo.carrier.text = event.record.ParentText;
                            }
                            this.source.returnInfo.product.code = event.record?.Code ?? null;
                            this.source.returnInfo.product.text = event.record?.Text ?? null;
                            this.source.returnInfo.product.configuration = event.record?.Configuration ?? null;
                            this.source.returnInfo.product.apiCode = event.record.APICode;

                        }
                        else {
                            this.source.returnInfo.product.serviceProviderProductAccountID = null;
                            if (this.source.returnInfo.carrier.serviceProviderAccountID == null) {
                                this.source.returnInfo.carrier.code = null;
                                this.source.returnInfo.carrier.text = null;
                            }
                            this.source.returnInfo.product.code = null;
                            this.source.returnInfo.product.text = null;
                            this.source.returnInfo.product.configuration = null;
                            this.source.returnInfo.product.apiCode = null;

                            this.source.returnInfo.option.serviceProviderOptionAccountID = null;
                            this.source.returnInfo.option.code = null;
                            this.source.returnInfo.option.text = null;

                        }
                    }
                    //this.$nextTick(() => {
                    //    if ((event?.value !== null && event?.value !== undefined)) {
                    //        console.log('access points');
                    //        this.getAccessPoints(isReturn);
                    //    }
                        
                    //});
                    this.manageAllocationInfo();
                },
                onSelectOption(event, isReturn) {
                    if (isReturn === null || isReturn === undefined)
                        isReturn = false
                    if (!isReturn) {
                        this.source.shippingInfo.option.serviceProviderOptionAccountID = event?.value;
                        if (event?.value !== null && event?.value !== undefined) {
                            this.source.shippingInfo.option.code = event?.record?.Code ?? null;
                            this.source.shippingInfo.option.text = event?.record?.Text ?? null;
                        }
                        else {
                            this.source.shippingInfo.option.serviceProviderOptionAccountID = null;
                            this.source.shippingInfo.option.code = null;
                            this.source.shippingInfo.option.text = null;
                        }
                    }
                    else {
                        this.source.returnInfo.option.serviceProviderOptionAccountID = event?.value;
                        if (event?.value !== null && event?.value !== undefined) {
                            this.source.returnInfo.option.code = event?.record?.Code ?? null;
                            this.source.returnInfo.option.text = event?.record?.Text ?? null;
                        }
                        else {
                            this.source.returnInfo.option.serviceProviderOptionAccountID = null;
                            this.source.returnInfo.option.code = null;
                            this.source.returnInfo.option.text = null;
                        }
                    }
                    this.manageAllocationInfo();
                },
                async onSelectAccessPoint(event, isReturn) {
                    if (isReturn === null || isReturn === undefined)
                        isReturn = false
                    if (!isReturn) {
                        this.source.shippingInfo.accesspoint.code = event?.value;
                        if (event?.value) {
                            this.source.shippingInfo.accesspoint.code = event?.record?.Code ?? null;
                            this.source.shippingInfo.accesspoint.text = event?.record?.FullDescription ?? null;
                            this.source.shippingInfo.accesspoint.record = event?.record ?? null;
                        }
                        else {
                            this.source.shippingInfo.accesspoint.code = null;
                            this.source.shippingInfo.accesspoint.text = null;
                            this.source.shippingInfo.accesspoint.record = null;
                        }
                    }
                    else {
                        this.source.returnInfo.accesspoint.code = event?.value;
                        if (event?.value) {
                            this.source.returnInfo.accesspoint.code = event?.record?.Code ?? null;
                            this.source.returnInfo.accesspoint.text = event?.record?.FullDescription ?? null;
                            this.source.returnInfo.accesspoint.record = event?.record ?? null;
                        }
                        else {
                            this.source.returnInfo.accesspoint.code = null;
                            this.source.returnInfo.accesspoint.text = null;
                            this.source.returnInfo.accesspoint.record = null;
                        }
                    }
                    this.manageAllocationInfo();
                },
                async onPopulateAlternateAddress(event, isReturn) {
                    if (isReturn === null || isReturn === undefined)
                        isReturn = false
                    if (!isReturn) {
                        this.source.shippingInfo.accesspoint.alternateDelivery.address.address1 = event.address1;
                        this.source.shippingInfo.accesspoint.alternateDelivery.address.address2 = event.address2;
                        this.source.shippingInfo.accesspoint.alternateDelivery.address.address3 = event.address3;
                        this.source.shippingInfo.accesspoint.alternateDelivery.address.city = event.city;
                        this.source.shippingInfo.accesspoint.alternateDelivery.address.state = event.state;
                        this.source.shippingInfo.accesspoint.alternateDelivery.address.postalCode = event.postalCode;
                        this.source.shippingInfo.accesspoint.alternateDelivery.address.countryID = event.countryID;
                        this.source.shippingInfo.accesspoint.alternateDelivery.address.companyName = event.companyName;
                        this.source.shippingInfo.accesspoint.alternateDelivery.address.establishmentType = event.establishmentType;
                        this.source.shippingInfo.accesspoint.alternateDelivery.address.fullDescription = event.address1 + (event.address2 ? (', ' + event.address2) : '') + (event.address3 ? (', ' + event.address3) : '') + (event.city ? (', ' + event.city) : '') + (event.state ? (', ' + event.state) : '') + (event.postalCode ? (', ' + event.postalCode) : '') + (event.countryID ? (', ' + this.$vm.$countries.filter(x => x.id === event.countryID)[0]?.text) : '')
                    }
                    else {
                        this.source.returnInfo.accesspoint.alternateDelivery.address.address1 = event.address1;
                        this.source.returnInfo.accesspoint.alternateDelivery.address.address2 = event.address2;
                        this.source.returnInfo.accesspoint.alternateDelivery.address.address3 = event.address3;
                        this.source.returnInfo.accesspoint.alternateDelivery.address.city = event.city;
                        this.source.returnInfo.accesspoint.alternateDelivery.address.state = event.state;
                        this.source.returnInfo.accesspoint.alternateDelivery.address.postalCode = event.postalCode;
                        this.source.returnInfo.accesspoint.alternateDelivery.address.countryID = event.countryID;
                        this.source.returnInfo.accesspoint.alternateDelivery.address.companyName = event.companyName;
                        this.source.returnInfo.accesspoint.alternateDelivery.address.establishmentType = event.establishmentType;
                        this.source.returnInfo.accesspoint.alternateDelivery.address.fullDescription = event.address1 + (event.address2 ? (', ' + event.address2) : '') + (event.address3 ? (', ' + event.address3) : '') + (event.city ? (', ' + event.city) : '') + (event.state ? (', ' + event.state) : '') + (event.postalCode ? (', ' + event.postalCode) : '') + (event.countryID ? (', ' + this.$vm.$countries.filter(x => x.id === event.countryID)[0]?.text) : '')
                    }
                },
                manageDefaultSetting() {
                    this.$nextTick(() => {
                        let _setting = this.companySettingRecordSource ?? this.companySettingRecord ?? null;
                        if ((_setting?.id ?? 0) != 0) {
                            if (this.source.shippingInfo.serviceGroup.serviceGroupID == null)
                                this.source.shippingInfo.serviceGroup.serviceGroupID = _setting.default_ServiceGroupID;
                            //this.source.shippingInfo.serviceGroup.serviceGroupID = _setting.default_ServiceGroupID;
                            //this.model.serviceGroupID = _setting.default_ServiceGroupID;
                            //this.model.serviceProviderAccountID = _setting.default_AccountCustomerID;
                        }
                    });

                },
                manageAllocationInfo() {
                    this.$nextTick(() => {
                        setTimeout(() => {
                            let isValidShippingInfo = ((((this.source?.shippingInfo.carrier?.serviceProviderAccountID ?? 0) == 0) ? ((this.source?.shippingInfo?.serviceGroup.serviceGroupID ?? 0) > 0) : false) ||
                                ((this.source?.shippingInfo.carrier?.serviceProviderAccountID ?? 0) > 0 ? (((this.source?.shippingInfo.carrier?.serviceProviderAccountID ?? 0) > 0) && ((this.source?.shippingInfo?.product?.serviceProviderProductAccountID ?? 0) > 0) && ((this.source?.shippingInfo?.product?.configuration?.RequireServiceOption ?? false) ? ((this.source?.shippingInfo.option?.serviceProviderOptionAccountID ?? 0) > 0) : true) && ((this.source?.shippingInfo?.product?.configuration?.RequireAdditionalCode ?? false) ? ((this.source?.shippingInfo.additional?.code ?? "").length > 0) : true)) : false));
                            let isValidReturnInfo = ((this.model?.isReturnable ?? false) ?
                                ((((this.source?.returnInfo.carrier?.serviceProviderAccountID ?? 0) > 0) ? (((this.source?.returnInfo.carrier?.serviceProviderAccountID ?? 0) > 0) && ((this.source?.returnInfo?.product?.serviceProviderProductAccountID ?? 0) > 0) && ((this.source?.returnInfo?.product?.configuration?.RequireServiceOption ?? false) ? ((this.source?.returnInfo.option?.serviceProviderOptionAccountID ?? 0) > 0) : true) && ((this.source?.returnInfo?.product?.configuration?.RequireAdditionalCode ?? false) ? ((this.source?.returnInfo?.additional?.code ?? "").length > 0) : true)) : true))
                                : true);
                            this.model.allocationDetail = {
                                isValid: (isValidReturnInfo && isValidShippingInfo),
                                isValidReturnInfo: isValidReturnInfo,
                                isValidShippingInfo: isValidShippingInfo,
                                shippingInfo: {
                                    serviceGroup: this.source?.shippingInfo?.serviceGroup,
                                    carrier: this.source?.shippingInfo?.carrier,
                                    product: this.source?.shippingInfo?.product,
                                    option: this.source?.shippingInfo?.option,
                                    customization: this.source?.shippingInfo?.customization,
                                    release: this.source?.shippingInfo?.release,
                                    additional: this.source?.shippingInfo?.additional,
                                    accesspoint: this.source?.shippingInfo?.accesspoint
                                },
                                returnInfo: {
                                    serviceGroup: this?.source?.returnInfo?.serviceGroup,
                                    carrier: this.source?.returnInfo?.carrier,
                                    product: this.source?.returnInfo?.product,
                                    option: this.source?.returnInfo?.option,
                                    release: this.source?.returnInfo?.release,
                                    additional: this.source?.returnInfo?.additional,
                                    accesspoint: this.source?.returnInfo?.accesspoint
                                }
                            };
                        }, 100);
                    });    
                }
            },
            watch: {
                $route(to) {
                    if (to.name === "consignment-allocation") {
                        this.$nextTick(() => {
                            this.getAccountCustomerSource();
                            this.getSettingSource();
                            this.getServiceGroup().then(() => {
                                if (this.source.shippingInfo.serviceGroup.serviceGroupID == null)
                                    this.manageDefaultSetting();
                            });
                        });
                    }
                },
                'formData.resetProvider': {
                    handler() {
                        this.$nextTick(() => {
                            this.getAccountCustomerSource();
                            this.getSettingSource();
                            this.getServiceGroup().then(() => {
                                if (this.source.shippingInfo.serviceGroup.serviceGroupID == null)
                                    this.manageDefaultSetting();
                            });
                            if (this?.model?.request_CollectionDateValue !== null) {
                                this.source.shippingInfo.providers.isBinded = false;
                                this.source.returnInfo.providers.isBinded = false;
                                if (!(this.source.shippingInfo.providers?.isBinded ?? false))
                                    this.getConsignmentProvider(false).then(() => {
                                        if ((this.model.packages?.length ?? 0) > 0)
                                            this.source.shippingInfo.providers.isBinded = true;
                                    });
                              
                                if (!(this.source.returnInfo.providers?.isBinded ?? false)) {
                                    if (this.model.isReturnable) {
                                        this.getConsignmentProvider(true).then(() => {
                                            if ((this.model.packages?.length ?? 0) > 0)
                                                this.source.returnInfo.providers.isBinded = true;
                                        });
                                    }

                                }
                            }
                        });
                    }, deep: true
                },
                'formData.request_CollectionDateValue': {
                    handler() {
                        this.$nextTick(() => {
                            this.getAccountCustomerSource();
                            this.getSettingSource();
                            this.getServiceGroup().then(() => {
                                if (this.source.shippingInfo.serviceGroup.serviceGroupID == null)
                                    this.manageDefaultSetting();
                            });
                            if (this?.model?.request_CollectionDateValue !== null) {
                                this.rebindAllocationCarriers();
                                    
                            }
                        });
                    }, deep: true
                },
                'formData.isReturnable': {
                    handler() {
                        this.rebindAllocationCarriers();
                    }, deep: true
                },
                'source.returnInfo.carrier.code': {
                    handler() {
                        this.$nextTick(() => {
                            if (this.source.returnInfo.carrier.code != null) {
                                this.getConsignmentService(true).then(() => {
                                        this.source.returnInfo.services.isBinded = true;
                                });
                                
                            }
                        });
                    }, deep: true
                },
                'source.returnInfo.product.code': {
                    handler() {
                        this.$nextTick(() => {
                            this.source.returnInfo.accesspointLocations = [];
                            if (this.source.returnInfo.product.code != null) {
                                this.getAccessPoints(true);
                            }
                        });
                    }, deep: true
                },
                'source.shippingInfo.carrier.code': {
                    handler() {
                        this.$nextTick(() => {
                            if (this.source.shippingInfo.carrier.code != null) {
                                this.getConsignmentService(false).then(() => {
                                    this.source.shippingInfo.services.isBinded = true;
                                });

                            }
                        });
                    }, deep: true
                },
                'source.shippingInfo.product.code': {
                    handler() {
                        this.$nextTick(() => {
                            this.source.shippingInfo.accesspointLocations = [];
                            if (this.source.shippingInfo.product.code != null) {
                                this.getAccessPoints(false);
                            }
                        });
                    }, deep: true
                }

            },
            created() {
                this.activity.serviceloading = true;
                this.$nextTick(() => {
                    if (this?.model?.request_CollectionDateValue !== null) {
                        this.source.shippingInfo.providers.isBinded = false;
                        this.source.returnInfo.providers.isBinded = false;
                        if (!(this.source.shippingInfo.providers?.isBinded ?? false))
                            this.getConsignmentProvider(false).then(() => {
                                if ((this.model.packages?.length ?? 0) > 0)
                                    this.source.shippingInfo.providers.isBinded = true;
                            });

                        if (!(this.source.returnInfo.providers?.isBinded ?? false)) {
                            if (this.model.isReturnable) {
                                this.getConsignmentProvider(true).then(() => {
                                    if ((this.model.packages?.length ?? 0) > 0)
                                        this.source.returnInfo.providers.isBinded = true;
                                });
                            }

                        }
                    }
                });
            },
            async mounted() {
                this.$nextTick(() => {
                    this.model.request_CollectionDateValue = new Date();
                    //this.model.request_ReadyTime = new Date(2018, 1, 1, 16, 0, 0);
                    this.model.request_ReadyTimeValue = new Date(2018, 1, 1, 16, 0, 0);
                    //this.model.request_ClosingTime = new Date(2018, 1, 1, 18, 0, 0);
                    this.model.request_ClosingTimeValue = new Date(2018, 1, 1, 18, 0, 0);
                });
                this.model.code = 'TEMP' + Math.random().toString().replace(".", "").substr(0, 16);
                this.model.name = 'TEMP' + Math.random().toString().replace(".", "").substr(0, 16);
                this.model.warehouseID = this.$vm.$appSession.warehouse.id;
                this.model.environmentID = this.$vm.$appSession.environment.id;
                //await this.getService();
                //await this.getCompanyList();
                //await this.getServiceProviderProduct();
                this.$nextTick(() => {
                    this.$nextTick(() => {
                        if (this?.model?.request_CollectionDateValue !== null) {
                            this.source.shippingInfo.providers.isBinded = false;
                            this.source.returnInfo.providers.isBinded = false;
                            if (!(this.source.shippingInfo.providers?.isBinded ?? false))
                                this.getConsignmentProvider(false).then(() => {
                                    if ((this.model.packages?.length ?? 0) > 0)
                                        this.source.shippingInfo.providers.isBinded = true;
                                });

                            if (!(this.source.returnInfo.providers?.isBinded ?? false)) {
                                if (this.model.isReturnable) {
                                    this.getConsignmentProvider(true).then(() => {
                                        if ((this.model.packages?.length ?? 0) > 0)
                                            this.source.returnInfo.providers.isBinded = true;
                                    });
                                }

                            }
                        }
                    });
                    if ((this.$props.recordformData?.customerID ?? 0 > 0) && (this.model?.model?.serviceGroupID ?? 0) === 0) {
                        this.getSetting(this.customer?.mask).then(() => {
                            this.manageDefaultSetting().then(() => {
                                //this.$nextTick(() => {
                                //    this.getConsignmentService(false);
                                //    if (!(this.model.isReturn ?? false))
                                //        this.getConsignmentService(true);
                                //});
                            });
                        });
                    }
                });
            }


        }
    },
    CONFIRMATIONFORM: {
        props: {
            ...props
        },
        data() { return { model : null } },
        computed: {
            //...computed, model() { return this.$props.formData } },
            ...computed
        },
        methods: {
            ...method, ...crud
        },
        watch: {
            'formData': {
                handler() {
                    this.$nextTick(() => {
                        this.model = this.$props.formData;
                    });
                }, deep: true
            }
        },
        //watch: {
        //    'model': { handler() { this.$emit("is-sync", { model: this.model }); }, deep: true }
        //},
       /* beforeCreate() { this.model = this.$props.formData },*/
        async mounted() {
            let _self = this;
            this.$nextTick(() => {
                this.model = _self.$props.formData;
            });

        }
        , async unmounted() {
            let _self = this;
            this.$nextTick(() => {
                for (let field in _self.$props.formData) {
                    this.model[field] = _self.$props.formData[field];
                }
            });
        }
    }

}