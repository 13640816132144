<template>
    <div class="p-grid" style="margin: 0 1rem;">
        <!--SETTINGS-->
        <div class="p-d-flex p-grid p-col-12" style="margin: 20px auto">
            <div class="p-col-12 p-md-3" style="padding-left:0;" v-if="showSetting && (companyType == companyTypes.Customer)">
                <div v-show="companyType == companyTypes.Customer">
                    <h5>VALIDATIONS</h5>
                    <form-boolean :disabled="limitedAccess" id="setting_CheckState" :value="model.setting_CheckState"  onText="ON" offText="OFF" label="Check Require State" width="120px" textPosition="left" @is-sync="model.setting_CheckState = $event.value" :showCopy="false"></form-boolean>
                    <form-boolean :disabled="limitedAccess" id="setting_CheckPostalCode" :value="model.setting_CheckPostalCode" onText="ON" offText="OFF" label="Check Postal Code Format" width="120px" textPosition="left" @is-sync="model.setting_CheckPostalCode = $event.value" :showCopy="false"></form-boolean>
                </div>
                <div v-show="companyType == companyTypes.Customer && user.userType == userTypes.SYSTEM_ADMINISTRATOR">
                    <h5>SUPPORT</h5>
                    <form-boolean :disabled="limitedAccess" id="setting_UseSupport" :value="model.setting_UseSupport" onText="ON" offText="OFF" label="Use Support" width="120px" textPosition="left" @is-sync="model.setting_UseSupport = $event.value" :showCopy="false"></form-boolean>
                    <form-input :disabled="limitedAccess" id="setting_SupportRedirectEmail" :value="model.setting_SupportRedirectEmail" label="Support Redirect Email" :v$="v$" @is-sync="model.setting_SupportRedirectEmail = $event.value?.toLowerCase()" v-lowercase :showCopy="false" />
                </div>
            </div>
            <div class="p-col-12 p-md-3" v-if="showSetting && (companyType == companyTypes.Environment || companyType == companyTypes.Customer)">
                <div v-show="companyType == companyTypes.Environment">
                    <h5>LIMITATIONS</h5>
                    <form-numeric :disabled="user.userType != userTypes.GLOBAL_ADMINISTRATOR" id="setting_UserLimit" :value="model.setting_UserLimit" label="User Limit" :v$="v$" @is-sync="model.setting_UserLimit = $event.value" :showCopy="false" :min="0" :max="9999"></form-numeric>
                    <form-numeric :disabled="user.userType != userTypes.GLOBAL_ADMINISTRATOR" id="setting_WarehouseLimit" :value="model.setting_WarehouseLimit" label="Warehouse Limit" :v$="v$" @is-sync="model.setting_WarehouseLimit = $event.value" vID="setting_WarehouseLimit" :showCopy="false" :min="0" :max="9999"></form-numeric>
                    <form-numeric :disabled="user.userType != userTypes.GLOBAL_ADMINISTRATOR" id="setting_ParcelLimit" :value="model.setting_ParcelLimit" label="Parcel Limit" :v$="v$" @is-sync="model.setting_ParcelLimit = $event.value" :showCopy="false" :min="0" :max="9999"></form-numeric>
                    <form-boolean v-if="user.userType == userTypes.GLOBAL_ADMINISTRATOR" width="120px" textPosition="left" id="setting_AllowTest" :value="model.setting_AllowTest" label="Allow Test Credentials" :v$="v$" @is-sync="model.setting_AllowTest = $event.value"></form-boolean>
                </div>
                <div v-show="companyType == companyTypes.Customer">
                    <h5>Auto Number</h5>
                    <form-boolean :disabled="limitedAccess"  id="setting_AutoArchive" :value="model.setting_AutoNumberConsignment" onText="ON" offText="OFF" label="Consignment Reference" width="120px" textPosition="left" @is-sync="model.setting_AutoNumberConsignment = $event.value" :showCopy="false"></form-boolean>
                </div>
            </div>
            <div class="p-col-12 p-md-3" v-if="showSetting && (companyType == companyTypes.Environment || companyType == companyTypes.Customer)">
                <div v-show="companyType == companyTypes.Customer">
                    <h5>RECORD ARCHIVED SERVICES</h5>
                    <form-boolean :disabled="limitedAccess" id="setting_AutoArchive" :value="model.setting_AutoArchive" onText="ON" offText="OFF" label="Auto Archived" width="120px" textPosition="left" @is-sync="model.setting_AutoArchive = $event.value" :showCopy="false"></form-boolean>
                    <form-numeric :disabled="limitedAccess" id="setting_DaysToAutoArchive" :value="model.setting_DaysToAutoArchive" label="Archived After" postLabel="Day(s)" :v$="v$" @is-sync="model.setting_DaysToAutoArchive = $event.value" :showCopy="false"></form-numeric>
                </div>
                <div v-show="companyType == companyTypes.Environment">
                    <h5>SYSTEM ARCHIVED SERVICES</h5>
                    <form-boolean :disabled="limitedAccess" id="setting_AutoSystemArchive" :value="model.setting_AutoSystemArchive" onText="ON" offText="OFF" label="Auto System Archived" width="120px" textPosition="left" @is-sync="model.setting_AutoSystemArchive = $event.value" :showCopy="false"></form-boolean>
                    <form-numeric :disabled="limitedAccess" id="setting_DaysToAutoSystemArchive" :value="model.setting_DaysToAutoSystemArchive" label="Archived After" postLabel="Day(s)" :v$="v$" @is-sync="model.setting_DaysToAutoSystemArchive = $event.value" :showCopy="false"></form-numeric>

                </div>
                <div v-show="companyType == companyTypes.Environment && (user.userType == userTypes.GLOBAL_ADMINISTRATOR || user.userType == userTypes.SYSTEM_ADMINISTRATOR)">
                    <h5>CUSTOMER AND DC SERVICES</h5>
                    <form-boolean :disabled="limitedAccess" id="setting_AutoCompanyArchive" :value="model.setting_AutoCompanyArchive" onText="ON" offText="OFF" label="Auto System Archived" width="120px" textPosition="left" @is-sync="model.setting_AutoCompanyArchive = $event.value" :showCopy="false"></form-boolean>
                    <form-numeric :disabled="limitedAccess" id="setting_DaysToAutoCompanyArchive" :value="model.setting_DaysToAutoCompanyArchive" label="Archived After" postLabel="Day(s)" :v$="v$" @is-sync="model.setting_DaysToAutoCompanyArchive = $event.value" :showCopy="false"></form-numeric>
                </div>
            </div>
            <div class="p-col-12 p-md-3" v-if="showSetting">
                <div v-show="companyType == companyTypes.Warehouse">
                    <h5>CLEAN-UP SERVICES</h5>
                    <form-boolean :disabled="user.userType > userTypes.ENVIRONMENT_ADMINISTRATOR" id="setting_AutoCollect" :value="model.setting_AutoCollect" onText="ON" offText="OFF" label="Auto Collect" width="120px" textPosition="left" @is-sync="model.setting_AutoCollect = $event.value" :showCopy="false"></form-boolean>
                    <form-numeric :disabled="user.userType > userTypes.ENVIRONMENT_ADMINISTRATOR" id="setting_DaysToAutoCollect" :value="model.setting_DaysToAutoCollect" label="Collect After" postLabel="Day(s)" :v$="v$" @is-sync="model.setting_DaysToAutoCollect = $event.value" :showCopy="false"></form-numeric>
                    <form-datetime-utc :disabled="user.userType > userTypes.ENVIRONMENT_ADMINISTRATOR" id="setting_TimeToAutoCollect" :stepMinute="5" :value="model?.setting_TimeToAutoCollect" type="time" @is-sync="onAutoCollectTime($event)" :timeFormat="24" label="Collect Around" :v$="v$" :showCopy="false"></form-datetime-utc>

                    <form-boolean :disabled="user.userType > userTypes.ENVIRONMENT_ADMINISTRATOR" id="setting_AutoDispatch" :value="model.setting_AutoDispatch" onText="ON" offText="OFF" label="Auto Dispatch" width="120px" textPosition="left" @is-sync="model.setting_AutoDispatch = $event.value" :showCopy="false"></form-boolean>
                    <form-numeric :disabled="user.userType > userTypes.ENVIRONMENT_ADMINISTRATOR" id="setting_DaysToAutoDispatch" :value="model.setting_DaysToAutoDispatch" label="Dispatch After" postLabel="Day(s)" :v$="v$" @is-sync="model.setting_DaysToAutoDispatch = $event.value" :showCopy="false"></form-numeric>
                    <form-datetime-utc :disabled="user.userType > userTypes.ENVIRONMENT_ADMINISTRATOR" id="setting_TimeToAutoDispatch" :stepMinute="5" :value="model?.setting_TimeToAutoDispatch" type="time" @is-sync="onAutoDispatchTime($event)" :timeFormat="24" label="Dispatch Around" :v$="v$" :showCopy="false"></form-datetime-utc>

                    <form-boolean :disabled="user.userType > userTypes.ENVIRONMENT_ADMINISTRATOR" id="setting_AutoPOD" :value="model.setting_AutoPOD" onText="ON" offText="OFF" label="Auto POD" width="120px" textPosition="left" @is-sync="model.setting_AutoPOD = $event.value" :showCopy="false"></form-boolean>
                    <form-numeric :disabled="user.userType > userTypes.ENVIRONMENT_ADMINISTRATOR" id="setting_DaysToAutoPOD" :value="model.setting_DaysToAutoPOD" label="Collect After" postLabel="Day(s)" :v$="v$" @is-sync="model.setting_DaysToAutoPOD = $event.value" :showCopy="false"></form-numeric>
                </div>
            </div>
            <!--DEFAULTS-->
            <div class="p-col-12 p-md-4" v-show="showDefault && companyType !== companyTypes.Environment">
                <h5>INTERNATIONAL FORMS</h5>
                <form-lookup :disabled="limitedAccess" id="Default_CustomsCategory" :required="true" type="enum" source="customscategorytype" :value="model.default_CustomsCategory" label="Customs Category" @is-sync="model.default_CustomsCategory = $event.value" :v$="v$" :showCopy="false"></form-lookup>
                <form-lookup :disabled="limitedAccess" v-show="false" id="default_ParcelCategory" :required="true" type="enum" source="parcelcategorytype" :value="model.default_ParcelCategory" label="Shipment Type" @is-sync="model.default_ParcelCategory = $event.value" :v$="v$" :showCopy="false"></form-lookup>
                <form-lookup :disabled="limitedAccess" id="default_IncoTerm" :required="true" type="enum" source="incotermtype" :value="model.default_IncoTerm" label="Incoterm" @is-sync="model.default_IncoTerm = $event.value" :v$="v$" :showCopy="false"></form-lookup>
                <form-lookup :disabled="limitedAccess" id="default_ExportReason" :required="true" type="enum" source="exportreasontype" :value="model.default_ExportReason" label="Export Reason" @is-sync="model.default_ExportReason = $event.value" :v$="v$" :showCopy="false"></form-lookup>

                <form-lookup :disabled="limitedAccess" id="default_PackageType" :required="true" type="enum" source="serviceproviderproductpackagingtype" :value="model.default_PackageType" label="Package Type" @is-sync="model.default_PackageType = $event.value" :v$="v$" :showCopy="false"></form-lookup>
                <form-input :disabled="limitedAccess" id="default_ParcelContent" :value="model.default_ParcelContent" label="Package Content" :v$="v$" @is-sync="model.default_ParcelContent = $event.value" :showCopy="false" />
                <h5>DECLARATION</h5>
                <form-lookup :disabled="limitedAccess" :value="model.default_CurrencyID" :required="true" id="default_CurrencyID" label="Currency" source="currency" textField="text" :sorted=false @is-sync="model.default_CurrencyID = $event?.value" :showCopy="false"></form-lookup>
                <form-numeric :disabled="limitedAccess" id="default_DeclaredValue" :value="model.default_DeclaredValue" label="Declared Value" decimalDigit="2" @is-sync="model.default_DeclaredValue = $event.value" type="decimal" :showCopy="false"></form-numeric>
                <form-numeric :disabled="limitedAccess" id="default_InsuranceProportion" :value="model.default_InsuranceProportion" label="Insurance Proportion" decimalDigit="2" @is-sync="model.default_InsuranceProportion = $event.value" type="decimal" :showCopy="false"></form-numeric>
            </div>
            <div class="p-col-12 p-md-4" v-if="showDefault && companyType !== companyTypes.Environment">
                <div>
                    <h5>UNITS</h5>
                    <form-lookup :disabled="limitedAccess" id="default_WeightUnit" :required="true" type="enum" source="weightunit" :value="model.default_WeightUnit" label="Weight Unit" @is-sync="model.default_WeightUnit = $event.value" :v$="v$" :showCopy="false"></form-lookup>
                    <form-lookup :disabled="limitedAccess" id="default_LengthUnit" :required="true" type="enum" source="lengthunit" :value="model.default_LengthUnit" label="Lenght Unit" @is-sync="model.default_LengthUnit = $event.value" :v$="v$" :showCopy="false"></form-lookup>
                    <h5>Dangerous Goods</h5>
                    <form-input :disabled="limitedAccess" id="default_DGEmergencyContact" :value="model.default_DGEmergencyContact" label="Emergency Contact" :v$="v$" @is-sync="model.default_DGEmergencyContact = $event.value" :showCopy="false" max-length="35" />
                    <form-input :disabled="limitedAccess" id="default_DGEmergencyTelephone" :value="model.default_DGEmergencyTelephone" label="Emergency Telephone" :v$="v$" @is-sync="model.default_DGEmergencyTelephone = $event.value" v-lowercase :showCopy="false" max-length="25" />
                    <form-lookup :disabled="limitedAccess" id="default_DGRegulatedCode" :required="false" type="enum" source="dg_regulationtype" :value="model.default_DGRegulatedCode" label="Regulated Code" @is-sync="model.default_DGRegulatedCode = $event.value" :v$="v$" :showCopy="false"></form-lookup>
                </div>
                <div v-show="companyType == companyTypes.Customer">
                    <h5 v-if="formData?.id">CARRIER (PORTAL ONLY)</h5>
                    <form-lookup :disabled="limitedAccess" v-if="formData?.id" id="default_ServiceGroupID" label="Service Group" :required="false" :source="serviceGroupSource ?? []" :value="model.default_ServiceGroupID" :v$="v$" :textField="'text'" :valueField="'id'" @is-sync="model.default_ServiceGroupID = (serviceGroupSource.length > 0 ? $event.value : model.default_ServiceGroupID)" :sorted="true" :showCopy="false" />
                    <form-lookup :disabled="limitedAccess" v-if="formData?.id" id="default_AccountCustomerID" label="Carrier" :required="false" :source="accountCustomerSource ?? []" :value="model.default_AccountCustomerID" :v$="v$" :textField="'text'" :valueField="'id'" @is-sync="model.default_AccountCustomerID = (accountCustomerSource.length > 0 ? $event.value : model.default_AccountCustomerID)" :sorted="true" :showCopy="false" />
                </div>
                <!--<h5>NOTIFICATION</h5>
        <form-boolean id="default_Notification" :value="model.default_Notification" onText="ON" offText="OFF" label="Notification" width="120px" textPosition="left" @is-sync="model.default_Notification = $event.value"></form-boolean>
        <form-input id="default_NotificationRecipient" type="textarea" label="Recipient" :value="model.default_NotificationRecipient" @is-sync="model.default_NotificationRecipient = $event.value"></form-input>-->
            </div>
            <div class="p-col-12 p-md-4" v-if="showDefault">
                <h5>NOTIFICATION</h5>
                <form-lookup type="flag" :value="model.default_NotificationType" :v$="v$" vID="default_NotificationType" id="notification" label="Notification" :sorted=false :source="'notificationtype'" @is-sync="model.default_NotificationType = $event.value" :showClear="false"></form-lookup>
                <h5>ADDRESS</h5>
                <form-lookup :disabled="limitedAccess" id="default_EstablishmentType" type="enum" source="establishmenttype" :value="model.default_EstablishmentType" label="Establishment Type" @is-sync="model.default_EstablishmentType = $event.value" :v$="v$" :showCopy="false" :nullable="false"></form-lookup>
                <h5 v-if="companyType == 2">INVOICE</h5>
                <form-lookup :disabled="limitedAccess" v-show="companyType == 2" id="default_InvoiceCurrencyID" :required="true" :value="model.default_InvoiceCurrencyID" label="Currency" source="currency" textField="text" :sorted=false @is-sync="model.default_InvoiceCurrencyID = $event?.value" :showCopy="false"></form-lookup>
                <form-lookup :disabled="limitedAccess" v-show="companyType == 2" id="default_InvoiceVATRateType" :required="true" :value="model.default_InvoiceVATRateType" label="Invoice VAT Type" type="enum" source="ratetype" @is-sync="model.default_InvoiceVATRateType = $event.value;" :showCopy="false" />
                <form-numeric :disabled="limitedAccess" v-show="companyType == 2" id="default_InvoiceVATRate" :type="(model.default_InvoiceVATRateType <= 100 ? 'currency' : 'decimal')" :suffix="(model.default_InvoiceVATRateType <= 100 ? '' : '%')" :currency="invoiceCurrencyCode" :hideLabel="true" :max="(model.default_InvoiceVATRateType <= 100 ? 9999999999.99 : 100)" :value="model.default_InvoiceVATRate" @is-sync="model.default_InvoiceVATRate = $event?.value;" :showCopy="false" />
            </div>
            <div class="p-col-12 p-md-4" v-if="showSchedule">
                <h4></h4>
                <form-lookup :disabled="limitedAccess"  type="flag" :value="model.schedule_CollectionDays" id="schedule_CollectionDays" label="Collection Days" :sorted=false :source="'dayofweek'" @is-sync="model.schedule_CollectionDays = $event.value" :showCopy="false"></form-lookup>
            </div>
            <div class="p-col-12 p-md-4" v-if="showSchedule">
                <h4></h4>
                <form-boolean :disabled="limitedAccess"  id="schedule_StrictCutOff" :stepMinute="5" :value="model.schedule_StrictCutOff" onText="YES" offText="NO" label="Strict Cut Off Time?" width="120px" textPosition="left" @is-sync="model.schedule_StrictCutOff = $event.value" :showCopy="false"></form-boolean>
                <form-datetime-utc v-if="model.schedule_StrictCutOff" :disabled="limitedAccess"  id="schedule_CutOffTime" :stepMinute="5" :value="model?.schedule_CutOffTime" type="time" @is-sync="onCutOffTime($event)" :timeFormat="24" label="CutOff Time" :v$="v$" :showCopy="false"></form-datetime-utc>
                <form-datetime-utc :disabled="limitedAccess"  id="schedule_ReadyTime" :stepMinute="5" :value="model?.schedule_ReadyTime" type="time" @is-sync="onReadyTime($event)" :timeFormat="24" label="Ready Time" :v$="v$" :showCopy="false"></form-datetime-utc>
                <form-datetime-utc :disabled="limitedAccess"  id="schedule_CollectionTime" :stepMinute="5" :value="model?.schedule_CollectionTime" type="time" @is-sync="onCollectionTime($event)" :timeFormat="24" label="Collection Time" :v$="v$" :showCopy="false"></form-datetime-utc>
                <form-datetime-utc :disabled="limitedAccess"  id="schedule_ClosingTime" :stepMinute="5" :value="model?.schedule_ClosingTime" type="time" @is-sync="onClosingTime($event)" :timeFormat="24" label="Closing Time" :v$="v$" :showCopy="false"></form-datetime-utc>
            </div>
        </div>
    </div>
</template>
<script>
    import { mapGetters } from 'vuex';
    const config = require("@/assets/_js/cosmos-config.js").default;
    import { useVuelidate } from '@vuelidate/core';
    const moduleName = 'companygeneralsetting';
    import { helpers, minLength, maxLength, minValue, maxValue } from '@vuelidate/validators';
    const regex = (param) => helpers.withParams({ type: 'regex', value: param }, (value) => value.match(param) != null);

    export default {
        name: moduleName + 'Record',
        props: {
            showSetting: { type: Boolean, default: false },
            showDefault: { type: Boolean, default: false },
            showSchedule: { type: Boolean, default: false },
            is_parentMask: { type: String },
            companyType: { type: Number, default: 1 },
            formData: { type: Object },
            readOnly: { type: Boolean, default: false },
            minWarehouseLimit: { type: Number, default: 0 },
            serviceGroupDS: { type: Array },
            accountCustomerDS: { type: Array }

        },
        setup: () => ({ v$: useVuelidate() }),
        data() {
            return {
                schema: null,
                model: null,
                filter: null,
                columns: null,
                errors: null,
                data: null,
                ds: {
                    total: 0,
                    data: []
                }
            }
        },
        validations() {
            let validator = {
                model: {
                    setting_WarehouseLimit: { $autoDirty: true, maxValue: maxValue(9999), minValue: minValue(0) },
                    default_DGEmergencyTelephone: { $autoDirty: true, maxLength: maxLength(25) },
                    default_DGEmergencyContact: { maxLength: maxLength(35), $autoDirty: true },

                }
            };

            if (this.companyType === this.companyTypes.Environment) {
                validator.model.setting_WarehouseLimit = { maxValue: maxValue(9999), minValue: minValue(0) };
                if ((this.model.setting_WarehouseLimit ?? 0) > 0) {
                    validator.model.setting_WarehouseLimit = { maxValue: maxValue(9999), minValue: helpers.withMessage(`Value must not less than the active warehouses : ${this.minWarehouseLimit} `, minValue(this.minWarehouseLimit)) };
                }
            }
            else if (this.companyType === this.companyTypes.Customer) {
                if ((this.model.default_DGEmergencyTelephone?.length ?? 0) > 0)
                    validator.model.default_DGEmergencyTelephone = { maxLength: maxLength(25), minLength: minLength(7), $autoDirty: true, regex: helpers.withMessage(`Invalid Telephone format. It must be a numeric string with at least one non-zero character and a length between 7 and 25 characters.`, regex('^(?!0+$)[0-9]{7,25}$')) }
            }

            return validator;
        },
        watch: {
            'formData': 
            {
                handler() {
                    this.$nextTick(() => {
                        this.model = this.$props.formData;
                    });
                },
                deep: true
            }
        },
        computed: {
            ...mapGetters({
                user: "auth/currentUser"
            }),
            init() {
                var model = this.$store.getters[moduleName + "/init"];
                return this.$filters.init(model);
            },
            currencyDS() { return this.$store.getters["currency/ds"]; },
            invoiceCurrencySymbol() {
                return this.$vm.$currencies.filter(x => x.id === this.model?.default_InvoiceCurrencyID)[0]?.symbolText;
            },
            invoiceCurrencyCode() {
                return this.$vm.$currencies.filter(x => x.id === this.model?.default_InvoiceCurrencyID)[0]?.code
            },
            companyTypes() {
                return {
                    System: 1,
                    Environment: 2,
                    Forwarder: 4,
                    Warehouse: 8,
                    Customer: 16
                }
            },
            limitedAccess() {
                return ((this.user?.userType ?? 0) === this.userTypes.ENVIRONMENT_USER) || ((this.user?.userType ?? 0) === this.userTypes.CUSTOMER_USER) || ((this.user?.userType ?? 0) === this.userTypes.RETAILER_USER);
            },
            userTypes() {
                return {
                    GLOBAL_ADMINISTRATOR: 0,
                    SYSTEM_ADMINISTRATOR: 2,
                    ENVIRONMENT_ADMINISTRATOR: 3,
                    ENVIRONMENT_USER: 4,
                    CUSTOMER_ADMINISTRATOR: 5,
                    CUSTOMER_USER: 6,
                    RETAILER_USER : 7
                }
            },
            serviceGroupSource() {
                return this.serviceGroupDS ?? [];
            },
            accountCustomerSource() {
                return this.accountCustomerDS ?? [];
            },
        },
        methods: {
            onAutoCollectTime(e) {
                this.$nextTick(() => {
                    this.model.setting_TimeToAutoCollect = e.value;
                    //this.model.setting_TimeToAutoCollectValue = e.value;
                    //this.model.setting_TimeToAutoCollect = (e.record.data ?? new Date());
                });

            },
            onAutoDispatchTime(e) {
                this.$nextTick(() => {
                    this.model.setting_TimeToAutoDispatch = e.value;
                    //this.model.setting_TimeToAutoDispatchValue = e.value;
                    //this.model.setting_TimeToAutoDispatch = (e.record.data ?? new Date());
                });

            },
            onCutOffTime(e) {
                this.$nextTick(() => {
                    this.model.schedule_CutOffTime = e.value;
                    //this.model.schedule_CutOffTimeValue = e.value;
                    //this.model.schedule_CutOffTime = (e.record.data ?? new Date());
                });
            },
            onReadyTime(e) {
                this.model.schedule_ReadyTime = e.value;
                //this.model.schedule_ReadyTimeValue = e.value;
                //this.model.schedule_ReadyTime = (e.record.data ?? new Date());
            },
            onCollectionTime(e) {
                this.model.schedule_CollectionTime = e.value;
                //this.model.schedule_CollectionTimeValue = e.value;
                //this.model.schedule_CollectionTime = (e.record.data ?? new Date());
            },
            onClosingTime(e) {
                this.model.schedule_ClosingTime = e.value;
                //this.model.schedule_ClosingTimeValue = e.value;
                //this.model.schedule_ClosingTime = (e.record.data ?? new Date());
            },
            async getSetting(mask) {
                await this.$axios
                    .get(config.api + `/services/settings/companygeneralsetting/ByCompany/${mask}`.replaceAll("//", "/"))
                    .then((response) => {
                        this.model = response.data.result;
                        //this.$emit("is-initial-sync", this.model);
                    })
                    .catch((ex) => { this.errors = ex.data; });
            },
            async getCarrier(customerID) {
                this.$store.dispatch("provideraccountcustomer/getURL", { url: `/services/admin/serviceprovideraccountcustomer/handlerbyaccount/id/${customerID}` });
                //    .then(() => {
                //    this.carriers = this.$store.getters["provideraccountcustomer/dsGET"].data;
                //}).catch((ex) => { this.carriers = [] });
            }
            
        },
        created() {
            this.model = this.init;
            this.$nextTick(() => {
                this.model.schedule_CollectionDays = 127;
            });
         
            //if (this.$route.params.mask ?? this.is_parentMask)
            //    this.getSetting(this.$route.params.mask ?? this.is_parentMask, true).then(() => {
            //        this.$emit("is-mounted", true);
            //    });
        },
        mounted() {
            this.model = this.$props.formData;
        },
        updated() {
           // this.$emit("is-sync", this.model);
        }
    
    }
</script>
